@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

$url: '/images/mobilehome2/new/';

/* 버튼 정의 */
button {
	border-style: none;
	background-color: transparent;
	cursor: pointer;

}

//일반버튼
div.btn_wrap {
	display:flex;
	justify-content:center;
	font-size:0;
	button,
	a {
		width:100%;
		& + button,
		& + a {
			margin-left:8px;
		}
		&.btn {
			display:inline-block;
			width:100%;
			// height:40px;
			// padding:7px 0 8px;
			height:auto;
			padding:8px 0 9px;
			// border-radius:8px;
			border-radius: 10px; // 2023070_[공통확인]
			text-align: center;
			background-color:$black;
			// border:2px solid $black;
			border:1px solid $black;
			> span {
				@include font_pre(0.875rem,700,$white,1.6);
			}
			&.border {
				background-color:$white !important;
				> span {
					// @include font_pre(0.875rem,700,$black !important,1.5);
					@include font_pre(14px,700,$black !important,1.5);
				}
			}
			&.em {
				border:2px solid $green;
				background-color:$green;
				> span {
					@include font_pre(0.875rem,700,$white,1.5);
				}
			}
			&.em_border {
				border:2px solid $green;
				background-color:$white;
				> span {
					@include font_pre(0.875rem,700,$green,1.5);
				}
			}
			&.gray {
				border:2px solid $gray03;
				background-color:$gray03;
				> span {
					@include font_pre(0.875rem,700,$white,1.5);
				}
			}
			&.gray_border {
				border:2px solid $gray03;
				background-color:$white;
				> span {
					@include font_pre(0.875rem,700,$gray02,1.5);
				}
			}
			&.white_border {
				border:1.4px solid $white;
				background-color:transparent;
				> span {
					@include font_pre(0.875rem,700,$white,1.5);
				}
			}
			&.inline_box {
				width: auto;
				padding-right: 16px;
				padding-left: 16px;
			}
		}
		&.lbtn {
			display:inline-block;
			width:100%;
			height:56px;
			padding:15px 0 15px;
			border-radius:10px;
			// border-radius:8px; //카드상세페이지 하단버튼 기준
			text-align: center;
			background-color:$black;
			border:2px solid $black;
			box-sizing: border-box;
			> span {
				@include font_pre(0.937rem,700,$white,1.6);
			}
			&.border {
				border-radius:8px;
				background-color:$white;
				> span {
					@include font_pre(0.937rem,700,$black,1.5);
				}
			}
			&.thick_border {
				border:2px solid $black;
				background-color:$white;
				> span {
					@include font_pre(0.937rem,700,$black,1.5);
				}
			}
			&.em {
				border:2px solid $green;
				background-color:$green;
				> span {
					@include font_pre(0.937rem,700,$white,1.5);
				}
			}
			&.em_border {
				border:2px solid $green;
				background-color:$white;
				> span {
					@include font_pre(0.937rem,700,$green,1.5);
				}
			}
		}
		&.sbtn {
			display:inline-block;
			width:100%;
			padding:3px 12px 4px;
			border-radius:6px;
			text-align: center;
			background-color:$black;
			border:1px solid $black;
			min-width:initial; //ASIS충돌방지
			height:auto; //ASIS충돌방지
			> span {
				@include font_pre(0.875rem,500,$white,1.3);
			}
			&.border {
				background-color:$white;
				> span {
					@include font_pre(0.875rem,500,$black,1.3);
				}
			}
			&.gray_border {
				border:1px solid $gray03;
				background-color:transparent;
				> span {
					@include font_pre(0.875rem,500,$black,1.5);
				}
			}
			&.em {
				border:1px solid $green;
				background-color:$green;
				> span {
					@include font_pre(0.875rem,500,$white,1.3);
				}
			}
			&.em_border {
				border:1px solid $green;
				background-color:$white;
				> span {
					@include font_pre(0.875rem,500,$green,1.3);
				}
			}
			&.round {
				border-radius:36px;
			}
		}
		&.dis {
			pointer-events:none;
			border:1px solid $gray01 !important;
			background-color:$gray01 !important;
			> span {
				color:$white !important;
			}
		}
		&.dis_border {
			pointer-events:none;
			border:1px solid $gray01 !important;
			background-color:$white !important;
			> span {
				color:$gray01 !important;
			}
		}
		/*jgh 40px짜리버튼 비활성화시 글자색이 변하지 않아 추가 */
		&.btn.dis_border {
			> span {
				color:$gray01 !important;
			}
		}

		//텍스트버튼(화살표)
		&.txt_arrow {
			display: inline-block;
			position: relative;
			width:auto;
			padding-right: 16px;
			&:after {
				content: '';
				position: absolute;
				top: 2px;
				right: 0;
				@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_black.png')
			}
			span {
				@include font_pre(0.875rem,700,$black,1.5);
			}
			&.gray {
				&:after {
					@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_gray.png')
				}
				span {
					color:$gray02;
				}
			}
			&.em {
				&:after {
					@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_green.png')
				}
				span {
					color:$green;
				}
			}
			&.disable {
				pointer-events:none;
				&:after {
					@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_dis.png')
				}
				span {
					color:$gray01;
				}
			}
			&.white {
				&:after {
					@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_white.png')
				}
				span {
					color:$white;
				}
			}
			&.box {
				padding:12px 36px 14px 20px;
				background-color:$black;
				&:after {
					top:14px;
					right:20px;
					@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_right_16_white.png')
				}
				span {
					color:$white;
				}
			}
			&.large {
				padding-right:24px;
				&:after {
					top:1px;
					@include bg(20px,20px,'/images/mobilehome2/new/ico_arrow_right_20_black.png')
				}
				span {
					font-size:0.937rem;
				}
			}
		}

		//텍스트버튼(라인)
		&.txt_line {
			display: inline-block;
			position: relative;
			width:auto;
			span {
				@include font_pre(0.937rem,400,$black,1.47) {
					letter-spacing:-0.4px;
					text-decoration:underline;
				}
			}
			&.gray {
				span {
					color:$gray02;
				}
			}
			&.white {
				span {
					color:$white;
				}
			}
			&.em {
				span {
					color:$green;
				}
			}
			&.disable {
				pointer-events:none;
				span {
					color:$gray01;
				}
			}
			&.box {
				padding:12px 20px 14px;
				background-color:$black;
				span {
					color:$white;
				}
			}
		}

		//인풋내 버튼
		&.input_btn {
			display:inline-block;
			width:auto;
			min-width:104px;
			height:48px;
			padding:0 16px;
			border-radius:8px; //4? 8?
			text-align: center;
			background-color: #00905c;
			border:1px solid #00905c;
			> span {
				white-space:nowrap;
				@include font_pre(0.937rem,700,$white,1.5);
			}
			&.em {
				border-color: #00905c;
				background-color: #00905c;
				> span {
					color: $white
				}
				&.dis {
					pointer-events:none;
					border:1px solid $gray01 !important;
					background-color:$gray01 !important;
					> span {
						color:$white !important;
					}
				}
			}
			&.dis {
				pointer-events:none;
				border:1px solid $gray01 !important;
				background-color:$gray01 !important;
				> span {
					color:$white !important;
			}
			}
		}
	}

	&.ico { //아이콘 형식
		button, a {
			width:auto;
			padding-left:12px;
			padding-right:12px;
			&::before {
				@include sudo_dis(0,4px,0,0);
			}
			&.alam {
				&::before {
					@include bg(20px,20px,'/images/mobilehome2/new/ico_alam_20_green.png');
				}
			}
			&.add {
				&::before {
					@include bg(20px,20px,'/images/mobilehome2/new/ico_add_20_green.png');
				}
			}
		}
		// 공유하기
		.share_btn {
			display: flex;
			padding: 0px;
			align-items: center;
			justify-content: center;
			> i.ico {
				display: block;
				@include bg(20px, 20px, $url + 'ico_share_20_gray.png' );
			}
			> i.ico + span { @include hidden(); }
		}
		//찜하기
		.heart_btn {
			display: flex;
			padding: 0px;
			align-items: center;
			justify-content: center;
			.ico {
				display: block;
				@include bg(20px, 20px, $url + 'ico_heart_20_gray.png' );
			}
			.ico + span { @include hidden(); }
			&.off,
			&.on {
				.ico {
					display: block;
					@include bg(20px, 20px, $url + 'ico_heart_20_hdgreen.png' );
				}
			}
			& + .share_btn { margin-left: 12px; }
		}
	}

	&._inline {
		display: inline-block;
	}
	&.list_more {
		width: 100px;
		margin: 40px auto 0 !important;
	}
	&.btn_wrap_sm_width,
	&.btn_wrap_sm_width.mt40 {
		width: auto;
		margin: 40px auto 0 !important;
	}
}


//더보기 버튼
div.more_list {
	display:block;
	margin:24px auto 0;
	padding:7px 20px 6px;
	border:1px solid $black;
	> span {
		@include font_pre(0.875rem,700,$black,1.5);
		> strong {
			margin-left:4px;
			color:$green;
		}
	}
	&.sm {
		padding:0;
		border:none;
		&:after {
			@include sudo_dis(1px,0,0,0);
			@include bg(16px,16px,'/images/mobilehome2/new/ico_arrow_down_black.svg')
		}
	}
}

// 상단 이벤트 보기 버튼
.icon_btn_wrap {
	> .icon_btn {
		display: flex;
		padding: 16px;
		column-gap: 12px;
		border-radius: 18px;
		background-color: $gray10;
		align-items: center;
		justify-content: flex-start;
		span {
			@include font_sum(
				$s: 15,
				$h: 22
			);
		}
		&::before {
			content: '';
			display: block;
			@include bg(
				24px,
				24px,
				$url + 'ico_gift_24_green.png'
			);
		}
		&._event::before { background-image: url($url + 'ico_gift_24_green.png'); }
	}
}

//셀렉트형 버튼
a.btn_sel_type,
button.btn_sel_type,
div.btn_sel_type {
	position:relative;
	display:inline-block;
	width:100%;
	padding:13px 20px;
	border:1px solid $gray03;
	// border-radius:8px;
	border-radius:10px;
	box-sizing:border-box;
	background-color:$white;
	&:after {
		@include sudo_pos(50%,20px,0,initial){
			transform:translateY(-50%);
		};
		@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_black.png');
	}
	span {
		@include font_pre(0.937rem,400,$black,1.5);
		&.ui_placeholder {
			color:$gray04 !important;
		}
	}
	&.dis {
		background-color:$gray05;
		pointer-events:none;
		> span {
			color:$gray01;
		}
		&:after {
			@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_dis.png');
		}
	}
	& + .input_wrap  {
		margin-top:8px;
	}
	& + .btn_wrap {
		margin-top:20px;
	}
	//그림자박스형
	&.wbox {
		padding:20px;
		border-radius:18px;
		// &:after {
		// 	// top:22px;
		// }
		span {
			@include font_pre(1.125rem,700,$gray04,1.4);
			> em {
				@include font_pre(1.125rem,700,$black,1.4);
			}
		}
	}
}

//Top 버튼
.btn_agreement_top {
	opacity:0; //초기값
	text-align: right;
	position:fixed;
	right: 24px;
	bottom: 24px;
	img {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
		background-color:$white;
	}
}
/* //버튼 정의 */