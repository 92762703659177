@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 기본 탭 정의 */
div.tab_wrap {
	margin-left:-16px;
	margin-right:-16px;
	.tabs {
		ul {
			display: flex;
			justify-content: flex-start;
			li {
				width:100%;
				margin-left:0;
				flex:1;
				border-bottom:1px solid $gray01;
				a {
					position: relative;
					display:block;
					width:100%;
					padding:11px 0 10px;
					text-decoration:none;
					text-align:center;
					@include font_pre(1rem,500,$gray04,1.5);
					span {
						@include font_pre(1rem,500,$gray04,1.5);
					}
				}
				&.on {
					a {
						border-bottom:3px solid $green02;
						> em {
							@include hidden;
						}
						> span {
							@include font_pre(1rem,700,$green02,1.5);
							&:after {
								content:none;
							}
						}
						&:after {
							content:none;
						}
					}
				}
			}
		}

		//스크롤 탭
		&.sc_tab {
			overflow: hidden;
			overflow-x: scroll;
			background-color:$white;
			@include sc_hidden;
			ul {
				display:inline-block;
				width:100%;
				li {
					display:inline-block;
					width:30%;
					height:40px;
					margin-right:-3px;
				}
			}
		}

		// 상단 고정
		&.sticky_tab {
			position: sticky;
			top: 50px;
			z-index: 20; // 셀렉트 박스 위로 올라오게끔 수정
			background: white;
		}
	}

	&.sub { //2depth tab
		ul {
			li {
				a {
					padding:12px 0 10px;
					> span {
						@include font_pre(0.875rem,400,$gray04,1.5);
					}
				}
				&.on {
					a {
						> span {
							@include font_pre(0.875rem,700,$green02,1.5);
							&:after {
								content:none;
							}
						}
						&:after {
							content:none;
						}
					}
				}
				&.alam {
					a {
						span {
							position:relative;
							&:after {
								@include bg(5px,5px,initial) {
									background-color: $pred;
									border-radius:15px;
								};
								@include sudo_pos(-2px,-8px,0,initial);
							}
						}
					}
				}
			}
		}
	}

	&.ssub { //3depth tab
		.tabs {
			ul {
				li {
					a {
						padding:8px 0 7px;
						@include font_pre(0.875rem,400,$gray04,1.5);
					}
					&.on {
						a {
							border-bottom:3px solid $black;
							> span {
								@include font_pre(0.875rem,700,$black,1.5);
							}
						}
					}
				}
			}
		}
		// inline 타입 추가
		&.inline {
			.tabs {
				padding: unset;
				padding-left: 24px;
				border-bottom: 1px solid #D4D6D4;
				ul {
					column-gap: 16px;
					li {
						width: auto;
						border: 0px;
						flex: initial;
						a {
							padding: unset;
							padding-top: 8px;
							> span {
								padding-bottom: 5px;
								@include font_sum (
									$w: 500,
									$c: #9b9e9c,
									$box: inline-block
								);
								font-size:14px;
							}
						}
						&.on {
							a {
								> span {
									color: $black;
									font-weight: 700;
								}
							}
						}
					}
				}
			}

		}
	}
	/*
		20230602 : 가이드 h2 tab 공통화 디자인으로 적용
	*/
	&.inline_type {
		& > .tabs {
			border-bottom: 1px solid $gray03;
			ul {
				position: relative;
				display: block;
				width: 100vw;
				left: 50%;
				padding: 0 24px;
				transform: translateX(-50%);
				box-sizing: border-box;
				li {
					display: inline-block;
					width: auto;
					height: auto;
					margin: 0px;
					padding-right: 20px;
					border-bottom: none;
					&:first-of-type a { padding-left: 0px; }
					a {
						width: auto;
						padding: 0px;
						font-size: 0;
						line-height: 0;
						span {
							padding: 12px 0;
							border-bottom: 3px solid transparent;
							@include font_sum($w: 500, $c: $gray04, $h: 21);
							> em {
								padding-left: 4px;
								@include font_sum($c: $gray04, $h: 21);
							}
						}
					}
					&.on {
						a {
							border-bottom: 0px;
							span {
								border-bottom: 3px solid $green02;
								font-weight: 700;
								color: $green02;
								> em {
									color: $green02;
									font-weight: 700;
								}
							}
						}
					}
					&.alam {
						padding-right: 28px;
						a {
							span {
								position:relative;
								&:after {
									border: 0px !important;
									@include bg(5px,5px,initial) {
										background-color: $pred;
										border-radius:15px;
									};
									@include sudo_pos(12px,-8px,0,initial);
								}
							}
						}
					}
				}
			}
		}
	}

	// AMY-B01-004.html
	&.inline_type02 {
		margin: 0;
		& > .tabs {
			ul {
				position: initial;
				width: 100%;
				display: flex;
				padding: 0px;
				border: none;
				left: auto;
				transform: none;
				align-items: center;
				justify-content: flex-start;
				> li {
					display: inline-block;
					width: auto;
					border-bottom: none;
					flex: initial;
					> a {
						display: inline-block;
						width: auto;
						padding: 10px 10px 0px;
						border-bottom: none;
						line-height: 0px;
						> span {
							display: inline-block;
							padding-bottom: 4px;
							border-bottom: 3px solid transparent;
							@include font_pre(0.875rem, 400, $gray04, 1.5);
						}
					}
					&:first-of-type a { padding-left: 8px; }
					&.on {
						a {
							border-bottom: none;
							span {
								font-weight: 700;
								color: $green02;
								border-color: $green02
							}
						}
					}
				}
			}
		}
		& > .tab_conts {
			padding-top: 24px;
			.no_result {
				margin:80px 0;
			}
		}
	}

	&.inner_type {
		margin: 0px;
		& > .tabs {
			margin-top:16px;
			ul {
				li {
					flex: initial;
					width: auto;
					border: none;
					& + li { margin-left: 8px; }
					a {
						width: auto;
						padding:5px 12px 6px;
						border: none;
						border-radius: 40px;
						// background-color: $gray10;
						background-color: $gray05; //231015_서브카테고리 배경컬러 변경
						@include font-sum($s: 0, $h: 0);
						> span { @include font_sum($h: 21, $c: $gray04); }

					}
					&.on {
						a {
							> span {
								font-weight: 700;
								color: $black;
							}
						}
					}
				}
			}
		}
	}

	&.sub_tab {
		> .tabs {
			border-bottom: 1px solid #171a191a;
			ul {
				padding-left:24px;
				font-size: 0;
				line-height: 0;
				box-sizing: border-box;
				li {
					width: auto;
					height: auto;
					margin: 0;
					border: 0px;
					a {
						position: relative;
						display: block;
						width: auto;
						padding: 12px 16px;
						border-bottom: 3px solid transparent;
						font-size: 0px;
						line-height: 0;
						> span {
							@include font_sum(14, 500, $gray04, 21, -.4px);
							em {
								@include font_sum(14, 500, $gray04, 21, -.4px) {
									margin-left: 4px;
								};
							}
							&:after { content:none; }
						}
						&:after { content:none; }
					}
					&.on {
						a {
							border-bottom: 3px solid $green02;
							> span {
								@include font_sum(14, 700, $green02, 21, -.4px);
								em {
									@include font_sum(14, 700, $green02, 21, -.4px) {
										margin-left: 4px;
									};
								}
								&:after {
									content:none;
								}
							}
							&:after {
								content:none;
							}
						}
					}
					&.alam {
						a {
							span {
								position:relative;
								&:after {
									border: 0px !important;
									@include bg(5px,5px,initial) {
										background-color: $pred;
										border-radius:15px;
									};
									@include sudo_pos(-2px,-8px,0,initial);
								}
							}
						}
					}
				}
			}
		}
	}
}

.lay_conts {
	div.tab_wrap {
		.tabs {
			&.sticky_tab {
				top: 0px;
				&.app_fixed { //개발 네이티브 영역 떄문에 추가 된 클래스(퍼블은 필요 없음)
					position:fixed;
					width:100%;
					top:50px;
					& + .tab_conts {
						padding-top:50px;
					}
				}
			}
		}
	}
}
/* //기본 탭 정의 */