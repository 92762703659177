@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 달력 */
div.ui-calendar-container {
	$com_url: '/images/mobilehome2/new/';

	position: relative;
	width: 100%;
	padding-top: 8px;
	margin-top: 24px;
	//231010_iOS 파란색 테두리 제거
	outline:transparent;
	&:focus {
		outline:transparent;
	}
	.ui-select-day {
		padding: 0;
		padding-top: 24px;
		// padding-bottom: 20px;
		box-shadow: none;
		.ui-calendar-header-second {
			display: flex;
			justify-content: center;
			align-items: center;
			.ui-calendar-prev {
				display: block;
				position: initial;
				margin: 0px;
				font-size: 0;
				line-height: 0;
				// @include bg(20px, 20px, $com_url + 'ico_arrow_down_20_black.png');
				@include bg(44px, 44px, $com_url + 'ico_arrow_down_20_black.png'){
					background-size:20px 20px;
				};
				transform: rotate(90deg);
			}
			.ui-calendar-now {
				padding: 0px 8px;
				span {
					@include font_sum(
						$s: 20,
						$w: 700,
						$h: 28,
						$l: -1px
					);
				}
			}
			.ui-calendar-next {
				display: block;
				position: initial;
				margin: 0px;
				font-size: 0;
				line-height: 0;
				// @include bg(20px, 20px, $com_url + 'ico_arrow_down_20_black.png');
				@include bg(44px, 44px, $com_url + 'ico_arrow_down_20_black.png'){
					background-size:20px 20px;
				};
				transform: rotate(-90deg);
			}
		}
		.ui-calendar-date {
			margin: 0px;
			padding: 32px 0px 0;
			.ui-calendar-table {
				width: 100%;
				border-collapse: collapse;
				.ui-calendar-dayname {
					width: calc(100% / 7);
					padding: 0px;
					padding-bottom: 12px;
					@include font_sum(
						$w: 700,
						$box: table-cell
					);
				}
				.ui-calendar-cell {
					position: relative;
					width: calc(100% / 7);
					height: auto;
					padding: 0px;
					border: 6px solid transparent;
					box-sizing: border-box;
					font-size: 0;
					line-height: 0;
					&::before {
						content: '';
						position: relative;
						display: block;
						width: 100%;
						padding-bottom:100%;
						border-radius: 50%;
						background-color: transparent;
					}
					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 6px;
						height: 6px;
						margin-top: 12px;
						margin-left: 1px;
						border-radius: 50%;
						transform: translate(-50%, -50%);
						background-color: transparent;
						z-index: 1;
					}
					.ui-calendar-day {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-color: transparent;
						align-items: center;
						justify-content: center;
						background-color: transparent;
						@include font_sum(
							$w: 500,
							$box: flex
						);
					}
				}
				// 휴점일 선택 불가
				.ui-calendar-holiday {
					> .ui-calendar-day {
						color: #eb3912;
						background-color: transparent;
					}
					&::after {
						background-color: #eb3912;
						border-radius:0;
					}
				}
				// 선택 불가
				.disabled > .ui-calendar-day {
					color: #d4d6d4;
					background-color: transparent;
				}
				// 연장영엽일
				.ui-calendar-extend {
					// & > .ui-calendar-day {
					// 	color: #01a76b;
					// 	background-color: transparent;
					// }
					&::after {
						background-color: #01a76b;
					}
				}
				// 금일
				.ui-calendar-today {
					// &::after {
					// 	background-color: #01a76b;
					// }
					&::before {
						background-color: #01a76b;
					}
					.ui-calendar-day {
						color: $white;
					}
					&.ui-calendar-extend { //금일이면서 연장일
						&::after {
							background-color: $white;
						}
					}
					&.ui-calendar-holiday { //금일이면서 휴일
						> .ui-calendar-day {
							color: #eb3912;
							background-color: transparent;
						}
					}
				}
				// 선택했을때
				.ui-calendar-active {
					&::before {
						background-color: #01a76b;
					}
					.ui-calendar-day {
						color: $white;
					}
					// 금일 선택
					// &.ui-calendar-today::after {
					// 	background-color: $white;
					// }
					&.ui-calendar-extend {
						&::after {
							background-color: $white;
						}
					}
					&.ui-calendar-holiday {
						.ui-calendar-day {
							color: #eb3912;
						}
					}
				}
				// 당월 X
				.ui-calendar-other {
					> .ui-calendar-day {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						align-items: center;
						justify-content: center;
						@include font_sum(
							$w: 500,
							$c: $gray01,
							$box: flex
						);
					}
				}
			}
		}
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 8px;
		background-color: $gray05;
		transform: translateX(-50%);
	}
}

/* 기간검색 */
.calendar_wrap {
	.terms_select {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		> .sel_btn  {
			width: calc((100% - 16px) / 3);
			margin: 0px !important;
			label {
				min-width: 96px;
			}
		}
		& + .multi_form {
			margin-top: 16px;
		}
	}
	.input_wrap {
		.input_cal {
			position:relative;
			input, input:read-only  {
				color: $black !important;
				@include font_pre(0.937rem,400,$gray04,1.5);
			}
			.ui_HdApp_calendar {
				position: absolute;
				top: 12px;
				right: 16px;
				@include bg(24px,24px,'/images/mobilehome2/new/ico_calendar_24_gray.png');
				> span {
					@include hidden;
				}
			}
		}
	}
	.multi_form {
		.input_wrap {
			flex: auto;
		}
		.dash {
			margin-left: 8px;
			@include font_pre(0.937rem,400,$black,1.5);
		}
		&.next_content_cal {
			// 달력 다음 컨텐츠가 존재할 시
			& + .ui-calendar-container {
				padding-bottom: 8px;
				margin-bottom: 24px;
				.ui-select-day {
					padding-bottom: 20px;
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 100vw;
					height: 8px;
					transform: translateX(-50%);
					background-color: $gray05;
				}
			}
		}
		&.active {
			div.input_wrap {
				.input_cal {
					input {
						background-color: $white;
					}
					&.on {
						input {
							border-color: #00905c;
						}
					}
				}
			}
		}
	}
	.select_list {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		div.sel_btn {
			& + .rdo_wrap {
				margin-top:0;
			}
		}
	}
}

/* 기간 검색 팝업 */
div._calendar_date {
	margin-top:0 !important;
	padding-top: 24px !important;
	.coupon_gift_detail_data {
		& + .coupon_gift_detail_data {
			margin-top: 32px;
		}
		// 20231013_[공통영역에서 하단 버튼있을시에 하단 공간 20px 주게끔 수정처리]
		// &:last-child {
		// 	padding-bottom: 20px;
		// }
	}
	// 20231013_[공통영역에서 하단 버튼있을시에 하단 공간 20px 주게끔 수정처리]
	// > div:last-of-type.popup_sub_conts {
	// 	padding-bottom: 20px;
	// }
	.popup_sub_head {
		padding: 0 8px;
		.title {
			span {
				@include font_sum (
					$s: 16,
					$w: 700,
					$h: 24,
					$l: -.25px,
					$box: block
				);
			}
		}
		& + .popup_sub_conts {
			margin-top: 12px;
		}
	}
	.popup_sub_conts {
		&._module {
			margin-right: 0px;
			margin-left: 0px;
		}
		// .calendar_wrap {}
	}

	&.is-btn + .lay_bot_btns {
		.btn_wrap {
			// padding-right: 16px;
			// padding-left: 16px;
			padding-right: 0; //231004_캘린더 하단버튼 레이어하단 공통 간격추가로 인해 삭제
			padding-left: 0; //231004_캘린더 하단버튼 레이어하단 공통 간격추가로 인해 삭제
		}
	}
}

/* 단독 달력 */
.notice_type {
	padding: 32px 16px 20px;
	.lay_conts {
		padding: 0px !important;
		.ui-calendar-container {
			padding: 0px !important;
			margin: 0 !important;
			&::before {
				content: none;
			}
			.ui-select-day {
				padding-top: 0px;
				.ui-calendar-date {
					.ui-calendar-table {
						.ui-calendar-dayname {
							padding-bottom: 15px;
						}
						.ui-calendar-cell {
							border-width: 4px;
							border-left: 0;
							border-right: 0;
						}
					}
				}
			}
		}
	}
	.lay_bot_btns {
		padding: 0 !important;
		margin: 0 !important;
		margin-top: 32px !important;
	}
}
