@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 테이블 상단 */
.top_table_sec {

}
/* //테이블 상단 */

/* 테이블 */
div.table_wrap {
	border-top:1px solid $black;
	overflow-x:auto;
	/* 공통 */
	table {
		width:100%;
		border-collapse:collapse;
		thead {
			tr {
				th {
					// background-color:#F0F5F4;
					background-color:$gray10 !important;
					border-left:1px solid $white;
					text-align:center;
					@include font_pre(0.875rem,700,$black,1.5);
					&:first-child {
						border-left:none;
					}
				}
			}
		}
		tbody {
			tr {
				th {
					border-bottom:1px solid $gray03;
					border-left:1px solid $gray03;
					// background-color:#F0F5F4;
					background-color:$gray10;
					@include font_pre(0.875rem,700,$black,1.5);
					&.first {
						border-left:none;
					}
				}
				td {
					border-bottom:1px solid $gray03;
					border-left:1px solid $gray03;
					@include font_pre(0.875rem,400,$black,1.5);
					word-break: keep-all;
					&:first-child {
						border-left:none;
					}
				}
			}
		}
	}

	/* 가로형 */
	&.col {
		tr {
			th {
				padding:16px 12px;
				// background-color:#F0F5F4;
				background-color:$gray10;
				text-align:center;
			}
			td {
				padding:20px 12px !important;
				text-align:left;
				background-color:$white;
				word-break: keep-all;
			}
		}
	}

	/* 세로형 */
	&.row {
		table {
			tbody {
				tr {
					&:first-child {
						th {
							border-top:none;
						}
					}
					&:last-child {
						th {
							border-bottom:1px solid $gray03;
						}
					}
				}
				th {
					padding:20px 12px;
					border-left:1px solid $white;
					border-right:1px solid $white;
					border-bottom:1px solid $white;
					text-align:left;
				}
				td {
					padding:20px 12px;
				}
			}
		}

		//복합 th인 경우
		.multi_th {
			th[scope='row'] {
				padding-top:21px;
				vertical-align: top;
				border-bottom:1px solid $white;
			}
			th[scope='col'] {
				padding: 14px 0 15px;
			}
			td[colspan] {
				border-bottom:1px solid $white;
			}
		}
	}

	/* 추가 스타일 */
	table {
		tr {
			th {
				&.al_left {
					text-align:left;
				}
				&.al_right {
					text-align:right;
				}
				&.al_center {
					text-align:center;
				}
				&.brd_left {
					border-left:1px solid $white;
					&:first-child {
						border-left:1px solid $white;
					}
				}
				&.brd_bottom {
					border-bottom:1px solid $white;
					&:first-child {
						border-bottom:1px solid $white;
					}
				}
				&.white_th {
					background: $white;
					border-left:1px solid $gray03;
				}
				&.v_align_top {
					vertical-align: top;
				}
			}
			td {
				&.al_left {
					text-align:left;
				}
				&.al_right {
					text-align:right;
				}
				&.al_center {
					text-align:center;
				}
				&.brd_left {
					border-left:1px solid $gray03;
					&:first-child {
						border-left:1px solid $gray03;
					}
				}
				&.white_th {
					background: $white;
					border-left:1px solid $gray03;
				}
				&.v_align_top {
					vertical-align: top;
				}
			}
		}
	}

}
/* //테이블(공통) */


/* 테이블과 하단 컨텐츠간 간격 */
.table_wrap + .btn_wrap {
	margin-top:32px;
}