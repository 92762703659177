@charset "UTF-8";

//font-color
$black : #171A19;
$white : #FFFFFF;
$green : #00905C;
$green02 : #01A76B;
$green03 : #28996F;
$red: #EF5533;
$pred: #FF5000;
$rred: #EB3912;
$blue: #1A4CFF;
$pink : #FF6BD6;
$yellow : #E5BA00;

$gray01 : #D4D6D4; //비활성(텍스트)
$gray02 : #727573; //소텍스트
$gray03 : #EAEBEA; //라인
$gray04 : #9B9E9C;
$gray05 : #F7F7F7; //비활성(면)
$gray06 : #CCE0DF; //인디게이터
$gray07 : #D7D7D7; //바텀네비
$gray08 : #F7FAF9; //면
$gray09 : #434545; //불릿텍스트
$gray10 : #EBF2EF; //결제일선택 면컬러