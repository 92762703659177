@charset "UTF-8";
* {
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: "Happiness-Sans", "Noto Sans", Arial, dotum, "sans-serif";
    -webkit-text-size-adjust: none;
    text-decoration: none;
}

@font-face {
    font-family: "Happiness-Sans";
    font-style: "normal";
    font-weight: 400;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: url("/font/Happiness-Sans-Regular.woff2") format("woff2"), url("/font/Happiness-Sans-Regular.woff") format("woff"), url("/font/Happiness-Sans-Regular.ttf") format("truetype"), url("/font/Happiness-Sans-Regular.svg#svgFontName") format("svg"); /* Legacy iOS */
}
@font-face {
    font-family: "Happiness-Sans";
    font-style: "bold";
    font-weight: 700;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: url("/font/Happiness-Sans-Bold.woff2") format("woff2"), url("/font/Happiness-Sans-Bold.woff") format("woff"), url("/font/Happiness-Sans-Bold.ttf") format("truetype"), url("/font/Happiness-Sans-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
}
@font-face {
    font-family: "Happiness-Sans-Title";
    font-style: "bold";
    font-weight: 900;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: url("/font/Happiness-Sans-Title.woff2") format("woff2"), url("/font/Happiness-Sans-Title.woff") format("woff"), url("/font/Happiness-Sans-Title.ttf") format("truetype"), url("/font/Happiness-Sans-Title.svg#svgFontName") format("svg"); /* Legacy iOS */
}
@font-face {
    font-family: "Pretendard";
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: local("Pretendard Regular"), url("/font/Pretendard-Regular.woff2") format("woff2"), url("/font/Pretendard-Regular.woff") format("woff");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: local("Pretendard Medium"), url("/font/Pretendard-Medium.woff2") format("woff2"), url("/font/Pretendard-Medium.woff") format("woff");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 700;
    font-style: "bold";
    font-display: swap;
    src: local("Pretendard Bold"), url("/font/Pretendard-Bold.woff2") format("woff2"), url("/font/Pretendard-Bold.woff") format("woff");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 800;
    font-display: swap;
    font-style: "bold";
    src: local("Pretendard ExtraBold"), url("/font/Pretendard-ExtraBold.woff2") format("woff2"), url("/font/Pretendard-ExtraBold.woff") format("woff");
}
/* 기본폰트정의 */
div, p, span, dt, dd, li, label, a, button, dfn, i {
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

em, strong {
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* //기본폰트정의 */
/* 타이틀폰트 스타일 */
.tit01 {
    font-size: 2rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -1px;
    font-family: "Happiness-Sans-Title";
    font-family: "Happiness-Sans-Title" !important;
}

.tit02 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}
.tit02 > em {
    font-size: 1.5rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}

.tit03 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}
.tit03 > em {
    font-size: 1.25rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}

.tit04 {
    letter-spacing: -0.8px;
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.tit04 > em {
    font-size: 1.125rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* //타이틀폰트 스타일 */
/* 단독 크기 정의 */
.f16 {
    font-size: 1rem;
}

/* 기본 탭 정의 */
div.tab_wrap {
    margin-left: -16px;
    margin-right: -16px;
    /*
    	20230602 : 가이드 h2 tab 공통화 디자인으로 적용
    */
}
div.tab_wrap .tabs ul {
    display: flex;
    justify-content: flex-start;
}
div.tab_wrap .tabs ul li {
    width: 100%;
    margin-left: 0;
    flex: 1;
    border-bottom: 1px solid #D4D6D4;
}
div.tab_wrap .tabs ul li a {
    position: relative;
    display: block;
    width: 100%;
    padding: 11px 0 10px;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap .tabs ul li a span {
    font-size: 1rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap .tabs ul li.on a {
    border-bottom: 3px solid #01A76B;
}
div.tab_wrap .tabs ul li.on a > em {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.tab_wrap .tabs ul li.on a > span {
    font-size: 1rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap .tabs ul li.on a > span:after {
    content: none;
}
div.tab_wrap .tabs ul li.on a:after {
    content: none;
}
div.tab_wrap .tabs.sc_tab {
    overflow: hidden;
    overflow-x: scroll;
    background-color: #FFFFFF;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
div.tab_wrap .tabs.sc_tab::-webkit-scrollbar {
    display: none;
}
div.tab_wrap .tabs.sc_tab ul {
    display: inline-block;
    width: 100%;
}
div.tab_wrap .tabs.sc_tab ul li {
    display: inline-block;
    width: 30%;
    height: 40px;
    margin-right: -3px;
}
div.tab_wrap .tabs.sticky_tab {
    position: sticky;
    top: 50px;
    z-index: 20;
    background: white;
}
div.tab_wrap.sub ul li a {
    padding: 12px 0 10px;
}
div.tab_wrap.sub ul li a > span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap.sub ul li.on a > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap.sub ul li.on a > span:after {
    content: none;
}
div.tab_wrap.sub ul li.on a:after {
    content: none;
}
div.tab_wrap.sub ul li.alam a span {
    position: relative;
}
div.tab_wrap.sub ul li.alam a span:after {
    width: 5px;
    height: 5px;
    background-image: url(initial);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #FF5000;
    border-radius: 15px;
    content: "";
    position: absolute;
    top: -2px;
    left: initial;
    bottom: 0;
    right: -8px;
}
div.tab_wrap.ssub .tabs ul li a {
    padding: 8px 0 7px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap.ssub .tabs ul li.on a {
    border-bottom: 3px solid #171A19;
}
div.tab_wrap.ssub .tabs ul li.on a > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap.ssub.inline .tabs {
    padding: unset;
    padding-left: 24px;
    border-bottom: 1px solid #D4D6D4;
}
div.tab_wrap.ssub.inline .tabs ul {
    column-gap: 16px;
}
div.tab_wrap.ssub.inline .tabs ul li {
    width: auto;
    border: 0px;
    flex: initial;
}
div.tab_wrap.ssub.inline .tabs ul li a {
    padding: unset;
    padding-top: 8px;
}
div.tab_wrap.ssub.inline .tabs ul li a > span {
    padding-bottom: 5px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9b9e9c;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    font-size: 14px;
}
div.tab_wrap.ssub.inline .tabs ul li.on a > span {
    color: #171A19;
    font-weight: 700;
}
div.tab_wrap.inline_type > .tabs {
    border-bottom: 1px solid #EAEBEA;
}
div.tab_wrap.inline_type > .tabs ul {
    position: relative;
    display: block;
    width: 100vw;
    left: 50%;
    padding: 0 24px;
    transform: translateX(-50%);
    box-sizing: border-box;
}
div.tab_wrap.inline_type > .tabs ul li {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0px;
    padding-right: 20px;
    border-bottom: none;
}
div.tab_wrap.inline_type > .tabs ul li:first-of-type a {
    padding-left: 0px;
}
div.tab_wrap.inline_type > .tabs ul li a {
    width: auto;
    padding: 0px;
    font-size: 0;
    line-height: 0;
}
div.tab_wrap.inline_type > .tabs ul li a span {
    padding: 12px 0;
    border-bottom: 3px solid transparent;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.inline_type > .tabs ul li a span > em {
    padding-left: 4px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.inline_type > .tabs ul li.on a {
    border-bottom: 0px;
}
div.tab_wrap.inline_type > .tabs ul li.on a span {
    border-bottom: 3px solid #01A76B;
    font-weight: 700;
    color: #01A76B;
}
div.tab_wrap.inline_type > .tabs ul li.on a span > em {
    color: #01A76B;
    font-weight: 700;
}
div.tab_wrap.inline_type > .tabs ul li.alam {
    padding-right: 28px;
}
div.tab_wrap.inline_type > .tabs ul li.alam a span {
    position: relative;
}
div.tab_wrap.inline_type > .tabs ul li.alam a span:after {
    border: 0px !important;
    width: 5px;
    height: 5px;
    background-image: url(initial);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #FF5000;
    border-radius: 15px;
    content: "";
    position: absolute;
    top: 12px;
    left: initial;
    bottom: 0;
    right: -8px;
}
div.tab_wrap.inline_type02 {
    margin: 0;
}
div.tab_wrap.inline_type02 > .tabs ul {
    position: initial;
    width: 100%;
    display: flex;
    padding: 0px;
    border: none;
    left: auto;
    transform: none;
    align-items: center;
    justify-content: flex-start;
}
div.tab_wrap.inline_type02 > .tabs ul > li {
    display: inline-block;
    width: auto;
    border-bottom: none;
    flex: initial;
}
div.tab_wrap.inline_type02 > .tabs ul > li > a {
    display: inline-block;
    width: auto;
    padding: 10px 10px 0px;
    border-bottom: none;
    line-height: 0px;
}
div.tab_wrap.inline_type02 > .tabs ul > li > a > span {
    display: inline-block;
    padding-bottom: 4px;
    border-bottom: 3px solid transparent;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.tab_wrap.inline_type02 > .tabs ul > li:first-of-type a {
    padding-left: 8px;
}
div.tab_wrap.inline_type02 > .tabs ul > li.on a {
    border-bottom: none;
}
div.tab_wrap.inline_type02 > .tabs ul > li.on a span {
    font-weight: 700;
    color: #01A76B;
    border-color: #01A76B;
}
div.tab_wrap.inline_type02 > .tab_conts {
    padding-top: 24px;
}
div.tab_wrap.inline_type02 > .tab_conts .no_result {
    margin: 80px 0;
}
div.tab_wrap.inner_type {
    margin: 0px;
}
div.tab_wrap.inner_type > .tabs {
    margin-top: 16px;
}
div.tab_wrap.inner_type > .tabs ul li {
    flex: initial;
    width: auto;
    border: none;
}
div.tab_wrap.inner_type > .tabs ul li + li {
    margin-left: 8px;
}
div.tab_wrap.inner_type > .tabs ul li a {
    width: auto;
    padding: 5px 12px 6px;
    border: none;
    border-radius: 40px;
    background-color: #F7F7F7;
    display: inline-block;
    font-size: 0rem;
    font-weight: 400;
    color: #171A19;
    line-height: NaN;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.inner_type > .tabs ul li a > span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.inner_type > .tabs ul li.on a > span {
    font-weight: 700;
    color: #171A19;
}
div.tab_wrap.sub_tab > .tabs {
    border-bottom: 1px solid rgba(23, 26, 25, 0.1019607843);
}
div.tab_wrap.sub_tab > .tabs ul {
    padding-left: 24px;
    font-size: 0;
    line-height: 0;
    box-sizing: border-box;
}
div.tab_wrap.sub_tab > .tabs ul li {
    width: auto;
    height: auto;
    margin: 0;
    border: 0px;
}
div.tab_wrap.sub_tab > .tabs ul li a {
    position: relative;
    display: block;
    width: auto;
    padding: 12px 16px;
    border-bottom: 3px solid transparent;
    font-size: 0px;
    line-height: 0;
}
div.tab_wrap.sub_tab > .tabs ul li a > span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.sub_tab > .tabs ul li a > span em {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    margin-left: 4px;
}
div.tab_wrap.sub_tab > .tabs ul li a > span:after {
    content: none;
}
div.tab_wrap.sub_tab > .tabs ul li a:after {
    content: none;
}
div.tab_wrap.sub_tab > .tabs ul li.on a {
    border-bottom: 3px solid #01A76B;
}
div.tab_wrap.sub_tab > .tabs ul li.on a > span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.tab_wrap.sub_tab > .tabs ul li.on a > span em {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    margin-left: 4px;
}
div.tab_wrap.sub_tab > .tabs ul li.on a > span:after {
    content: none;
}
div.tab_wrap.sub_tab > .tabs ul li.on a:after {
    content: none;
}
div.tab_wrap.sub_tab > .tabs ul li.alam a span {
    position: relative;
}
div.tab_wrap.sub_tab > .tabs ul li.alam a span:after {
    border: 0px !important;
    width: 5px;
    height: 5px;
    background-image: url(initial);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #FF5000;
    border-radius: 15px;
    content: "";
    position: absolute;
    top: -2px;
    left: initial;
    bottom: 0;
    right: -8px;
}

.lay_conts div.tab_wrap .tabs.sticky_tab {
    top: 0px;
}
.lay_conts div.tab_wrap .tabs.sticky_tab.app_fixed {
    position: fixed;
    width: 100%;
    top: 50px;
}
.lay_conts div.tab_wrap .tabs.sticky_tab.app_fixed + .tab_conts {
    padding-top: 50px;
}

/* //기본 탭 정의 */
div.slide_wrap {
    position: relative;
    visibility: hidden;
}
div.slide_wrap .indi_wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
div.slide_wrap .indi_wrap .indi_conts {
    font-size: 0;
    text-align: center;
}
div.slide_wrap .indi_wrap .indi_conts > li {
    display: inline-block;
    line-height: 0;
    font-size: 0;
}
div.slide_wrap .indi_wrap .indi_conts > li .btn_indi > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.slide_wrap .indi_wrap .indi_conts > li + li {
    margin-left: 8px;
}
div.slide_wrap .indi_wrap .indi_control {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
}
div.slide_wrap .indi_wrap .indi_control > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.slide_wrap .slide_page_number {
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 10;
}
div.slide_wrap .slide_page_number span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    opacity: 0.5;
}
div.slide_wrap .slide_page_number span.current_num {
    font-size: 1.25rem;
    opacity: 1;
}
div.slide_wrap .slide_page_number .total::before {
    content: "";
    display: inline-block;
    margin-top: -2px;
    margin-right: 6px;
    margin-bottom: 0;
    margin-left: 6px;
    vertical-align: top;
    width: 1px;
    height: 10px;
    background-color: #FFFFFF;
    opacity: 0.5;
    vertical-align: middle;
}
div.slide_wrap .indi_progress {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    height: 2px;
}
div.slide_wrap .indi_progress .track {
    height: 2px;
    width: 100%;
}
div.slide_wrap .indi_progress .track .current {
    display: block;
    height: 2px;
    background-color: rgb(0, 0, 0);
}
div.slide_wrap .indi_progress .track .current > i {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.slide_wrap .slide_content .slide_track {
    content: " ";
    display: flex;
    clear: both;
    overflow: hidden;
}
div.slide_wrap .slide_content .slide_conts {
    position: relative;
    font-size: 0;
    line-height: 0px;
}
div.slide_wrap .slide_content .slide_conts a {
    background-image: url("/images/mo/shopping/bg_no_img.png");
    background-position: center;
    background-size: cover;
}
div.slide_wrap .slide_controls {
    position: absolute;
    display: block;
    top: 50%;
    left: initial;
    max-width: initial;
    z-index: 10;
    width: 100%;
    transform: translateY(-20px);
}
div.slide_wrap .slide_controls.pos_top_25 {
    top: 25vw;
}
div.slide_wrap .slide_controls button {
    position: absolute;
    width: 40px;
    height: 40px;
    opacity: 1;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.slide_wrap .slide_controls button.prev {
    left: 8px;
    right: initial;
    transform: rotate(180deg);
}
div.slide_wrap .slide_controls button.next {
    left: initial;
    right: 8px;
}
div.slide_wrap .slide_controls button > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.slide_wrap .slide_controls button.disabled {
    opacity: 0.3;
}
div.slide_wrap.ban_type {
    overflow: unset;
    margin-bottom: 24px;
}
div.slide_wrap.ban_type .indi_wrap {
    bottom: -20px;
}
div.slide_wrap.ban_type .indi_wrap .indi_conts > li .btn_indi {
    width: 6px;
    height: 6px;
    background-color: #CCE0DF;
    border-radius: 16px;
}
div.slide_wrap.ban_type .indi_wrap .indi_conts > li.on .btn_indi {
    width: 20px;
    background-color: #171A19;
}
div.slide_wrap.ban_type .slide_controls {
    display: none;
}
div.slide_wrap.ban_type .slide_controls button.disabled {
    opacity: 0;
}
div.slide_wrap.none_indi {
    margin-bottom: 0px !important;
}

div.rolling_wrap .list_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
div.rolling_wrap .list_wrap > ul > li > a {
    display: block;
}

/* 레이어 팝업 정의 */
article.lay_pop {
    display: none;
    width: calc(100% - 40px);
    /* 이미지전용 */
    /* size */
}
article.lay_pop + .input_wrap {
    margin-top: 8px;
}
article.lay_pop.view_image .lay_wrap div.slide_wrap.ban_type {
    margin-bottom: 0px;
}
article.lay_pop .lay_wrap {
    position: relative;
    padding: 20px 24px 40px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}
article.lay_pop .lay_wrap .lay_tit {
    min-height: 39px;
    padding-bottom: 12px;
    padding-right: 20px;
    border-bottom: none;
}
article.lay_pop .lay_wrap .lay_tit h1 {
    line-height: 1.8;
}
article.lay_pop .lay_wrap .lay_tit h1 span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}
article.lay_pop .lay_wrap .lay_tit h1 > a {
    display: inline-block;
    margin-top: 0;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_arrow_left_24_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
}
article.lay_pop .lay_wrap .lay_tit h1 > a > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
article.lay_pop .lay_wrap .lay_conts {
    padding: 16px 16px 0;
    height: calc(100% - 50px);
    overflow-y: auto;
    max-height: none;
}
article.lay_pop .lay_wrap .lay_conts.is-btn {
    max-height: calc(100% - 142px);
}
article.lay_pop .lay_wrap .lay_bot_btns {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    min-height: 56px;
    padding: 0 16px 20px;
    text-align: center;
}
article.lay_pop .lay_wrap .lay_bot_btns .btn_wrap {
    width: 100%;
}
article.lay_pop .lay_wrap .lay_bot_btns .btn_wrap button + button, article.lay_pop .lay_wrap .lay_bot_btns .btn_wrap button + a, article.lay_pop .lay_wrap .lay_bot_btns .btn_wrap a + button, article.lay_pop .lay_wrap .lay_bot_btns .btn_wrap a + a {
    margin-left: 8px;
}
article.lay_pop .lay_wrap.alert {
    width: auto;
    border-radius: 16px;
    padding: 20px 16px;
}
article.lay_pop .lay_wrap.alert .lay_conts {
    padding: 20px 0 0;
}
article.lay_pop .lay_wrap.alert .lay_conts > div {
    padding: 0px 24px;
    text-align: center;
}
article.lay_pop .lay_wrap.alert .lay_conts > div.ico:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 16px;
    margin-left: auto;
    vertical-align: top;
    display: block;
    width: 88px;
    height: 88px;
    background-image: url("/images/mobilehome2/new/ico_no_result_88.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
article.lay_pop .lay_wrap.alert .lay_conts > div p {
    font-size: 1.125rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    word-break: keep-all;
}
article.lay_pop .lay_wrap.alert .lay_conts > div p > b {
    word-break: keep-all;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
article.lay_pop .lay_wrap.alert .lay_conts > div .sub_desc {
    margin-top: 8px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop .lay_wrap.alert .lay_conts .btn_wrap {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 32px;
}
article.lay_pop .lay_wrap.alert .lay_conts .btn_wrap .btn {
    padding-top: 17px;
    padding-bottom: 17px;
}
article.lay_pop .lay_wrap.alert .lay_conts .btn_wrap .btn > span {
    display: inline-block;
    font-size: 0.9375rem;
    line-height: 1.4666666667;
    font-family: "Happiness-Sans" !important;
}
article.lay_pop .lay_wrap.alert .lay_conts .btn_wrap .btn.border {
    border: 2px solid #171A19;
}
article.lay_pop .lay_wrap.alert .lay_tit {
    text-align: center;
    padding-right: 0;
}
article.lay_pop .lay_wrap.notice_type {
    padding: 20px 24px;
    border-radius: 18px;
}
article.lay_pop .lay_wrap.notice_type .lay_tit {
    min-height: auto;
    padding-bottom: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #EAEBEA;
}
article.lay_pop .lay_wrap.notice_type .lay_tit h1 {
    line-height: 1;
}
article.lay_pop .lay_wrap.notice_type .lay_tit h1 span {
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop .lay_wrap.notice_type .lay_conts {
    padding: 20px 0 0 !important;
}
article.lay_pop .lay_wrap.notice_type .lay_bot_btns {
    padding: 0 !important;
}
article.lay_pop .lay_wrap.notice_type .close {
    top: 16px;
}
article.lay_pop .lay_wrap .close {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 5;
}
article.lay_pop .lay_wrap .close button {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_close_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: top;
}
article.lay_pop .lay_wrap .close button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
article.lay_pop.view_image .lay_wrap {
    padding: 0;
}
article.lay_pop.view_image .lay_wrap .img_wrap {
    width: 100%;
}
article.lay_pop.view_image.full .lay_wrap .lay_conts {
    padding: 7px 0 0;
}
article.lay_pop.view_image.full .lay_wrap .lay_conts .img_wrap {
    position: relative;
    width: 100%;
}
article.lay_pop.view_image.full .lay_wrap .lay_conts .img_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
}
article.lay_pop.view_image.full .lay_wrap .ban_type {
    height: calc(100% - 60px);
}
article.lay_pop.view_image.full .lay_wrap .ban_type .slide_content {
    height: 100%;
}
article.lay_pop.view_image.full .lay_wrap .ban_type .slide_content .slide_track {
    height: 100%;
}
article.lay_pop.view_image.full .lay_wrap .ban_type .slide_content .slide_track .slide_conts {
    overflow-y: auto;
}
article.lay_pop.view_image.full .lay_wrap .ban_type .slide_content .slide_track .slide_conts .slide_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 100%;
}
article.lay_pop.small {
    width: 335px;
}
article.lay_pop.full {
    width: 100%;
    height: 100%;
}
article.lay_pop.full .lay_wrap {
    height: 100%;
    padding: 0;
    overflow: hidden;
}
article.lay_pop.full .lay_wrap .lay_tit {
    min-height: 50px;
    box-sizing: border-box;
    padding: 8px 20px 0;
}
article.lay_pop.full .lay_wrap .lay_tit h1 {
    padding: 0;
}
article.lay_pop.full .lay_wrap .lay_tit.al_cetner {
    text-align: center;
}
article.lay_pop.full .lay_wrap .lay_conts {
    overflow-y: auto;
}
article.lay_pop.full .lay_wrap .lay_conts.is-btn {
    max-height: calc(100% - 164px);
}
article.lay_pop.full .lay_wrap .lay_bot_btns {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
article.lay_pop.full .lay_wrap .lay_bot_btns .btn_wrap {
    margin-top: 2px;
}
article.lay_pop.ui_option_popup {
    width: 100%;
}
article.lay_pop.ui_option_popup .lay_conts {
    max-height: 274px;
    overflow-y: auto;
}
article.lay_pop.ui_option_popup .lay_bot_btns {
    margin-top: 12px;
}
article.lay_pop.bottom_type {
    width: 100%;
}
article.lay_pop.bottom_type .lay_wrap {
    position: relative;
    height: 100%;
    padding: 20px 0;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
article.lay_pop.bottom_type .lay_wrap .lay_tit {
    min-height: initial;
    padding-left: 24px;
    padding-bottom: 0;
    padding-right: 24px;
}
article.lay_pop.bottom_type .lay_wrap .lay_tit h1 span {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4444444444;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
article.lay_pop.bottom_type .lay_wrap .lay_tit h1 .sub_desc {
    display: block;
    margin-top: 8px;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts {
    max-height: calc(95vh - 214px);
    padding: 0 16px;
    margin-top: 24px;
    overflow-y: auto;
    /*
     * 20231013_[공통영역에서 하단 버튼있을시에 하단 공간 20px 주게끔 수정처리]
     * 하단 버튼이 마진 20px 으로 인해 padding-bottom: 40px 으로 적용
     */
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.branch_select {
    padding-bottom: 20px;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.popup_min_height {
    min-height: 63px;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts .tab_wrap {
    margin-left: -16px;
    margin-right: -16px;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.check_alert {
    padding-top: 8px;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.full_wrap {
    margin-left: -20px;
    margin-right: -20px;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.info_notice .txt {
    margin-bottom: 6px;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.is-btn {
    padding-bottom: 20px;
    box-sizing: border-box;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.rcmd_search {
    padding-bottom: 0;
}
article.lay_pop.bottom_type .lay_wrap .lay_conts.branch_select .form_type01 > dl > dt {
    margin-left: 8px;
}
article.lay_pop.bottom_type .lay_wrap .lay_bot_btns {
    margin-top: 20px;
    padding: 0 16px !important;
}
article.lay_pop.bottom_type .lay_wrap .close {
    top: 20px;
    right: 24px;
}
article.lay_pop.bottom_fix {
    min-height: auto !important;
}

/* //레이어 팝업 정의 */
/* 레이어 팝업 웹뷰로 띄웠을 경우 타입 */
article.new_page_full_popup {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
}

/* 딤 레이어 */
.dim_layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

/* //딤 레이어 */
/* 툴팁 레이어 */
.tooltip_wrap {
    position: relative;
    z-index: 5;
    display: inline-block;
    vertical-align: top;
}
.tooltip_wrap .btn_tooltip {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_tooltip_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.tooltip_wrap .btn_tooltip > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.tooltip_wrap .bubble_wrap {
    display: none;
    position: absolute;
    top: 29px;
    min-width: 150px;
    padding: 12px 40px 12px 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #171A19;
}
.tooltip_wrap .bubble_wrap:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
}
.tooltip_wrap .bubble_wrap.tr {
    right: -12px;
}
.tooltip_wrap .bubble_wrap.tr:before {
    top: -27px;
    right: 12px;
    border-bottom: 14px solid #171A19;
    border-top: 20px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.tooltip_wrap .bubble_wrap.tl {
    left: -12px;
}
.tooltip_wrap .bubble_wrap.tl:before {
    top: -27px;
    left: 12px;
    border-bottom: 14px solid #171A19;
    border-top: 20px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.tooltip_wrap .bubble_wrap.tc {
    left: -118px;
}
.tooltip_wrap .bubble_wrap.tc:before {
    top: -27px;
    left: 118px;
    border-bottom: 14px solid #171A19;
    border-top: 20px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.tooltip_wrap .bubble_wrap.br {
    bottom: 32px;
    top: auto;
}
.tooltip_wrap .bubble_wrap.br:before {
    top: auto;
    bottom: -27px;
    border-top: 14px solid #171A19;
    border-bottom: 20px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.tooltip_wrap .bubble_wrap .desc {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    word-break: keep-all;
}
.tooltip_wrap .bubble_wrap .desc .txt {
    font-size: 0.75rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.tooltip_wrap .bubble_wrap .desc .txt + .txt {
    margin-top: 8px;
}
.tooltip_wrap .bubble_wrap .btn_close {
    position: absolute;
    top: 12px;
    right: 16px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_close_20_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.tooltip_wrap .bubble_wrap .btn_close span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.tooltip_wrap.open .bubble_wrap {
    display: block;
}

/* //툴팁 레이어 */
/* toast */
.toast {
    position: fixed;
    width: 100%;
    bottom: 20px;
    z-index: 99999;
}
.toast .toast_box {
    position: relative;
    width: calc(100% - 85px);
    padding: 16px 33px 16px 20px;
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 144, 92, 0.9);
}
.toast .toast_box span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    color: #FFFFFF !important;
}
.toast .toast_box button {
    display: block;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_close_20_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.toast .toast_box.no_x_btn_toast {
    padding-top: 8px; /* 20230714_[디자인 공통 적용] */
    padding-right: 20px;
    padding-bottom: 8px; /* 20230714_[디자인 공통 적용] */
}

/* toast */
/* 레이아웃 */
#contents {
    width: 100%;
    min-height: 380px;
    box-sizing: border-box;
}
#contents.no_footer {
    padding-bottom: 80px;
}
#contents .conts {
    margin-top: 50px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 80px;
}
#contents .conts .box_wrap + .box_wrap {
    margin-top: 40px;
}
#contents .conts .box_wrap.fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    z-index: 10;
}
#contents .conts .box_wrap.fixed.wbox {
    bottom: -8px;
    box-shadow: 0 -4px 27px 0 rgba(0, 0, 0, 0.12);
}
#contents .conts .box_wrap.fixed.btm_btn {
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
}
#contents .conts .box_wrap.fixed.btm_btn .btn_wrap {
    margin-left: 0;
    margin-right: 0;
}
#contents .conts .box_wrap.wbox {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #FFFFFF;
}
#contents .conts .box_wrap.wbox.fixed {
    left: 0;
    right: 0;
    z-index: 5;
}
#contents .conts .box_wrap.btm_btn {
    padding-left: 0;
    padding-right: 0;
}
#contents .conts .box_wrap.btm_btn .btn_wrap {
    margin-left: 0;
    margin-right: 0;
}
#contents .conts .tab_wrap .tab_conts .box_wrap.btm_btn {
    padding-left: 0;
    padding-right: 0;
}
#contents .conts .tab_wrap .tab_conts .box_wrap.btm_btn .btn_wrap {
    margin-left: 16px;
    margin-right: 16px;
}

/* //레이아웃 */
/* 쉐도우박스(일반) */
.wbox {
    margin: 8px 0;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}
.wbox + .wbox {
    margin-top: 16px;
}

.btm_btn {
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}
.btm_btn.wbox {
    box-shadow: none;
}
.btm_btn.wbox .btn_wrap {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.btm_btn.wbox.fixed {
    margin-top: 48px !important;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0 -4px 27px 0 rgba(0, 0, 0, 0.12);
}
.btm_btn.wbox .desc {
    padding: 16px 0;
    text-align: center;
}
.btm_btn.wbox .total_price {
    padding: 16px 8px;
}
.btm_btn.wbox .total_price dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btm_btn.wbox .total_price dl dt {
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.btm_btn.wbox .total_price dl dd {
    font-size: 1rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* 결과 없는 경우 */
.no_result::before {
    width: 88px;
    height: 88px;
    background-image: url("/images/mobilehome2/new/ico_no_result_88.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 8px;
    margin-left: auto;
    vertical-align: top;
    display: block;
}
.no_result .comment {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.no_result .add_comment {
    margin-top: 4px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.no_result .add_comment.center {
    text-align: center;
}
.no_result.list_type::before {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/new/ico_no_result_88.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 12px;
    margin-left: auto;
    vertical-align: top;
    display: block;
}
.no_result.list_type .comment {
    text-align: center;
    font-size: 0.937rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.no_result.top_line {
    margin-top: 0px !important;
    padding-top: 80px;
    border-top: 8px solid #F7F7F7;
}
.no_result .btn_wrap {
    margin-top: 24px;
}
.no_result .btn_wrap .btn.border.inline {
    width: auto;
    padding-right: 15px;
    padding-left: 15px;
    border-width: 2px;
}

/* 하단 공통 공지(참고사항) */
div.btm_notice_line {
    margin-top: 48px;
}
div.btm_notice_line:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: -16px;
    margin-bottom: 24px;
    margin-left: -16px;
    vertical-align: top;
    display: block;
    width: auto;
    height: 8px;
    background-color: #F7F7F7;
}
div.btm_notice_line .bullet_list.dot li, div.btm_notice_line .bullet_list.dot p {
    color: #727573;
}
div.btm_notice_line .ui_accordion {
    margin-top: -24px;
}
div.btm_notice_line .ui_accordion > ul > li {
    border-bottom: none;
}
div.btm_notice_line .ui_accordion > ul > li .head {
    position: relative;
    padding: 16px 0px !important;
    background-color: #FFFFFF !important;
}
div.btm_notice_line .ui_accordion > ul > li .head > div {
    font-size: 0.9375rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btm_notice_line .ui_accordion > ul > li .head .ui_accord_toggle {
    display: block;
    position: absolute;
    top: 16px;
    right: -4px;
    padding: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(-180deg);
}
div.btm_notice_line .ui_accordion > ul > li .head .ui_accord_toggle > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.btm_notice_line .ui_accordion > ul > li.active .head .ui_accord_toggle {
    top: 18px;
    transform: rotate(0);
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content {
    padding: 16px 0px !important;
    background-color: #FFFFFF !important;
    border-top: 1px solid #EAEBEA;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl dt {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl dt + dd {
    margin-top: 8px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl dt.sub_title01 {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl dd + dt {
    margin-top: 12px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl + dl, div.btm_notice_line .ui_accordion > ul > li .ui_accord_content dl + .bullet_list {
    margin-top: 24px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content .bullet_list + dl, div.btm_notice_line .ui_accordion > ul > li .ui_accord_content .bullet_list + .bullet_list {
    margin-top: 24px;
}
div.btm_notice_line .ui_accordion > ul > li .ui_accord_content pre {
    margin-top: 12px;
    white-space: pre-wrap;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btm_notice_line._space16_type .ui_accordion {
    padding-left: 8px;
    padding-right: 8px;
}

/* 박스 상단 간격 전용 클래스(수정용) */
.cnt32 {
    margin-top: 32px !important;
}

.cnt40 {
    margin-top: 40px !important;
}

.cnt80 {
    margin-top: 80px !important;
}

.mt12 {
    margin-top: 12px !important;
}

.mt24 {
    margin-top: 24px !important;
}

/* position 속성 전용 */
.pos_rel {
    position: relative;
}

/* 양쪽 간격 24px(기본 16px일때) 경우 */
.inner_pd {
    padding-left: 8px;
    padding-right: 8px;
}

.basic_inner_pd {
    padding-left: 16px;
    padding-right: 16px;
}

/* 블렛 정의 */
ul.bullet_list > li {
    position: relative;
    word-break: keep-all;
    font-size: 0.875rem;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
ul.bullet_list > li > a {
    color: #171A19;
}
ul.bullet_list > li + li {
    margin-top: 8px;
}
ul.bullet_list > li .rred {
    font-weight: 400;
    color: #EB3912 !important;
}
ul.bullet_list.square > li {
    padding-left: 13px;
}
ul.bullet_list.square > li:before {
    width: 3px;
    height: 3px;
    background-color: #434545;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
}
ul.bullet_list.square + li {
    margin-top: 8px;
}
ul.bullet_list.dot li {
    padding-left: 5px;
}
ul.bullet_list.dot li:before {
    content: " ";
    position: absolute;
    top: 7px;
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 10px;
    background-color: #727573;
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    bottom: 0;
    right: 0;
}
ul.bullet_list.dot.strong > li {
    padding-left: 10px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    display: block;
}
ul.bullet_list.dot.strong > li:before {
    content: " ";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #727573;
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    bottom: 0;
    right: 0;
}
ul.bullet_list.dot.gray > li {
    padding-left: 10px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    display: block;
}
ul.bullet_list.dot.gray > li > p {
    color: #727573;
}
ul.bullet_list.dot.gray > li em, ul.bullet_list.dot.gray > li strong {
    color: #727573;
}
ul.bullet_list.dot.gray > li:before {
    background-color: #727573;
}
ul.bullet_list.dot.red > li {
    padding-left: 8px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #EB3912;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    display: block;
}
ul.bullet_list.dot.red > li:before {
    background-color: #EB3912;
}
ul.bullet_list.bar > li {
    padding-left: 10px;
}
ul.bullet_list.bar > li:before {
    width: 5px;
    height: 1px;
    background-color: #171A19;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
    transform: rotate(0deg);
}
ul.bullet_list.star > li {
    padding-left: 10px;
}
ul.bullet_list.star > li:before {
    content: "*" !important;
    color: #171A19;
    content: "";
    position: absolute;
    top: 3px;
    left: -2px;
    bottom: 0;
    right: 0;
}
ul.bullet_list.alert > li {
    color: #EF5533;
}
ul.bullet_list.alert > li:before {
    background-color: #EF5533;
}

/* //블렛 정의 */
/* 버튼 정의 */
button {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
}

div.btn_wrap {
    display: flex;
    justify-content: center;
    font-size: 0;
}
div.btn_wrap button,
div.btn_wrap a {
    width: 100%;
    /*jgh 40px짜리버튼 비활성화시 글자색이 변하지 않아 추가 */
}
div.btn_wrap button + button, div.btn_wrap button + a,
div.btn_wrap a + button,
div.btn_wrap a + a {
    margin-left: 8px;
}
div.btn_wrap button.btn,
div.btn_wrap a.btn {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 8px 0 9px;
    border-radius: 10px;
    text-align: center;
    background-color: #171A19;
    border: 1px solid #171A19;
}
div.btn_wrap button.btn > span,
div.btn_wrap a.btn > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.6;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.border,
div.btn_wrap a.btn.border {
    background-color: #FFFFFF !important;
}
div.btn_wrap button.btn.border > span,
div.btn_wrap a.btn.border > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19 !important;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.em,
div.btn_wrap a.btn.em {
    border: 2px solid #00905C;
    background-color: #00905C;
}
div.btn_wrap button.btn.em > span,
div.btn_wrap a.btn.em > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.em_border,
div.btn_wrap a.btn.em_border {
    border: 2px solid #00905C;
    background-color: #FFFFFF;
}
div.btn_wrap button.btn.em_border > span,
div.btn_wrap a.btn.em_border > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #00905C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.gray,
div.btn_wrap a.btn.gray {
    border: 2px solid #EAEBEA;
    background-color: #EAEBEA;
}
div.btn_wrap button.btn.gray > span,
div.btn_wrap a.btn.gray > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.gray_border,
div.btn_wrap a.btn.gray_border {
    border: 2px solid #EAEBEA;
    background-color: #FFFFFF;
}
div.btn_wrap button.btn.gray_border > span,
div.btn_wrap a.btn.gray_border > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.white_border,
div.btn_wrap a.btn.white_border {
    border: 1.4px solid #FFFFFF;
    background-color: transparent;
}
div.btn_wrap button.btn.white_border > span,
div.btn_wrap a.btn.white_border > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.btn.inline_box,
div.btn_wrap a.btn.inline_box {
    width: auto;
    padding-right: 16px;
    padding-left: 16px;
}
div.btn_wrap button.lbtn,
div.btn_wrap a.lbtn {
    display: inline-block;
    width: 100%;
    height: 56px;
    padding: 15px 0 15px;
    border-radius: 10px;
    text-align: center;
    background-color: #171A19;
    border: 2px solid #171A19;
    box-sizing: border-box;
}
div.btn_wrap button.lbtn > span,
div.btn_wrap a.lbtn > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.6;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.lbtn.border,
div.btn_wrap a.lbtn.border {
    border-radius: 8px;
    background-color: #FFFFFF;
}
div.btn_wrap button.lbtn.border > span,
div.btn_wrap a.lbtn.border > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.lbtn.thick_border,
div.btn_wrap a.lbtn.thick_border {
    border: 2px solid #171A19;
    background-color: #FFFFFF;
}
div.btn_wrap button.lbtn.thick_border > span,
div.btn_wrap a.lbtn.thick_border > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.lbtn.em,
div.btn_wrap a.lbtn.em {
    border: 2px solid #00905C;
    background-color: #00905C;
}
div.btn_wrap button.lbtn.em > span,
div.btn_wrap a.lbtn.em > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.lbtn.em_border,
div.btn_wrap a.lbtn.em_border {
    border: 2px solid #00905C;
    background-color: #FFFFFF;
}
div.btn_wrap button.lbtn.em_border > span,
div.btn_wrap a.lbtn.em_border > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #00905C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn,
div.btn_wrap a.sbtn {
    display: inline-block;
    width: 100%;
    padding: 3px 12px 4px;
    border-radius: 6px;
    text-align: center;
    background-color: #171A19;
    border: 1px solid #171A19;
    min-width: initial;
    height: auto;
}
div.btn_wrap button.sbtn > span,
div.btn_wrap a.sbtn > span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.3;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn.border,
div.btn_wrap a.sbtn.border {
    background-color: #FFFFFF;
}
div.btn_wrap button.sbtn.border > span,
div.btn_wrap a.sbtn.border > span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn.gray_border,
div.btn_wrap a.sbtn.gray_border {
    border: 1px solid #EAEBEA;
    background-color: transparent;
}
div.btn_wrap button.sbtn.gray_border > span,
div.btn_wrap a.sbtn.gray_border > span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn.em,
div.btn_wrap a.sbtn.em {
    border: 1px solid #00905C;
    background-color: #00905C;
}
div.btn_wrap button.sbtn.em > span,
div.btn_wrap a.sbtn.em > span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.3;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn.em_border,
div.btn_wrap a.sbtn.em_border {
    border: 1px solid #00905C;
    background-color: #FFFFFF;
}
div.btn_wrap button.sbtn.em_border > span,
div.btn_wrap a.sbtn.em_border > span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #00905C;
    line-height: 1.3;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.sbtn.round,
div.btn_wrap a.sbtn.round {
    border-radius: 36px;
}
div.btn_wrap button.dis,
div.btn_wrap a.dis {
    pointer-events: none;
    border: 1px solid #D4D6D4 !important;
    background-color: #D4D6D4 !important;
}
div.btn_wrap button.dis > span,
div.btn_wrap a.dis > span {
    color: #FFFFFF !important;
}
div.btn_wrap button.dis_border,
div.btn_wrap a.dis_border {
    pointer-events: none;
    border: 1px solid #D4D6D4 !important;
    background-color: #FFFFFF !important;
}
div.btn_wrap button.dis_border > span,
div.btn_wrap a.dis_border > span {
    color: #D4D6D4 !important;
}
div.btn_wrap button.btn.dis_border > span,
div.btn_wrap a.btn.dis_border > span {
    color: #D4D6D4 !important;
}
div.btn_wrap button.txt_arrow,
div.btn_wrap a.txt_arrow {
    display: inline-block;
    position: relative;
    width: auto;
    padding-right: 16px;
}
div.btn_wrap button.txt_arrow:after,
div.btn_wrap a.txt_arrow:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow span,
div.btn_wrap a.txt_arrow span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.txt_arrow.gray:after,
div.btn_wrap a.txt_arrow.gray:after {
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.gray span,
div.btn_wrap a.txt_arrow.gray span {
    color: #727573;
}
div.btn_wrap button.txt_arrow.em:after,
div.btn_wrap a.txt_arrow.em:after {
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.em span,
div.btn_wrap a.txt_arrow.em span {
    color: #00905C;
}
div.btn_wrap button.txt_arrow.disable,
div.btn_wrap a.txt_arrow.disable {
    pointer-events: none;
}
div.btn_wrap button.txt_arrow.disable:after,
div.btn_wrap a.txt_arrow.disable:after {
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.disable span,
div.btn_wrap a.txt_arrow.disable span {
    color: #D4D6D4;
}
div.btn_wrap button.txt_arrow.white:after,
div.btn_wrap a.txt_arrow.white:after {
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.white span,
div.btn_wrap a.txt_arrow.white span {
    color: #FFFFFF;
}
div.btn_wrap button.txt_arrow.box,
div.btn_wrap a.txt_arrow.box {
    padding: 12px 36px 14px 20px;
    background-color: #171A19;
}
div.btn_wrap button.txt_arrow.box:after,
div.btn_wrap a.txt_arrow.box:after {
    top: 14px;
    right: 20px;
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_16_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.box span,
div.btn_wrap a.txt_arrow.box span {
    color: #FFFFFF;
}
div.btn_wrap button.txt_arrow.large,
div.btn_wrap a.txt_arrow.large {
    padding-right: 24px;
}
div.btn_wrap button.txt_arrow.large:after,
div.btn_wrap a.txt_arrow.large:after {
    top: 1px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_arrow_right_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap button.txt_arrow.large span,
div.btn_wrap a.txt_arrow.large span {
    font-size: 0.937rem;
}
div.btn_wrap button.txt_line,
div.btn_wrap a.txt_line {
    display: inline-block;
    position: relative;
    width: auto;
}
div.btn_wrap button.txt_line span,
div.btn_wrap a.txt_line span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    text-decoration: underline;
}
div.btn_wrap button.txt_line.gray span,
div.btn_wrap a.txt_line.gray span {
    color: #727573;
}
div.btn_wrap button.txt_line.white span,
div.btn_wrap a.txt_line.white span {
    color: #FFFFFF;
}
div.btn_wrap button.txt_line.em span,
div.btn_wrap a.txt_line.em span {
    color: #00905C;
}
div.btn_wrap button.txt_line.disable,
div.btn_wrap a.txt_line.disable {
    pointer-events: none;
}
div.btn_wrap button.txt_line.disable span,
div.btn_wrap a.txt_line.disable span {
    color: #D4D6D4;
}
div.btn_wrap button.txt_line.box,
div.btn_wrap a.txt_line.box {
    padding: 12px 20px 14px;
    background-color: #171A19;
}
div.btn_wrap button.txt_line.box span,
div.btn_wrap a.txt_line.box span {
    color: #FFFFFF;
}
div.btn_wrap button.input_btn,
div.btn_wrap a.input_btn {
    display: inline-block;
    width: auto;
    min-width: 104px;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    text-align: center;
    background-color: #00905c;
    border: 1px solid #00905c;
}
div.btn_wrap button.input_btn > span,
div.btn_wrap a.input_btn > span {
    white-space: nowrap;
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.btn_wrap button.input_btn.em,
div.btn_wrap a.input_btn.em {
    border-color: #00905c;
    background-color: #00905c;
}
div.btn_wrap button.input_btn.em > span,
div.btn_wrap a.input_btn.em > span {
    color: #FFFFFF;
}
div.btn_wrap button.input_btn.em.dis,
div.btn_wrap a.input_btn.em.dis {
    pointer-events: none;
    border: 1px solid #D4D6D4 !important;
    background-color: #D4D6D4 !important;
}
div.btn_wrap button.input_btn.em.dis > span,
div.btn_wrap a.input_btn.em.dis > span {
    color: #FFFFFF !important;
}
div.btn_wrap button.input_btn.dis,
div.btn_wrap a.input_btn.dis {
    pointer-events: none;
    border: 1px solid #D4D6D4 !important;
    background-color: #D4D6D4 !important;
}
div.btn_wrap button.input_btn.dis > span,
div.btn_wrap a.input_btn.dis > span {
    color: #FFFFFF !important;
}
div.btn_wrap.ico button, div.btn_wrap.ico a {
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
}
div.btn_wrap.ico button::before, div.btn_wrap.ico a::before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
}
div.btn_wrap.ico button.alam::before, div.btn_wrap.ico a.alam::before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_alam_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap.ico button.add::before, div.btn_wrap.ico a.add::before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_add_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap.ico .share_btn {
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
}
div.btn_wrap.ico .share_btn > i.ico {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_share_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap.ico .share_btn > i.ico + span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.btn_wrap.ico .heart_btn {
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
}
div.btn_wrap.ico .heart_btn .ico {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_heart_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap.ico .heart_btn .ico + span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.btn_wrap.ico .heart_btn.off .ico, div.btn_wrap.ico .heart_btn.on .ico {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_heart_20_hdgreen.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.btn_wrap.ico .heart_btn + .share_btn {
    margin-left: 12px;
}
div.btn_wrap._inline {
    display: inline-block;
}
div.btn_wrap.list_more {
    width: 100px;
    margin: 40px auto 0 !important;
}
div.btn_wrap.btn_wrap_sm_width, div.btn_wrap.btn_wrap_sm_width.mt40 {
    width: auto;
    margin: 40px auto 0 !important;
}

div.more_list {
    display: block;
    margin: 24px auto 0;
    padding: 7px 20px 6px;
    border: 1px solid #171A19;
}
div.more_list > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.more_list > span > strong {
    margin-left: 4px;
    color: #00905C;
}
div.more_list.sm {
    padding: 0;
    border: none;
}
div.more_list.sm:after {
    content: "";
    display: inline-block;
    margin-top: 1px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 16px;
    height: 16px;
    background-image: url("/images/mobilehome2/new/ico_arrow_down_black.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.icon_btn_wrap > .icon_btn {
    display: flex;
    padding: 16px;
    column-gap: 12px;
    border-radius: 18px;
    background-color: #EBF2EF;
    align-items: center;
    justify-content: flex-start;
}
.icon_btn_wrap > .icon_btn span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.icon_btn_wrap > .icon_btn::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_gift_24_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.icon_btn_wrap > .icon_btn._event::before {
    background-image: url("/images/mobilehome2/new/ico_gift_24_green.png");
}

a.btn_sel_type,
button.btn_sel_type,
div.btn_sel_type {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 13px 20px;
    border: 1px solid #EAEBEA;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}
a.btn_sel_type:after,
button.btn_sel_type:after,
div.btn_sel_type:after {
    content: "";
    position: absolute;
    top: 50%;
    left: initial;
    bottom: 0;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
a.btn_sel_type span,
button.btn_sel_type span,
div.btn_sel_type span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
a.btn_sel_type span.ui_placeholder,
button.btn_sel_type span.ui_placeholder,
div.btn_sel_type span.ui_placeholder {
    color: #9B9E9C !important;
}
a.btn_sel_type.dis,
button.btn_sel_type.dis,
div.btn_sel_type.dis {
    background-color: #F7F7F7;
    pointer-events: none;
}
a.btn_sel_type.dis > span,
button.btn_sel_type.dis > span,
div.btn_sel_type.dis > span {
    color: #D4D6D4;
}
a.btn_sel_type.dis:after,
button.btn_sel_type.dis:after,
div.btn_sel_type.dis:after {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
a.btn_sel_type + .input_wrap,
button.btn_sel_type + .input_wrap,
div.btn_sel_type + .input_wrap {
    margin-top: 8px;
}
a.btn_sel_type + .btn_wrap,
button.btn_sel_type + .btn_wrap,
div.btn_sel_type + .btn_wrap {
    margin-top: 20px;
}
a.btn_sel_type.wbox,
button.btn_sel_type.wbox,
div.btn_sel_type.wbox {
    padding: 20px;
    border-radius: 18px;
}
a.btn_sel_type.wbox span,
button.btn_sel_type.wbox span,
div.btn_sel_type.wbox span {
    font-size: 1.125rem;
    font-weight: 700;
    color: #9B9E9C;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
a.btn_sel_type.wbox span > em,
button.btn_sel_type.wbox span > em,
div.btn_sel_type.wbox span > em {
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

.btn_agreement_top {
    opacity: 0;
    text-align: right;
    position: fixed;
    right: 24px;
    bottom: 24px;
}
.btn_agreement_top img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}

/* //버튼 정의 */
/* 폼 */
div.input_wrap {
    position: relative;
}
div.input_wrap input[type=text],
div.input_wrap input[type=password],
div.input_wrap input[type=number],
div.input_wrap input[type=button],
div.input_wrap input[type=email],
div.input_wrap input[type=date],
div.input_wrap input[type=tel] {
    width: 100%;
    height: 48px;
    padding: 0 42px 0 16px !important;
    border-radius: 10px;
    border: 1px solid #EAEBEA;
    box-sizing: border-box;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap input[type=text]:active,
div.input_wrap input[type=password]:active,
div.input_wrap input[type=number]:active,
div.input_wrap input[type=button]:active,
div.input_wrap input[type=email]:active,
div.input_wrap input[type=date]:active,
div.input_wrap input[type=tel]:active {
    border-color: #EAEBEA;
}
div.input_wrap input[type=text]:read-only,
div.input_wrap input[type=password]:read-only,
div.input_wrap input[type=number]:read-only,
div.input_wrap input[type=button]:read-only,
div.input_wrap input[type=email]:read-only,
div.input_wrap input[type=date]:read-only,
div.input_wrap input[type=tel]:read-only {
    padding-right: 0;
    background-color: #f7f7f7;
    border-color: #eaebea;
    outline: none;
    opacity: 1;
}
div.input_wrap input[type=text]:read-only::placeholder,
div.input_wrap input[type=password]:read-only::placeholder,
div.input_wrap input[type=number]:read-only::placeholder,
div.input_wrap input[type=button]:read-only::placeholder,
div.input_wrap input[type=email]:read-only::placeholder,
div.input_wrap input[type=date]:read-only::placeholder,
div.input_wrap input[type=tel]:read-only::placeholder {
    font-size: 0.937rem;
    font-weight: 400;
    color: #9B9E9C !important;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap input[type=text]:read-only:focus, div.input_wrap input[type=text]:read-only:active,
div.input_wrap input[type=password]:read-only:focus,
div.input_wrap input[type=password]:read-only:active,
div.input_wrap input[type=number]:read-only:focus,
div.input_wrap input[type=number]:read-only:active,
div.input_wrap input[type=button]:read-only:focus,
div.input_wrap input[type=button]:read-only:active,
div.input_wrap input[type=email]:read-only:focus,
div.input_wrap input[type=email]:read-only:active,
div.input_wrap input[type=date]:read-only:focus,
div.input_wrap input[type=date]:read-only:active,
div.input_wrap input[type=tel]:read-only:focus,
div.input_wrap input[type=tel]:read-only:active {
    border: 1px solid #F7FAF9;
    outline: transparent;
}
div.input_wrap input[type=text]:disabled,
div.input_wrap input[type=password]:disabled,
div.input_wrap input[type=number]:disabled,
div.input_wrap input[type=button]:disabled,
div.input_wrap input[type=email]:disabled,
div.input_wrap input[type=date]:disabled,
div.input_wrap input[type=tel]:disabled {
    background-color: #F7F7F7;
    border-color: #EAEBEA;
    color: #D4D6D4;
    pointer-events: none;
    -webkit-text-fill-color: #D4D6D4;
    opacity: 1;
}
div.input_wrap input[type=text]:disabled::placeholder,
div.input_wrap input[type=password]:disabled::placeholder,
div.input_wrap input[type=number]:disabled::placeholder,
div.input_wrap input[type=button]:disabled::placeholder,
div.input_wrap input[type=email]:disabled::placeholder,
div.input_wrap input[type=date]:disabled::placeholder,
div.input_wrap input[type=tel]:disabled::placeholder {
    font-size: 0.937rem;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap input[type=text]::placeholder,
div.input_wrap input[type=password]::placeholder,
div.input_wrap input[type=number]::placeholder,
div.input_wrap input[type=button]::placeholder,
div.input_wrap input[type=email]::placeholder,
div.input_wrap input[type=date]::placeholder,
div.input_wrap input[type=tel]::placeholder {
    font-size: 0.937rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap input[type=text]:focus, div.input_wrap input[type=text]:active,
div.input_wrap input[type=password]:focus,
div.input_wrap input[type=password]:active,
div.input_wrap input[type=number]:focus,
div.input_wrap input[type=number]:active,
div.input_wrap input[type=button]:focus,
div.input_wrap input[type=button]:active,
div.input_wrap input[type=email]:focus,
div.input_wrap input[type=email]:active,
div.input_wrap input[type=date]:focus,
div.input_wrap input[type=date]:active,
div.input_wrap input[type=tel]:focus,
div.input_wrap input[type=tel]:active {
    border: 1px solid #00905C;
    outline: transparent;
}
div.input_wrap input[type=text].incorrect_type,
div.input_wrap input[type=password].incorrect_type,
div.input_wrap input[type=number].incorrect_type,
div.input_wrap input[type=button].incorrect_type,
div.input_wrap input[type=email].incorrect_type,
div.input_wrap input[type=date].incorrect_type,
div.input_wrap input[type=tel].incorrect_type {
    border: 1px solid #EB3912;
}
div.input_wrap.no_bg input {
    border: none !important;
    background: none !important;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 8px !important;
}
div.input_wrap .del_input {
    position: absolute;
    top: 14px;
    right: 16px;
    width: 20px;
    height: 20px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_input_del_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.input_wrap.no_del input[type=text],
div.input_wrap.no_del input[type=password],
div.input_wrap.no_del input[type=number],
div.input_wrap.no_del input[type=button],
div.input_wrap.no_del input[type=email],
div.input_wrap.no_del input[type=date],
div.input_wrap.no_del input[type=tel] {
    padding: 0 16px !important;
}
div.input_wrap.no_del .del_input {
    display: none;
}
div.input_wrap .btn_wrap {
    margin-left: 8px !important;
    margin-right: 0 !important;
}
div.input_wrap.search_type input[type=text],
div.input_wrap.search_type input[type=password],
div.input_wrap.search_type input[type=number],
div.input_wrap.search_type input[type=button] {
    padding-right: 68px !important;
}
div.input_wrap.search_type .input.on input[type=text],
div.input_wrap.search_type .input.on input[type=password],
div.input_wrap.search_type .input.on input[type=number],
div.input_wrap.search_type .input.on input[type=button] {
    padding-right: 56px;
}
div.input_wrap.search_type .search {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_search_24_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.input_wrap.search_type .search > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.input_wrap.search_type .del {
    position: absolute;
    top: 16px;
    right: 76px;
    background-color: transparent;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_input_del.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.input_wrap.search_type .del > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.input_wrap.search_type.search_open_down {
    overflow: hidden;
    border-radius: 10px;
}
div.input_wrap.search_type.search_open_down.on {
    border: 1px solid #00905C;
}
div.input_wrap.search_type.search_open_down.on input {
    border: none !important;
    background-color: #FFFFFF;
}
div.input_wrap.search_type.search_open_down .del_input {
    right: 52px;
}
div.input_wrap.search_type.search_open_down .search {
    width: 24px;
    height: 24px;
    top: 13px;
}
div.input_wrap.search_type.search_open_down input {
    border: 1px solid #EAEBEA;
    border-radius: 10px;
    background-color: #FFFFFF;
}
div.input_wrap.search_type.search_open_down input:active, div.input_wrap.search_type.search_open_down input:focus {
    border: 1px solid #00905C;
    background-color: #FFFFFF;
}
div.input_wrap.search_type.search_open_down .list_open_down {
    overflow-y: auto;
    max-height: 200px;
    background: #F7FAF9;
}
div.input_wrap.search_type.search_open_down .list_open_down li {
    height: 40px;
}
div.input_wrap.search_type.search_open_down .list_open_down li button {
    display: block;
    width: 100%;
    padding: 9px 0;
    text-indent: 16px;
    text-align: left;
    font-size: 0.937rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap.search_type.search_open_down .list_open_down li button span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap.search_type .del_input {
    right: 46px;
}
div.input_wrap.search_type.auto + .auto_transform {
    padding: 8px 12px;
    background-color: #FFFFFF;
}
div.input_wrap.search_type.auto + .auto_transform > p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap.search_type.auto + .auto_transform > p em {
    font-size: 0.75rem;
    font-weight: 400;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.input_wrap.search_type.auto + .auto_transform > a {
    display: block;
    margin-top: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    text-decoration: underline;
}
div.input_wrap.search_type.auto + .auto_transform:after {
    content: "";
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    height: 58px;
    background-color: #FFFFFF;
    z-index: -1;
}

div.input_wrap > div {
    position: relative !important;
    top: initial !important;
    right: initial !important;
    transform: none !important;
}
div.input_wrap > div:first-child {
    margin-left: 0;
}
div.input_wrap.type01 {
    display: flex;
    justify-content: space-between;
}
div.input_wrap.type01 .del_input {
    right: 129px;
}
div.input_wrap + .input_wrap, div.input_wrap + .sel_wrap {
    margin-top: 8px;
}
div.input_wrap.error input {
    border-color: #EB3912;
}
div.input_wrap.error + .caution_wrap {
    display: block;
}

.amount_wrap {
    margin-top: 16px;
    padding: 13px 16px;
    border-radius: 8px;
    border: solid 1px #EAEBEA;
    background-color: #FFFFFF;
}
.amount_wrap sup {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    color: #00905C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.amount_wrap input {
    width: calc(100% - 42px);
    border: none;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    outline: none;
}
.amount_wrap .txt {
    margin-left: 20px;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.amount_wrap.error {
    border-color: #EB3912;
}
.amount_wrap.error + .caution_wrap {
    display: block;
}

.caution_wrap {
    display: none;
    margin-top: 8px;
    margin-left: 12px;
}
.caution_wrap p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #EB3912;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.caution_wrap.positive p {
    color: #01A76B;
}

div.rdo_wrap {
    display: inline-block;
    position: relative;
}
div.rdo_wrap + .rdo_wrap {
    margin-left: 0;
}
div.rdo_wrap input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.rdo_wrap input:focus + label {
    outline: 0;
}
div.rdo_wrap label {
    display: inline-block;
    padding-left: 28px;
}
div.rdo_wrap label > span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap input + label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_radio_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.rdo_wrap input:checked + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_radio_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.rdo_wrap input:disabled + label span {
    color: #D4D6D4;
}
div.rdo_wrap input:disabled + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_radio_20_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.rdo_wrap.round + .rdo_wrap {
    margin-top: 8px;
}
div.rdo_wrap.round label {
    width: 100%;
    height: 65px;
    padding-left: 0;
    text-align: center;
    border: 1px solid #EAEBEA;
    border-radius: 80px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
div.rdo_wrap.round label::before {
    content: none;
}
div.rdo_wrap.round label span {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.round label span.desc {
    margin-top: 2px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.round input:checked + label {
    border: 1.4px solid #171A19;
}
div.rdo_wrap.round input:checked + label span {
    font-weight: 700;
}
div.rdo_wrap.round input:checked + label span.desc {
    font-weight: 400;
}
div.rdo_wrap.round input:disabled + label {
    pointer-events: none;
    background-color: #F7F7F7;
}
div.rdo_wrap.round input:disabled + label span {
    color: #D4D6D4;
}
div.rdo_wrap.s_round + .rdo_wrap {
    margin-top: 8px;
    margin-left: 0;
}
div.rdo_wrap.s_round label {
    width: 100%;
    height: 48px;
    padding-left: 0;
    text-align: center;
    border: 1px solid #EAEBEA;
    border-radius: 28px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
div.rdo_wrap.s_round label::before {
    content: none;
}
div.rdo_wrap.s_round label span {
    display: block;
    width: 100%;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_round label span.desc {
    margin-top: 2px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_round input:checked + label {
    background-color: #01A76B;
    border: 1px solid #01A76B;
}
div.rdo_wrap.s_round input:checked + label span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_round input:checked + label span.desc {
    font-weight: 400;
}
div.rdo_wrap.s_round input:disabled + label {
    pointer-events: none;
    border: 1px solid #F7F7F7;
    background-color: #F7F7F7;
}
div.rdo_wrap.s_round input:disabled + label span {
    color: #D4D6D4;
}
div.rdo_wrap.s_round.border input:checked + label {
    padding: 7px 10px 6px;
    background-color: #FFFFFF;
    border: 2px solid #171A19;
}
div.rdo_wrap.s_round.border input:checked + label span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.card_round + .rdo_wrap {
    margin-top: 16px;
}
div.rdo_wrap.card_round > label {
    width: 100%;
    padding-left: 0;
    text-align: left;
    box-sizing: border-box;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
div.rdo_wrap.card_round > label::before {
    content: none;
}
div.rdo_wrap.card_round > input:checked + label {
    border: 1.4px solid #171A19;
}
div.rdo_wrap.card_round > input:checked + label span {
    font-weight: 700;
}
div.rdo_wrap.card_round > input:checked + label span.desc {
    font-weight: 400;
}
div.rdo_wrap.card_round > input:disabled + label {
    pointer-events: none;
    background-color: #F7F7F7;
}
div.rdo_wrap.card_round > input:disabled + label span {
    color: #D4D6D4;
}
div.rdo_wrap.sel_btn + .rdo_wrap {
    margin-top: 8px;
}
div.rdo_wrap.sel_btn label {
    min-width: 96px;
    height: 48px;
    padding: 13px 20px;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #EAEBEA;
    border-radius: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
div.rdo_wrap.sel_btn label::before {
    content: none;
}
div.rdo_wrap.sel_btn label span {
    display: block;
    width: 100%;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.sel_btn input:checked + label {
    padding: 10px 19px;
    background-color: #01A76B;
    border: 1px solid #01A76B;
}
div.rdo_wrap.sel_btn input:checked + label span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.sel_btn input:disabled + label {
    pointer-events: none;
    background-color: #F7F7F7;
    border: 1px solid #EAEBEA;
}
div.rdo_wrap.sel_btn input:disabled + label span {
    color: #D4D6D4;
}
div.rdo_wrap.sel_btn.border input:checked + label {
    padding: 10px 18px;
    background-color: #FFFFFF;
    border: 2px solid #171A19;
}
div.rdo_wrap.sel_btn.border input:checked + label span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.sel_btn.border_green input:checked + label {
    padding: 10px 18px;
    background-color: transparent;
    border: 2px solid #01A76B;
}
div.rdo_wrap.sel_btn.border_green input:checked + label span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_sel_btn + .rdo_wrap {
    margin-top: 8px;
}
div.rdo_wrap.s_sel_btn label {
    min-width: auto;
    height: 36px;
    padding: 6px 11px 7px;
    background-color: #FFFFFF;
    border: 1.4px solid #EAEBEA;
    border-radius: 24px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
div.rdo_wrap.s_sel_btn label::before {
    content: none;
}
div.rdo_wrap.s_sel_btn label span {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_sel_btn input:checked + label {
    background-color: #01A76B;
    border: 1.4px solid #01A76B;
}
div.rdo_wrap.s_sel_btn input:checked + label span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_sel_btn input:disabled + label {
    pointer-events: none !important;
    background-color: #F7F7F7 !important;
    border: 1px solid #EAEBEA !important;
}
div.rdo_wrap.s_sel_btn input:disabled + label span {
    color: #D4D6D4 !important;
}
div.rdo_wrap.s_sel_btn.border input:checked + label {
    padding: 7px 10px 6px;
    background-color: #FFFFFF;
    border: 2px solid #171A19;
}
div.rdo_wrap.s_sel_btn.border input:checked + label span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.s_sel_btn.black label {
    text-align: center;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #171A19;
}
div.rdo_wrap.s_sel_btn.black label span {
    color: #171A19;
}
div.rdo_wrap.s_sel_btn.black input:checked + label {
    background-color: #171A19 !important;
}
div.rdo_wrap.s_sel_btn.black input:checked + label span {
    color: #FFFFFF;
}
div.rdo_wrap.btn_type {
    width: 100%;
}
div.rdo_wrap.btn_type input + label {
    border: solid 1px rgba(155, 158, 156, 0.1);
    background-color: rgba(155, 158, 156, 0.1);
    border-radius: 24px;
}
div.rdo_wrap.btn_type input + label .sel_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102px;
    height: 50px;
    margin-left: -2px;
    margin-top: -2px;
    margin-bottom: -2px;
    border-radius: 24px;
    border: solid 1px #EAEBEA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type input + label .desc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type input:checked + label {
    border: solid 1px #EBF2EF;
    background-color: #EBF2EF;
}
div.rdo_wrap.btn_type input:checked + label .sel_btn {
    border: solid 1px #01A76B;
    background-color: #01A76B;
    font-size: 0.937rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type + .btn_type {
    margin-top: 20px;
}
div.rdo_wrap.btn_type.row input + label {
    display: block;
    height: auto;
    border: solid 1px #F7F7F7;
    background-color: #F7F7F7;
    border-radius: 28px;
}
div.rdo_wrap.btn_type.row input + label .sel_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-left: 0;
    border-radius: 28px;
    border: 1px solid #EAEBEA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type.row input + label .desc {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
}
div.rdo_wrap.btn_type.row input + label .desc > span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type.row input + label .desc .btn_wrap .sbtn {
    min-width: initial;
    height: auto;
    padding: 3px 12px 4px;
}
div.rdo_wrap.btn_type.row input:checked + label {
    border: solid 1px #F7F7F7;
    background-color: #F7F7F7;
}
div.rdo_wrap.btn_type.row input:checked + label .sel_btn {
    border: solid 1px #01A76B;
    background-color: #01A76B;
    font-size: 0.937rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type.row input:checked + label .sel_btn.em {
    border: solid 2px #00905C;
    background-color: #00905C;
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.rdo_wrap.btn_type.row input:checked + label .desc {
    display: flex;
}
div.rdo_wrap.btn_type.row input:checked + label .desc.line {
    display: block;
}
div.rdo_wrap.btn_type.row + .rdo_wrap {
    margin-top: 12px;
}

div.chk_wrap {
    display: inline-block;
    position: relative;
}
div.chk_wrap input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.chk_wrap input:focus + label {
    outline: 0;
}
div.chk_wrap label {
    display: inline-block;
    padding-left: 28px;
}
div.chk_wrap label > span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.chk_wrap input + label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_check_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap input:checked + label span {
    color: #171A19;
}
div.chk_wrap input:checked + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_check_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap input:disabled + label span {
    color: #D4D6D4;
}
div.chk_wrap input:disabled + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_check_20_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap input:disabled + label.empty:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_check_20_empty.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap.sm label {
    display: inline-block;
    padding-left: 28px;
}
div.chk_wrap.sm label > span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.chk_wrap.sm input + label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_s_check_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap.sm input:checked + label span {
    color: #171A19;
}
div.chk_wrap.sm input:checked + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_s_check_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap.sm input:disabled + label span {
    color: #D4D6D4;
}
div.chk_wrap.sm input:disabled + label:before {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_s_check_20_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
div.chk_wrap.toggle {
    height: 24px;
}
div.chk_wrap.toggle input + label {
    position: relative;
    width: 48px;
    height: 24px;
    padding: 0;
    border-radius: 50px;
    background-color: #D4D6D4;
}
div.chk_wrap.toggle input + label:before {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #FFFFFF;
    background-image: none;
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 0;
    right: initial;
}
div.chk_wrap.toggle input + label > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.chk_wrap.toggle input:checked + label {
    background-color: #00905C;
}
div.chk_wrap.toggle input:checked + label:before {
    content: "";
    position: absolute;
    top: 2px;
    left: initial;
    bottom: 0;
    right: 2px;
}
div.chk_wrap.s_sel_btn + .chk_wrap {
    margin-top: 8px;
}
div.chk_wrap.s_sel_btn label {
    min-width: auto;
    height: 36px;
    padding: 8px 12px 7px;
    background-color: #FFFFFF;
    border: 1px solid #EAEBEA;
    border-radius: 24px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
div.chk_wrap.s_sel_btn label::before {
    content: none;
}
div.chk_wrap.s_sel_btn label span {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.chk_wrap.s_sel_btn input:checked + label {
    padding: 8px 11px 7px;
    background-color: #01A76B;
    border: 1.4px solid #01A76B;
}
div.chk_wrap.s_sel_btn input:checked + label span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.chk_wrap.s_sel_btn input:disabled + label {
    pointer-events: none;
    background-color: #F7F7F7;
    border: 1px solid #EAEBEA;
}
div.chk_wrap.s_sel_btn input:disabled + label span {
    color: #D4D6D4;
}
div.chk_wrap.s_sel_btn.border input:checked + label {
    padding: 7px 10px 6px;
    background-color: #FFFFFF;
    border: 2px solid #171A19;
}
div.chk_wrap.s_sel_btn.border input:checked + label span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.chk_wrap.s_sel_btn.black label {
    text-align: center;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #171A19;
}
div.chk_wrap.s_sel_btn.black label span {
    color: #171A19;
}
div.chk_wrap.s_sel_btn.black input:checked + label {
    background-color: #171A19 !important;
}
div.chk_wrap.s_sel_btn.black input:checked + label span {
    color: #FFFFFF;
}

div.sel_wrap .ui-selectbox-wrap {
    position: relative;
    width: 100% !important;
}
div.sel_wrap .ui-selectbox-wrap .ui-select-button {
    display: block;
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid #eaebea;
    background-color: #FFFFFF;
    box-sizing: border-box;
}
div.sel_wrap .ui-selectbox-wrap .ui-select-button.placeholder .ui-select-text {
    color: #9B9E9C;
}
div.sel_wrap .ui-selectbox-wrap .ui-select-button .ui-select-text {
    margin-right: -1px;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    vertical-align: middle;
    white-space: nowrap;
}
div.sel_wrap .ui-selectbox-wrap .ui-select-button .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.sel_wrap .ui-selectbox-wrap .ui-select-button .ico {
    position: absolute;
    top: 16px;
    right: 20px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_arrow_up_20_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
}
div.sel_wrap .ui-selectbox-wrap.on .ui-select-button {
    box-sizing: border-box;
    padding: 12px 20px 13px 20px;
    border-left: 1px solid #00905C;
    border-right: 1px solid #00905C;
    border-top: 1px solid #00905C;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
div.sel_wrap .ui-selectbox-wrap.on .ui-select-button::after {
    content: "";
    position: absolute;
    top: initial;
    left: 2px;
    bottom: 0;
    right: 2px;
    z-index: 1;
    border-bottom: 1px solid #eaebea;
    height: 1px;
}
div.sel_wrap .ui-selectbox-wrap.on .ui-select-button .ico {
    transform: rotate(0deg);
}
div.sel_wrap .ui-selectbox-wrap .ui-dropdown,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list {
    position: absolute;
    z-index: 2;
    max-height: 114px;
    overflow: auto;
    left: 0;
    right: 0;
    padding: 0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-left: 1px solid #00905C;
    border-right: 1px solid #00905C;
    border-bottom: 1px solid #00905C;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .ui-dropdown-title,
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .btn_wrap,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-dropdown-title,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .btn_wrap {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .ui-dropdown-list > li,
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .ui-select-scrollarea-ul > li,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-dropdown-list > li,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li {
    padding: 12px 20px;
}
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .ui-dropdown-list > li > a,
div.sel_wrap .ui-selectbox-wrap .ui-dropdown .ui-select-scrollarea-ul > li > a,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-dropdown-list > li > a,
div.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a {
    display: block;
    outline: none;
    font-size: 0.937rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    text-decoration: none;
}
div.sel_wrap .ui-selectbox-wrap.disabled .ui-select-button {
    background-color: #F7F7F7;
}
div.sel_wrap.dis .ui-selectbox-wrap .ui-select-button {
    pointer-events: none;
    background-color: #F7F7F7;
}
div.sel_wrap.dis .ui-selectbox-wrap .ui-select-button .ui-select-text {
    color: #D4D6D4;
}
div.sel_wrap.dis .ui-selectbox-wrap .ui-select-button .ico {
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_dis.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(0deg);
}
div.sel_wrap + .input_wrap, div.sel_wrap + .sel_wrap {
    margin-top: 8px;
}
div.sel_wrap.inline_type > .ui-selectbox-wrap > .ui-selectbox-view > .ui-select-button {
    display: flex;
    padding: 0px;
    border: 0px;
    border-radius: 0;
    align-items: center;
    justify-content: space-between;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
div.sel_wrap.inline_type > .ui-selectbox-wrap > .ui-selectbox-view > .ui-select-button span.ui-select-text {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    width: auto;
    margin-right: 4px;
    font-size: 14px;
}
div.sel_wrap.inline_type > .ui-selectbox-wrap > .ui-selectbox-view > .ui-select-button span.ico {
    display: inline-block;
    position: initial;
}
div.sel_wrap.inline_type > .ui-selectbox-wrap.on > .ui-selectbox-view .ui-select-button {
    border: 0xp;
}
div.sel_wrap.inline_type > .ui-selectbox-wrap.on > .ui-selectbox-view .ui-select-button::after {
    display: none;
}
div.sel_wrap.inline_type .ui-dropdown {
    max-height: 208px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    min-width: 160px;
    width: auto;
    padding: 0;
    margin-top: 8px;
    border-radius: 8px;
    border: none;
    left: auto;
    right: 0;
    z-index: 10;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
div.sel_wrap.inline_type .ui-dropdown > .ui-dropdown-box > .ui-dropdown-title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
div.sel_wrap.inline_type .ui-dropdown > .ui-dropdown-box > .ui-dropdown-list > li {
    min-height: 32px;
    padding: 5px 8px 6px 12px;
    box-sizing: border-box;
}
div.sel_wrap.inline_type .ui-dropdown > .ui-dropdown-box > .ui-dropdown-list > li > a {
    padding: 0px;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.sel_wrap.inline_type .ui-dropdown > .ui-dropdown-box > .ui-dropdown-list > li.on {
    background-color: #f7faf9;
}
div.sel_wrap.inline_type .ui-dropdown > .ui-dropdown-box > .ui-dropdown-list > li.on > a {
    padding-right: 28px;
    color: #171a19;
    background-image: url("/images/mobilehome2/new/ico_s_check_h2_20_green.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center right;
}

.multi_form {
    display: flex;
    justify-content: flex-start;
}
.multi_form > div {
    flex: 1;
}
.multi_form > div + div {
    margin-top: 0 !important;
    margin-left: 8px;
}
.multi_form.custom > div {
    flex: 0;
}
.multi_form .dash {
    display: inline-flex;
    align-items: center;
}
.multi_form.card_input .input_wrap {
    flex: 1;
}
.multi_form.card_input .dash {
    flex: 0;
}
.multi_form.card_input > div + div {
    margin-left: 4px;
}
.multi_form.id_card .input_wrap {
    flex: 1;
}
.multi_form.id_card div.one_digit {
    flex: 0;
}
.multi_form.id_card div.one_digit input {
    width: 32px;
    padding: 0 10px !important;
}
.multi_form.id_card .password_wrap {
    display: flex;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 24px;
    align-items: center;
}
.multi_form.id_card .password_wrap .pw_dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #D4D6D4;
    border-radius: 16px;
}
.multi_form.id_card .password_wrap .pw_dot + .pw_dot {
    margin-left: 8px;
}
.multi_form.license_id .input_wrap {
    flex: 1;
}
.multi_form.license_id .input_wrap:nth-child(3), .multi_form.license_id .input_wrap:nth-child(7) {
    flex: 0;
}
.multi_form.license_id .input_wrap input {
    padding: 10px !important;
}

.textarea_wrap {
    position: relative;
    padding: 13px 20px 45px 16px;
    border: 1px solid #EAEBEA;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
}
.textarea_wrap textarea {
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 0.973rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    vertical-align: top;
    text-align: left;
    resize: none;
}
.textarea_wrap textarea::placeholder {
    font-size: 0.973rem;
    font-weight: 400;
    color: #9b9e9c;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.textarea_wrap textarea:focus, .textarea_wrap textarea:active {
    border-color: #00905C;
    outline: transparent;
}
.textarea_wrap .word_num {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.textarea_wrap .word_num .cnt {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.textarea_wrap .word_num em {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #EF5533;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.textarea_wrap.read_only {
    padding: 13px 16px;
    background: #F7F7F7;
}
.textarea_wrap.read_only textarea {
    background: #F7F7F7;
}

/*
	20230525_선택시 굵게 변경되는 스타일 추가 strong 태그로
 */
.btm_select_wrap .ui_option_wrap li {
    position: relative;
}
.btm_select_wrap .ui_option_wrap li .ui_btn_option {
    width: 100%;
    padding: 16px 24px;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.btm_select_wrap .ui_option_wrap li .ui_btn_option > em {
    margin-right: 4px;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.btm_select_wrap .ui_option_wrap li .ui_btn_option > strong {
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.btm_select_wrap .ui_option_wrap li .ui_btn_option:focus {
    background-color: #EBF2EF;
}
.btm_select_wrap .ui_option_wrap li.on:after {
    content: "";
    position: absolute;
    top: 50%;
    left: initial;
    bottom: 0;
    right: 24px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_s_check_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btm_select_wrap .ui_option_wrap li.on .ui_btn_option {
    background-color: #F7FAF9;
    font-weight: 700;
}
.btm_select_wrap .ui_option_wrap li.on .ui_btn_option > strong {
    font-weight: 700;
}

dl.with_text_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-radius: 10px;
    border: solid 1px #EAEBEA;
    background-color: #FFFFFF;
}
dl.with_text_input dt {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
dl.with_text_input dd {
    flex: 1;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
dl.with_text_input dd div.input_wrap {
    display: inline-block;
    width: calc(100% - 18px);
}
dl.with_text_input dd div.input_wrap input[type=text],
dl.with_text_input dd div.input_wrap input[type=password],
dl.with_text_input dd div.input_wrap input[type=number],
dl.with_text_input dd div.input_wrap input[type=button],
dl.with_text_input dd div.input_wrap input[type=email],
dl.with_text_input dd div.input_wrap input[type=date],
dl.with_text_input dd div.input_wrap input[type=tel] {
    padding-right: 0px !important;
    border: none;
    text-align: right;
}
dl.with_text_input dd div.input_wrap input[type=text]:focus, dl.with_text_input dd div.input_wrap input[type=text]:active,
dl.with_text_input dd div.input_wrap input[type=password]:focus,
dl.with_text_input dd div.input_wrap input[type=password]:active,
dl.with_text_input dd div.input_wrap input[type=number]:focus,
dl.with_text_input dd div.input_wrap input[type=number]:active,
dl.with_text_input dd div.input_wrap input[type=button]:focus,
dl.with_text_input dd div.input_wrap input[type=button]:active,
dl.with_text_input dd div.input_wrap input[type=email]:focus,
dl.with_text_input dd div.input_wrap input[type=email]:active,
dl.with_text_input dd div.input_wrap input[type=date]:focus,
dl.with_text_input dd div.input_wrap input[type=date]:active,
dl.with_text_input dd div.input_wrap input[type=tel]:focus,
dl.with_text_input dd div.input_wrap input[type=tel]:active {
    outline: none;
    border: none;
}
dl.with_text_input dd div.input_wrap + .won {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
dl.with_text_input dd div.input_wrap.del_input_static {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}
dl.with_text_input dd div.input_wrap.del_input_static input {
    width: 100%;
}
dl.with_text_input dd div.input_wrap.del_input_static .del_input {
    position: static;
    margin-left: 6px;
    flex-shrink: 0;
}
dl.with_text_input.error {
    border-color: #EB3912;
}
dl.with_text_input.error + .caution_wrap {
    display: block;
}
dl.with_text_input.readonly {
    background-color: #F7F7F7;
}
dl.with_text_input.won_box > dt {
    width: auto;
    flex-shrink: 0;
}
dl.with_text_input.won_box > dd {
    display: flex;
    width: 100%;
    flex: auto;
    align-items: center;
    justify-content: flex-end;
}
dl.with_text_input.won_box > dd > span.won {
    display: none;
    margin-left: 4px;
}
dl.with_text_input.won_box > dd > span.won.on {
    display: inline-block;
}

.input_btn_set {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: flex-start;
}
.input_btn_set > .input_wrap {
    width: 100%;
}
.input_btn_set > .btn_wrap {
    width: auto;
    flex-shrink: 0;
}

/* //폼 */
/* 플래그 정의 */
.flag_wrap {
    display: inline-block;
    padding: 0 6px;
    border-radius: 4px;
    border: 1px solid #171A19;
    background-color: #171A19;
    line-height: 100%;
    /* 강조형(컬러타입) */
    /* 등급 : 말꼬리 이미지 형태 */
    /* 등급 : 기본 이미지 형태 */
}
.flag_wrap span {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    font-style: normal;
}
.flag_wrap.border {
    background-color: #FFFFFF;
}
.flag_wrap.border span {
    color: #171A19;
}
.flag_wrap.opa08 {
    background-color: rgba(23, 26, 25, 0.8);
    border-color: transparent;
}
.flag_wrap.white.border {
    border-color: #FFFFFF;
    background-color: transparent;
}
.flag_wrap.white.border span {
    color: #FFFFFF;
    background-color: transparent;
}
.flag_wrap.c01 {
    color: #FFFFFF;
    border-color: #01A76B;
    background-color: #01A76B;
}
.flag_wrap.c01.border {
    background-color: #FFFFFF;
}
.flag_wrap.c01.border span {
    color: #01A76B;
}
.flag_wrap.c02 {
    color: #FFFFFF;
    border-color: #1A4CFF;
    background-color: #1A4CFF;
}
.flag_wrap.c02.border {
    background-color: #FFFFFF;
}
.flag_wrap.c02.border span {
    color: #1A4CFF;
}
.flag_wrap.c03 {
    color: #FFFFFF;
    border-color: #EB3912;
    background-color: #EB3912;
}
.flag_wrap.c03.border {
    background-color: #FFFFFF;
}
.flag_wrap.c03.border span {
    color: #EB3912;
}
.flag_wrap.c04 {
    color: #FFFFFF;
    border-color: #E5BA00;
    background-color: #E5BA00;
}
.flag_wrap.c04.border {
    background-color: #FFFFFF;
}
.flag_wrap.c04.border span {
    color: #E5BA00;
}
.flag_wrap.c05 {
    color: #FFFFFF;
    border-color: #6889ff;
    background-color: #6889ff;
}
.flag_wrap.c05.border {
    background-color: #FFFFFF;
}
.flag_wrap.c05.border span {
    color: #6889ff;
}
.flag_wrap.c06 {
    color: #FFFFFF;
    border-color: #ff60d2;
    background-color: #ff60d2;
}
.flag_wrap.c06.border {
    background-color: #FFFFFF;
}
.flag_wrap.c06.border span {
    color: #ff60d2;
}
.flag_wrap.c07 {
    color: #FFFFFF;
    border-color: #FF5000;
    background-color: #FF5000;
}
.flag_wrap.c07.border {
    background-color: #FFFFFF;
}
.flag_wrap.c07.border span {
    color: #FF5000;
}
.flag_wrap.c08 {
    color: #FFFFFF;
    border-color: #7f7f7f;
    background-color: #7f7f7f;
}
.flag_wrap.c08.border {
    background-color: #FFFFFF;
}
.flag_wrap.c08.border span {
    color: #7f7f7f;
}
.flag_wrap.c09 {
    color: #FFFFFF;
    border-color: #f07191;
    background-color: #f07191;
}
.flag_wrap.c09.border {
    background-color: #FFFFFF;
}
.flag_wrap.c09.border span {
    color: #f07191;
}
.flag_wrap.c10 {
    color: #FFFFFF;
    border-color: #2ABBB6;
    background-color: #2ABBB6;
}
.flag_wrap.c10.border {
    background-color: #FFFFFF;
}
.flag_wrap.c10.border span {
    color: #2ABBB6;
}
.flag_wrap.c11 {
    color: #FFFFFF;
    border-color: #2e2c8b;
    background-color: #2e2c8b;
}
.flag_wrap.c11.border {
    background-color: #FFFFFF;
}
.flag_wrap.c11.border span {
    color: #2e2c8b;
}
.flag_wrap.c12 {
    color: #FFFFFF;
    border-color: #5B74BD;
    background-color: #5B74BD;
}
.flag_wrap.c12.border {
    background-color: #FFFFFF;
}
.flag_wrap.c12.border span {
    color: #5B74BD;
}
.flag_wrap.c13 {
    color: #FFFFFF;
    border-color: #ffa800;
    background-color: #ffa800;
}
.flag_wrap.c13.border {
    background-color: #FFFFFF;
}
.flag_wrap.c13.border span {
    color: #ffa800;
}
.flag_wrap.c14 {
    color: #FFFFFF;
    border-color: #727573;
    background-color: #727573;
}
.flag_wrap.c14.border {
    background-color: #FFFFFF;
}
.flag_wrap.c14.border span {
    color: #727573;
}
.flag_wrap.c15 {
    border-color: #EAEBEA;
    background-color: #EAEBEA;
}
.flag_wrap.c15 > span {
    color: #171A19;
}
.flag_wrap.c15.border {
    background-color: #FFFFFF;
}
.flag_wrap.c15.border span {
    color: #171A19;
}
.flag_wrap.benefit {
    display: inline-block;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #171A19;
    background-color: #171A19;
    line-height: 100%;
}
.flag_wrap.benefit span {
    font-size: 0.75rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.flag_wrap.bubblee {
    padding: 0px;
    border: 0px;
    box-sizing: border-box;
    background-color: transparent;
}
.flag_wrap.bubblee span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.flag_wrap.bubblee.yp {
    width: 61px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_clubyp.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.jasmin {
    width: 102px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_jasmin.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.sage {
    width: 54px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_sage.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green {
    width: 60px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.bestuser {
    width: 57px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_bestuser.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.season {
    width: 63px;
    height: 26px;
    background-image: url("/images/mobilehome2/new/flag_bubblee_season.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_210 {
    width: 118px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_210.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_220 {
    width: 118px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_220.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_240 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_240.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_250 {
    width: 118px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_250.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_260 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_260.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_270 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_270.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_290 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_290.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_400 {
    width: 118px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_400.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_410 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_410.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_420 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_420.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_430 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_430.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_450 {
    width: 107px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_450.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_460 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_460.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_470 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_470.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_480 {
    width: 97px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_480.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.bubblee.green_490 {
    width: 118px;
    height: 26px;
    background-image: url("/images/mobilehome2/new//flag_bubblee_rank_green_490.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img {
    padding: 0px;
    border: 0px;
    box-sizing: border-box;
    background-color: transparent;
}
.flag_wrap.img span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.flag_wrap.img.yp {
    width: 61px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_clubyp.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.jasmin {
    width: 102px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_jasmin.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.sage {
    width: 54px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_sage.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green {
    width: 60px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.bestuser {
    width: 57px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_bestuser.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.season {
    width: 63px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_season.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_210 {
    width: 118px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_210.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_220 {
    width: 118px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_220.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_240 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_240.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_250 {
    width: 118px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_250.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_260 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_260.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_270 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_270.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_290 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_290.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_400 {
    width: 118px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_400.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_410 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_410.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_420 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_420.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_430 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_430.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_450 {
    width: 107px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_450.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_460 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_460.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_470 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_470.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_480 {
    width: 97px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_480.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.flag_wrap.img.green_490 {
    width: 118px;
    height: 20px;
    background-image: url("/images/mobilehome2/new//flag_rank_green_490.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/* //플래그 정의 */
/* 플래그 정의 - 말꼬리 타입 */
.flag_bubblee_wrap {
    display: inline-block;
    padding-bottom: 7px;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto 8.1px;
    background-image: url("/images/mobilehome2/new/flag_edge.png");
}
.flag_bubblee_wrap .flag_bubblee {
    display: flex;
    height: 20px;
    padding: 0 8px;
    border-radius: 4px 4px 4px 0;
    align-items: center;
    justify-content: center;
    background-color: #171A19;
}
.flag_bubblee_wrap .flag_bubblee span {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    color: white !important;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.flag_bubblee_wrap.c01 {
    background-image: url("/images/mobilehome2/new/flag_edge_c01.png");
}
.flag_bubblee_wrap.c01 .flag_bubblee {
    background-color: #01A76B;
}
.flag_bubblee_wrap.c02 {
    background-image: url("/images/mobilehome2/new/flag_edge_c02.png");
}
.flag_bubblee_wrap.c02 .flag_bubblee {
    background-color: #3A5FFF;
}
.flag_bubblee_wrap.c03 {
    background-image: url("/images/mobilehome2/new/flag_edge_c03.png");
}
.flag_bubblee_wrap.c03 .flag_bubblee {
    background-color: #F07191;
}
.flag_bubblee_wrap.c05 {
    background-image: url("/images/mobilehome2/new/flag_edge_c05.png");
}
.flag_bubblee_wrap.c05 .flag_bubblee {
    background-color: #7F7F7F;
}
.flag_bubblee_wrap.c06 {
    background-image: url("/images/mobilehome2/new/flag_edge_c06.png");
}
.flag_bubblee_wrap.c06 .flag_bubblee {
    background-color: #2ABBB6;
}
.flag_bubblee_wrap.c07 {
    background-image: url("/images/mobilehome2/new/flag_edge_c07.png");
}
.flag_bubblee_wrap.c07 .flag_bubblee {
    background-color: #302E92;
}
.flag_bubblee_wrap.c08 {
    background-image: url("/images/mobilehome2/new/flag_edge_c08.png");
}
.flag_bubblee_wrap.c08 .flag_bubblee {
    background-color: #5B74BD;
}

/* 테이블 상단 */
/* //테이블 상단 */
/* 테이블 */
div.table_wrap {
    border-top: 1px solid #171A19;
    overflow-x: auto;
    /* 공통 */
    /* 가로형 */
    /* 세로형 */
    /* 추가 스타일 */
}
div.table_wrap table {
    width: 100%;
    border-collapse: collapse;
}
div.table_wrap table thead tr th {
    background-color: #EBF2EF !important;
    border-left: 1px solid #FFFFFF;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.table_wrap table thead tr th:first-child {
    border-left: none;
}
div.table_wrap table tbody tr th {
    border-bottom: 1px solid #EAEBEA;
    border-left: 1px solid #EAEBEA;
    background-color: #EBF2EF;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
div.table_wrap table tbody tr th.first {
    border-left: none;
}
div.table_wrap table tbody tr td {
    border-bottom: 1px solid #EAEBEA;
    border-left: 1px solid #EAEBEA;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    word-break: keep-all;
}
div.table_wrap table tbody tr td:first-child {
    border-left: none;
}
div.table_wrap.col tr th {
    padding: 16px 12px;
    background-color: #EBF2EF;
    text-align: center;
}
div.table_wrap.col tr td {
    padding: 20px 12px !important;
    text-align: left;
    background-color: #FFFFFF;
    word-break: keep-all;
}
div.table_wrap.row table tbody tr:first-child th {
    border-top: none;
}
div.table_wrap.row table tbody tr:last-child th {
    border-bottom: 1px solid #EAEBEA;
}
div.table_wrap.row table tbody th {
    padding: 20px 12px;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    text-align: left;
}
div.table_wrap.row table tbody td {
    padding: 20px 12px;
}
div.table_wrap.row .multi_th th[scope=row] {
    padding-top: 21px;
    vertical-align: top;
    border-bottom: 1px solid #FFFFFF;
}
div.table_wrap.row .multi_th th[scope=col] {
    padding: 14px 0 15px;
}
div.table_wrap.row .multi_th td[colspan] {
    border-bottom: 1px solid #FFFFFF;
}
div.table_wrap table tr th.al_left {
    text-align: left;
}
div.table_wrap table tr th.al_right {
    text-align: right;
}
div.table_wrap table tr th.al_center {
    text-align: center;
}
div.table_wrap table tr th.brd_left {
    border-left: 1px solid #FFFFFF;
}
div.table_wrap table tr th.brd_left:first-child {
    border-left: 1px solid #FFFFFF;
}
div.table_wrap table tr th.brd_bottom {
    border-bottom: 1px solid #FFFFFF;
}
div.table_wrap table tr th.brd_bottom:first-child {
    border-bottom: 1px solid #FFFFFF;
}
div.table_wrap table tr th.white_th {
    background: #FFFFFF;
    border-left: 1px solid #EAEBEA;
}
div.table_wrap table tr th.v_align_top {
    vertical-align: top;
}
div.table_wrap table tr td.al_left {
    text-align: left;
}
div.table_wrap table tr td.al_right {
    text-align: right;
}
div.table_wrap table tr td.al_center {
    text-align: center;
}
div.table_wrap table tr td.brd_left {
    border-left: 1px solid #EAEBEA;
}
div.table_wrap table tr td.brd_left:first-child {
    border-left: 1px solid #EAEBEA;
}
div.table_wrap table tr td.white_th {
    background: #FFFFFF;
    border-left: 1px solid #EAEBEA;
}
div.table_wrap table tr td.v_align_top {
    vertical-align: top;
}

/* //테이블(공통) */
/* 테이블과 하단 컨텐츠간 간격 */
.table_wrap + .btn_wrap {
    margin-top: 32px;
}

/* 전체메뉴 */
.ui_accordion .head.mix_type {
    display: flex;
    justify-content: space-between;
}
.ui_accordion .head.mix_type a {
    white-space: nowrap;
}

/* //바텀 네비게이션 */
/* 달력 */
div.ui-calendar-container {
    position: relative;
    width: 100%;
    padding-top: 8px;
    margin-top: 24px;
    outline: transparent;
}
div.ui-calendar-container:focus {
    outline: transparent;
}
div.ui-calendar-container .ui-select-day {
    padding: 0;
    padding-top: 24px;
    box-shadow: none;
}
div.ui-calendar-container .ui-select-day .ui-calendar-header-second {
    display: flex;
    justify-content: center;
    align-items: center;
}
div.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-prev {
    display: block;
    position: initial;
    margin: 0px;
    font-size: 0;
    line-height: 0;
    width: 44px;
    height: 44px;
    background-image: url("/images/mobilehome2/new/ico_arrow_down_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 20px 20px;
    transform: rotate(90deg);
}
div.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now {
    padding: 0px 8px;
}
div.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now span {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
}
div.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-next {
    display: block;
    position: initial;
    margin: 0px;
    font-size: 0;
    line-height: 0;
    width: 44px;
    height: 44px;
    background-image: url("/images/mobilehome2/new/ico_arrow_down_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 20px 20px;
    transform: rotate(-90deg);
}
div.ui-calendar-container .ui-select-day .ui-calendar-date {
    margin: 0px;
    padding: 32px 0px 0;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table {
    width: 100%;
    border-collapse: collapse;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-dayname {
    width: 14.2857142857%;
    padding: 0px;
    padding-bottom: 12px;
    display: table-cell;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-cell {
    position: relative;
    width: 14.2857142857%;
    height: auto;
    padding: 0px;
    border: 6px solid transparent;
    box-sizing: border-box;
    font-size: 0;
    line-height: 0;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-cell::before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: transparent;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-cell::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-top: 12px;
    margin-left: 1px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 1;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-cell .ui-calendar-day {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-color: transparent;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-holiday > .ui-calendar-day {
    color: #eb3912;
    background-color: transparent;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-holiday::after {
    background-color: #eb3912;
    border-radius: 0;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .disabled > .ui-calendar-day {
    color: #d4d6d4;
    background-color: transparent;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-extend::after {
    background-color: #01a76b;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-today::before {
    background-color: #01a76b;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-today .ui-calendar-day {
    color: #FFFFFF;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-today.ui-calendar-extend::after {
    background-color: #FFFFFF;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-today.ui-calendar-holiday > .ui-calendar-day {
    color: #eb3912;
    background-color: transparent;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-active::before {
    background-color: #01a76b;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-active .ui-calendar-day {
    color: #FFFFFF;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-active.ui-calendar-extend::after {
    background-color: #FFFFFF;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-active.ui-calendar-holiday .ui-calendar-day {
    color: #eb3912;
}
div.ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-other > .ui-calendar-day {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    color: #D4D6D4;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.ui-calendar-container::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 8px;
    background-color: #F7F7F7;
    transform: translateX(-50%);
}

/* 기간검색 */
.calendar_wrap .terms_select {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.calendar_wrap .terms_select > .sel_btn {
    width: calc((100% - 16px) / 3);
    margin: 0px !important;
}
.calendar_wrap .terms_select > .sel_btn label {
    min-width: 96px;
}
.calendar_wrap .terms_select + .multi_form {
    margin-top: 16px;
}
.calendar_wrap .input_wrap .input_cal {
    position: relative;
}
.calendar_wrap .input_wrap .input_cal input, .calendar_wrap .input_wrap .input_cal input:read-only {
    color: #171A19 !important;
    font-size: 0.937rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.calendar_wrap .input_wrap .input_cal .ui_HdApp_calendar {
    position: absolute;
    top: 12px;
    right: 16px;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_calendar_24_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.calendar_wrap .input_wrap .input_cal .ui_HdApp_calendar > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.calendar_wrap .multi_form .input_wrap {
    flex: auto;
}
.calendar_wrap .multi_form .dash {
    margin-left: 8px;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.calendar_wrap .multi_form.next_content_cal + .ui-calendar-container {
    padding-bottom: 8px;
    margin-bottom: 24px;
}
.calendar_wrap .multi_form.next_content_cal + .ui-calendar-container .ui-select-day {
    padding-bottom: 20px;
}
.calendar_wrap .multi_form.next_content_cal + .ui-calendar-container::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 8px;
    transform: translateX(-50%);
    background-color: #F7F7F7;
}
.calendar_wrap .multi_form.active div.input_wrap .input_cal input {
    background-color: #FFFFFF;
}
.calendar_wrap .multi_form.active div.input_wrap .input_cal.on input {
    border-color: #00905c;
}
.calendar_wrap .select_list {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.calendar_wrap .select_list div.sel_btn + .rdo_wrap {
    margin-top: 0;
}

/* 기간 검색 팝업 */
div._calendar_date {
    margin-top: 0 !important;
    padding-top: 24px !important;
}
div._calendar_date .coupon_gift_detail_data + .coupon_gift_detail_data {
    margin-top: 32px;
}
div._calendar_date .popup_sub_head {
    padding: 0 8px;
}
div._calendar_date .popup_sub_head .title span {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
div._calendar_date .popup_sub_head + .popup_sub_conts {
    margin-top: 12px;
}
div._calendar_date .popup_sub_conts._module {
    margin-right: 0px;
    margin-left: 0px;
}
div._calendar_date.is-btn + .lay_bot_btns .btn_wrap {
    padding-right: 0;
    padding-left: 0;
}

/* 단독 달력 */
.notice_type {
    padding: 32px 16px 20px;
}
.notice_type .lay_conts {
    padding: 0px !important;
}
.notice_type .lay_conts .ui-calendar-container {
    padding: 0px !important;
    margin: 0 !important;
}
.notice_type .lay_conts .ui-calendar-container::before {
    content: none;
}
.notice_type .lay_conts .ui-calendar-container .ui-select-day {
    padding-top: 0px;
}
.notice_type .lay_conts .ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-dayname {
    padding-bottom: 15px;
}
.notice_type .lay_conts .ui-calendar-container .ui-select-day .ui-calendar-date .ui-calendar-table .ui-calendar-cell {
    border-width: 4px;
    border-left: 0;
    border-right: 0;
}
.notice_type .lay_bot_btns {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 32px !important;
}

/* 완료 화면 */
.complete_wrap {
    padding-top: 16px;
}
.complete_wrap::before {
    width: 88px;
    height: 88px;
    background-image: url("/images/mo/common/ico_complete_88.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 16px;
    margin-left: auto;
    vertical-align: top;
    display: block;
}
.complete_wrap .comment {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    font-family: "Happiness-Sans" !important;
}
.complete_wrap .comment + .sub_comment {
    margin-top: 8px;
}
.complete_wrap .comment + .sub_comment > strong {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.complete_wrap .comment > em {
    font-size: inherit;
    font-weight: inherit;
    color: #01a76b;
    line-height: inherit;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.complete_wrap .comment.is_btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.complete_wrap .comment.is_btn > span {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.complete_wrap .comment.is_btn > span:last-of-type {
    width: auto;
}
.complete_wrap .comment.is_btn .refresh_btn {
    position: static !important;
    bottom: 0px !important;
    margin-left: 8px !important;
    font-size: 0;
    line-height: 0;
}
.complete_wrap .comment.is_btn .refresh_btn i {
    display: block;
    overflow: hidden;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_refresh_24_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #01A76B;
}
.complete_wrap .sub_comment {
    text-align: center;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    text-align: center;
}
.complete_wrap._first {
    padding-top: 32px;
}

/* AMY-N01-001T : 목록 이미지 아이템 */
.image_item {
    overflow: hidden;
    position: relative;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
.image_item_thumbnail {
    position: relative;
}
.image_item_thumbnail .image {
    position: relative;
}
.image_item_thumbnail .image img {
    width: 100%;
    line-height: 0px;
}
.image_item_info {
    display: block;
    position: relative;
    height: auto;
    padding: 20px 85px 24px 20px;
    box-sizing: border-box;
    background: #FFFFFF;
}
.image_item_info > .badge {
    font-size: 0;
    line-height: 0;
}
.image_item_info > .badge .flag_wrap + .flag_wrap {
    margin-left: 4px;
}
.image_item_info > .badge + .title {
    margin-top: 12px;
}
.image_item_info > .title {
    max-height: 48px;
}
.image_item_info > .title span {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: auto;
    font-size: 16px;
}
.image_item_info > .title._line01 {
    padding-right: 47px;
}
.image_item_info > .title._line01 span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: auto;
    font-size: 14px;
}
.image_item_info > .title + .text {
    margin-top: 4px;
}
.image_item_info > .text.inline > span {
    display: inline-block;
}
.image_item_info > .text > span {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    font-size: 14px;
}
.image_item_info > .text > span + .d_day {
    margin-left: 8px;
}
.image_item_info > .text .d_day {
    top: 54px;
    right: 20px;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
    font-weight: 700;
}
.image_item_info > .text .d_day.d14 {
    color: #01A76B;
}
.image_item_info > .text .d_day.d3 {
    color: #EB3912;
}
.image_item_info > .text > ul > li span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.image_item_info > .text > ul > li span + span {
    position: relative;
    margin-left: 8px;
    padding-left: 9px;
}
.image_item_info > .text > ul > li span + span::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 11px;
    opacity: 0.1;
    transform: translateY(-50%);
    background-color: #000;
}
.image_item_button {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 24px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.image_item.heart_btn_none .image_item_info {
    padding: 20px 20px 24px;
}

.small_image_item {
    position: relative;
    overflow: hidden;
    padding: 56px 20px 26px;
    border-radius: 16px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}
.small_image_item_conts {
    display: flex;
    column-gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
}
.small_image_item_thumbnail {
    width: auto;
    flex-shrink: 0;
}
.small_image_item_thumbnail .image {
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 16px;
}
.small_image_item_thumbnail .image img {
    width: 100%;
    object-fit: cover;
}
.small_image_item_info {
    width: 100%;
    margin-bottom: 21px;
    padding-bottom: 9px;
}
.small_image_item_info > .title span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: auto;
    word-break: keep-all;
}
.small_image_item_info > .title + .date {
    margin-top: 4px;
}
.small_image_item_info > .date,
.small_image_item_info > .place {
    font-size: 0;
    line-height: 0;
}
.small_image_item_info > .date span,
.small_image_item_info > .place span {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.small_image_item_info > .place + .money {
    margin-top: 8px;
}
.small_image_item_info > .money .em {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.small_image_item_info > .money .em + .through {
    margin-left: 7px;
}
.small_image_item_info > .money .through {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    text-decoration: line-through;
}
.small_image_item_info > .money .through + .em {
    margin-left: 7px;
}
.small_image_item_button > .badge {
    position: absolute;
    top: 20px;
    font-size: 0;
    line-height: 0;
}
.small_image_item_button > .badge > .flag_wrap + .flag_wrap {
    margin-left: 8px;
}
.small_image_item_button > .btn_wrap.ico {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
.small_image_item._btn_is_heart {
    padding: 20px;
}
.small_image_item._goods {
    padding: 20px;
}
.small_image_item._goods .small_image_item_conts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.small_image_item._goods .small_image_item_conts .small_image_item_info {
    margin-bottom: 0px;
}
.small_image_item._goods .small_image_item_conts .small_image_item_info > .title + .money {
    margin-top: 12px;
}
.small_image_item._goods .small_image_item_conts .small_image_item_info > .money > .em {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.small_image_item.heart_btn_none .small_image_item_info {
    margin: 0px;
    padding: 0px;
}

.con_view_products {
    position: relative;
}
.con_view_products .btn_heart_share {
    display: flex;
    position: absolute;
    right: 0;
    top: -1px;
    justify-content: flex-end;
    width: 135px;
    height: 34px;
    background-image: url("/images/mo/shopping/btn_heart_share.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.con_view_products .btn_heart_share .ico_heart_20_green_gray {
    overflow: hidden;
    line-height: 10;
    width: 20px;
    height: 20px;
    background-image: url("/images/mo/shopping/ico_heart_20_green_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.con_view_products .btn_heart_share .ico_heart_20_green_gray.on {
    width: 20px;
    height: 20px;
    background-image: url("/images/mo/shopping/ico_heart_20_green_gray_on.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.con_view_products .btn_heart_share .ico_share_20_green_gray {
    margin: 0 24px 0 16px;
    overflow: hidden;
    line-height: 10;
    width: 20px;
    height: 20px;
    background-image: url("/images/mo/shopping/ico_share_20_green_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 20px !important;
    height: 20px !important;
}
.con_view_products .btn_dotbogi_shop_gh {
    position: absolute;
    right: 24px;
    bottom: 24px;
    background-color: #21b27e;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}
.con_view_products .btn_dotbogi_shop_gh i {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    background-image: url("/images/mo/shopping/btn_dotbogi_shop_24_gh.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-size: 0;
}

.item_banner {
    position: relative;
    display: block;
    border-radius: 25px;
    overflow: hidden;
}
.item_banner .slide_tit_text {
    position: absolute;
    z-index: 10;
    left: 20px;
    bottom: 40px;
}
.item_banner .slide_tit_text dt {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    font-family: "Happiness-Sans" !important;
    letter-spacing: -1px;
}
.item_banner .slide_tit_text dt > .flag_wrap {
    position: absolute;
    top: -9px;
    left: 0px;
    transform: translateY(-100%);
    font-family: "Pretendard" !important;
}
.item_banner .slide_tit_text dd {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.item_banner .slide_info_text_tr {
    /*
    position:absolute;
    z-index:10;
    right:0px;
    top:0px;
    padding:8px 26px 0 0;
    text-align:right;
    // @include font_pre(0.75rem,400,$white,1.5);
    @include font_pre(12px,400,$white,1.5);
    @include bg(129px,34px,'/images/mo/shopping/bg_slide_topright.png');
    box-sizing: border-box;
    background-position-y: 0;
    */
    position: absolute;
    top: 0;
    right: 0;
    width: 129px;
    height: 34px;
    padding-left: 34px;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.2857142857;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
    font-size: 12px;
    background-image: url("/images/mo/shopping/bg_slide_topright.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.item_banner .slide_info_text_br {
    position: absolute;
    z-index: 10;
    right: 20px;
    bottom: 20px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    padding: 1px 8px 1px 8px;
    background: #171A19;
}
.item_banner:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
}

/* AMY-A01-007T : 대상(가족)선택 바텀 셀렉트 형 */
.owner .top_type {
    display: inline-block;
    position: relative;
    width: auto;
    margin-left: 8px;
    padding: 0;
    border: none;
    background-color: transparent;
}
.owner .top_type:after {
    content: "";
    position: absolute;
    top: 50%;
    left: initial;
    bottom: 0;
    right: -24px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(-50%);
}
.owner .top_type .ui_select_text {
    font-size: 1.25rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.owner .top_type .ui_select_text > em {
    margin-right: 4px;
    opacity: 0.6;
    font-size: 1.25rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.owner._black .top_type:after {
    background-image: url("/images/mobilehome2/mypage/new/ico_arrow_down_20_black.png");
}
.owner._black .top_type .ui_select_text {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "Happiness-Sans" !important;
}
.owner._black .top_type .ui_select_text > em {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "Happiness-Sans" !important;
}
.owner._black .button_wrap {
    margin-left: 8px;
}
.owner._black .button_wrap:after {
    content: none;
}
.owner._black .button_wrap .btn_wrap.store_select .text {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "Happiness-Sans" !important;
}
.owner._black .button_wrap .btn_wrap.store_select .text > em {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "Happiness-Sans" !important;
}

/* 카드 > 청구계정선택 */
.account_select .btn_sel_type {
    width: auto;
    margin: 0;
    padding: 13px 44px 13px 8px;
    border-color: #FFFFFF;
}
.account_select .btn_sel_type span {
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}
.account_select .btn_sel_type span > em {
    font-size: 1.25rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans" !important;
}

/* 마이카드 > 즉시결제내역 */
.history_state {
    padding: 20px;
    border-radius: 18px;
    background-color: #F7FAF9;
}
.history_state dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.history_state dl dt {
    font-size: 0.875rem;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.history_state dl dd {
    font-size: 0.875rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.history_state dl dd .no {
    color: #EB3912;
    font-weight: 500;
}
.history_state dl + dl {
    margin-top: 12px;
}

/* 온라인페이 > 보안비밀번호 네자리 */
.password_check_gh {
    display: flex;
}
.password_check_gh span {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password_check_gh span em {
    position: relative;
    top: 2px;
    font-size: 1rem;
    font-weight: 800;
    color: #000;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.password_check_gh span.empty {
    border: solid 1px #EAEBEA;
    background-color: #F7F7F7;
}
.password_check_gh span.empty em {
    display: none;
}
.password_check_gh span.empty.on {
    background-color: #00905C;
    border-color: #00905C;
}
.password_check_gh span.empty.on em {
    display: inline;
    font-size: 1rem;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.password_check_gh span + span {
    margin-left: 8px;
}

/* 온라인페이 > 보안비밀번호 여러자리 */
.input_wrap.input_wrap_hidden_privacy input {
    color: white;
}
.input_wrap.input_wrap_hidden_privacy .hidden_privacy_gh {
    position: absolute;
    left: 16px;
    top: 17px;
    letter-spacing: -4px;
}
.input_wrap.input_wrap_hidden_privacy .hidden_privacy_gh span {
    font-size: 0.937rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.input_wrap.input_wrap_hidden_privacy .hidden_privacy_gh span.on {
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* 기간조회 */
.date_term_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EAEBEA;
}
.date_term_search .date {
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.date_term_search .btn_ico .search:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 2px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_calendar_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.date_term_search .btn_ico .search > span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* 이용내역 */
.common_usage_history .top_sec {
    padding: 32px 8px;
}
.common_usage_history .usage_list {
    margin-top: 32px;
}
.common_usage_history .usage_list dl {
    position: relative;
    padding: 20px;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}
.common_usage_history .usage_list dl dt {
    padding-right: 145px;
    font-size: 0.937rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.common_usage_history .usage_list dl dd {
    margin-top: 4px;
}
.common_usage_history .usage_list dl dd .btn_wrap {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.common_usage_history .usage_list dl dd .btn_wrap > span {
    font-size: 0.937rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.common_usage_history .usage_list dl dd .btn_wrap .minus {
    color: #EB3912;
}
.common_usage_history .usage_list dl dd .btn_wrap.static {
    position: static;
    justify-content: left;
    margin-top: 12px;
}
.common_usage_history .usage_list dl dd.info .date, .common_usage_history .usage_list dl dd.info .etc {
    display: block;
}
.common_usage_history .usage_list dl dd.info .date span, .common_usage_history .usage_list dl dd.info .etc span {
    font-size: 0.75rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.common_usage_history .usage_list dl dd.info .etc {
    margin-top: 2px;
}
.common_usage_history .usage_list dl dd.info .etc > span + span:before {
    content: "";
    display: inline-block;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 8px;
    vertical-align: top;
    width: 1px;
    height: 11px;
    background-color: #171A19;
    opacity: 0.1;
}
.common_usage_history .usage_list dl + dl {
    margin-top: 24px;
}

.usage_list {
    margin-top: 32px;
}
.usage_list > div.no_result {
    margin-top: 80px;
    margin-bottom: 80px;
}

/* 약관 타입01 */
.terms_type01 {
    padding: 16px 8px 80px;
}
.terms_type01 .tit01 {
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_type01 .tit03 {
    display: inherit;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.terms_type01 .tit02 {
    margin-top: 24px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_type01 .tit02:first-child {
    margin-top: 0;
}
.terms_type01 ul {
    margin-top: 8px;
}
.terms_type01 ul > li {
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_type01 .tb_conts .stxt {
    margin-bottom: 12px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* ABI-A01-013B 지점선택 팝업 / 공통 관심있는 지점 선택*/
.lay_conts._common {
    padding-right: 24px !important;
    padding-left: 24px !important;
}
.lay_conts._common .top_sec {
    padding-top: 16px;
}
.lay_conts._common .top_sec .tit02 + .sub_desc {
    margin-top: 8px;
    word-break: keep-all;
}
.lay_conts._common .office_select_wrap {
    padding-top: 40px;
    padding-bottom: 80px;
}
.lay_conts._select {
    padding-top: 0;
}
.lay_conts._select + .lay_bot_btns {
    padding: 0 16px !important;
}
.lay_conts .office_select + .office_select {
    margin-top: 32px;
}
.lay_conts .office_select_title {
    padding-left: 8px;
}
.lay_conts .office_select_title span {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
.lay_conts .office_select_title + .office_select_conts {
    margin-top: 12px;
}
.lay_conts .office_select_conts > ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.lay_conts .office_select_conts > ul > li {
    width: calc(50% - 4px);
    margin-top: 8px;
}
.lay_conts .office_select_conts > ul > li:nth-child(-n+2) {
    margin-top: 0px;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn {
    width: 100%;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn label {
    display: flex;
    padding: 11px 12px 13px;
    border: 1px solid #eaebea;
    border-radius: 33px;
    align-items: center;
    justify-content: center;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn label::before {
    content: none;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn label span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn label span em {
    margin-left: 5px;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #01a76b;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn input:checked + label {
    background-color: #01a76b;
    border: 1px solid #01a76b;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn input:checked + label span {
    font-weight: 700;
    color: #FFFFFF;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn input:checked + label span em {
    font-weight: 700;
    color: #FFFFFF;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn input:disabled + label {
    pointer-events: none;
    background-color: #F7F7F7;
}
.lay_conts .office_select_conts > ul > li .rdo_wrap.round_btn input:disabled + label span {
    color: #D4D6D4;
}
.lay_conts .office_select_conts > ul.all_select_box > li {
    width: 100%;
}

.address_from > dt {
    padding-left: 8px;
    display: block;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.address_from > dt + dd {
    margin-top: 8px;
}

/* 쿠폰 : AMY-A01-001T : 패키지 쿠폰 */
.accordion_coupon_list {
    overflow: hidden;
    border-radius: 18px;
    margin-bottom: 12px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
.accordion_coupon_list .icon_wrap i.icon_arrow {
    display: block;
    line-height: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/mo/common/ico_dropdown_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.accordion_coupon_list .icon_wrap i.icon_arrow + span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.accordion_coupon_list .ui_accordion > ul > li {
    background-color: #FFFFFF;
}
.accordion_coupon_list .ui_accordion > ul > li > .head {
    position: relative;
    font-size: 0;
    line-height: 0;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title {
    position: relative;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 2;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .flag {
    position: static;
    padding-bottom: 0px;
    margin: 0px;
    margin-bottom: 8px;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .flag .flag_wrap {
    padding: 0px 7px;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .flag .flag_wrap span {
    padding: 0;
    margin: 0px;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
    background: transparent;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .title strong {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .title + .text {
    margin-top: 8px;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .text_area > .text p {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.accordion_coupon_list .ui_accordion > ul > li > .head .coupon_title .img_area {
    position: relative;
    width: 100%;
    z-index: 1;
}
.accordion_coupon_list .ui_accordion > ul > li .ui_accord_toggle {
    display: flex;
    position: absolute;
    width: 162px;
    height: 34px;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-end;
    width: 162px;
    height: 34px;
    background-image: url("/images/mobilehome2/mypage/new/img_button_bg_01.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;
}
.accordion_coupon_list .ui_accordion > ul > li .ui_accord_toggle > .button_area {
    display: flex;
    height: 100%;
    margin: 0px !important;
    padding-right: 12px;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #FFFFFF;
}
.accordion_coupon_list .ui_accordion > ul > li .ui_accord_toggle > .button_area p {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_coupon_list .ui_accordion > ul > li .ui_accord_toggle > .button_area p em {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    min-width: 10px;
    margin: 0 4px;
}
.accordion_coupon_list .ui_accordion > ul > li .ui_accord_toggle > .button_area .icon_arrow {
    transition: all 0.3s ease-in;
    transform: rotate(0deg);
}
.accordion_coupon_list .ui_accordion > ul > li.on .ui_accord_toggle > .button_area .icon_arrow {
    transform: rotate(180deg);
}
.accordion_coupon_list .coupon_list {
    padding: 8px 20px 4px;
}
.accordion_coupon_list .coupon_list .coupon_item + .coupon_item {
    border-top: 1px solid #EAEBEA;
}

/* 쿠폰 : AMY-A01-001T ~ AMY-A01-019T : 이미지 그림자 떄문에 상단 12 하단 16 좌우 16 공간을 가지고 있습니다. */
.coupon_img_box {
    position: relative;
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01.png");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.coupon_img_box::before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 45.834%;
    z-index: -1;
}
.coupon_img_box .coupon_item_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 3.337% 4.445% 4.445%;
    top: 0;
    left: 0;
    box-sizing: border-box;
}
.coupon_img_box .coupon_item {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 6.1%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.coupon_img_box .coupon_item .flag_area {
    top: -8px;
}
.coupon_img_box .coupon_item .coupon_item_area {
    padding: 0px;
    height: 100%;
    box-sizing: border-box;
}
.coupon_img_box .coupon_item .coupon_item_area .contents_wrap .info_title {
    margin-right: 20px;
}
.coupon_img_box .coupon_item .coupon_item_area > .button_wrap {
    display: flex;
    width: 21.43%;
    margin: 0px;
    justify-content: center;
    align-items: center;
}
.coupon_img_box .coupon_item .coupon_item_area > .button_wrap .text_em {
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-content: center;
}
.coupon_img_box .coupon_item .coupon_item_area > .button_wrap span {
    text-align: center;
    word-break: keep-all;
}
.coupon_img_box._dis, .coupon_img_box.end, .coupon_img_box.full {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off.png");
}
.coupon_img_box._dis .coupon_item_area, .coupon_img_box.end .coupon_item_area, .coupon_img_box.full .coupon_item_area {
    opacity: 0.3;
}
.coupon_img_box._dis .coupon_item_area > .button_wrap .text_em span, .coupon_img_box.end .coupon_item_area > .button_wrap .text_em span, .coupon_img_box.full .coupon_item_area > .button_wrap .text_em span {
    color: #171a19;
}
.coupon_img_box._dis .coupon_item_area > .button_wrap .text_em i, .coupon_img_box.end .coupon_item_area > .button_wrap .text_em i, .coupon_img_box.full .coupon_item_area > .button_wrap .text_em i {
    display: none;
}
.coupon_img_box.shape_01 {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01.png");
}
.coupon_img_box.shape_01::before {
    padding-bottom: 45.834%; /* height: 165px; */
}
.coupon_img_box.shape_01._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off.png");
}
.coupon_img_box.shape_01.no_btn {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_no_btn.png");
}
.coupon_img_box.shape_01.no_btn .coupon_item .contents_wrap {
    padding-right: 21%;
}
.coupon_img_box.shape_01.no_btn._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off_no_btn.png");
}
.coupon_img_box.shape_02 {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_02.png");
}
.coupon_img_box.shape_02::before {
    padding-bottom: 51.667%; /* height: 186px; */
}
.coupon_img_box.shape_02._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_02_off.png");
}
.coupon_img_box.shape_02.no_btn {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_02_no_btn.png");
}
.coupon_img_box.shape_02.no_btn .coupon_item .contents_wrap {
    padding-right: 22%;
}
.coupon_img_box.shape_02.no_btn._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_02_off_no_btn.png");
}
.coupon_img_box.shape_03 {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01.png");
}
.coupon_img_box.shape_03::before {
    padding-bottom: 45.834%; /* height: 165px; */
}
.coupon_img_box.shape_03._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off.png");
}
.coupon_img_box.shape_03.no_btn {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_no_btn.png");
}
.coupon_img_box.shape_03.no_btn .coupon_item .contents_wrap {
    padding-right: 22%;
}
.coupon_img_box.shape_03.no_btn._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off_no_btn.png");
}
.coupon_img_box.shape_04 {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_04.png");
}
.coupon_img_box.shape_04::before {
    padding-bottom: 46.11%; /* height: 166px; */
}
.coupon_img_box.shape_04._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off.png");
}
.coupon_img_box.shape_04.no_btn {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_no_btn.png");
}
.coupon_img_box.shape_04.no_btn .coupon_item .contents_wrap {
    padding-right: 22%;
}
.coupon_img_box.shape_04.no_btn._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_01_off_no_btn.png");
}
.coupon_img_box.shape_04 .coupon_item_area .contents_wrap .info_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: auto;
    max-height: 72px;
}
.coupon_img_box.shape_05 {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_05.png");
}
.coupon_img_box.shape_05::before {
    padding-bottom: 45.27%; /* height: 163px; */
}
.coupon_img_box.shape_05._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_05_off.png");
}
.coupon_img_box.shape_05.no_btn {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_05_no_btn.png");
}
.coupon_img_box.shape_05.no_btn .coupon_item .contents_wrap {
    padding-right: 22%;
}
.coupon_img_box.shape_05.no_btn._dis {
    background-image: url("/images/mobilehome2/mypage/new/img_mycoupon_shape_05_off_no_btn.png");
}
.coupon_img_box.module {
    overflow: hidden;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background: none;
}
.coupon_img_box.module::before {
    display: none;
}
.coupon_img_box.module .coupon_item_wrap {
    position: initial;
    width: 100%;
    height: auto;
    padding: 32px 20px;
}
.coupon_img_box.module .coupon_item_wrap .coupon_item {
    padding: 0px;
}
.coupon_img_box + .coupon_img_box {
    margin-top: -4px;
}

/* 쿠폰 : AMY-A01-001T ~ AMY-A01-019T : 겉 이미지 제외한 내부 아이템 박스 입니다. */
.coupon_item {
    position: relative;
}
.coupon_item .icon_wrap i.icon_new {
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #FF5000;
}
.coupon_item .icon_wrap i.icon_new span {
    padding-top: 1px;
    display: inline-block;
    font-size: 0.5rem;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 1;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.coupon_item .btn_wrap .text_em {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.coupon_item .btn_wrap .text_em span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01a76b;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.coupon_item .btn_wrap .text_em i {
    display: none;
    margin-top: 2px;
}
.coupon_item .btn_wrap .text_em .icon_barcode {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/mypage/new/ico_barcode_24_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .btn_wrap .text_em .icon_download {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/mypage/new/ico_download_24_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .flag_area {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0;
    align-items: flex-start;
    justify-content: flex-start;
}
.coupon_item .flag_area > * + * {
    margin-left: 4px;
}
.coupon_item .coupon_item_area {
    display: flex;
    position: relative;
    width: 100%;
    padding: 16px 0;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}
.coupon_item .coupon_item_area > a {
    display: flex;
    width: 100%;
    min-height: 100%;
    align-items: center;
    justify-content: flex-start;
}
.coupon_item .thumbnail_wrap {
    display: block;
    position: relative;
    width: auto;
    flex-shrink: 0;
}
.coupon_item .thumbnail_wrap .icon_wrap {
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 1;
}
.coupon_item .thumbnail_area {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.coupon_item .thumbnail_area.sale {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_sale_thumbnail_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.sale > img {
    display: none;
}
.coupon_item .thumbnail_area.sale._03 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_sale_thumbnail_3_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.sale._05 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_sale_thumbnail_5_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.sale._10 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_sale_thumbnail_10_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.amountdiscount {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_amountdiscount_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.amountdiscount > img {
    display: none;
}
.coupon_item .thumbnail_area.amountdiscount._5000 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_amountdiscount_thum_5000.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.amountdiscount._10000 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_amountdiscount_thum_10000.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.amountdiscount._100000 {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_amountdiscount_thum_100000.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.interestfree {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_interestfree_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.interestfree > img {
    display: none;
}
.coupon_item .thumbnail_area.parking {
    width: 48px;
    height: 48px;
    background-image: url("/images/mobilehome2/mypage/new/img_parking_thum.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.coupon_item .thumbnail_area.parking > img {
    display: none;
}
.coupon_item .contents_wrap {
    width: 100%;
    margin-left: 16px;
}
.coupon_item .contents_wrap .info_area {
    display: block;
}
.coupon_item .contents_wrap .info_branch span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.coupon_item .contents_wrap .info_branch + .info_title {
    margin-top: 2px;
}
.coupon_item .contents_wrap .info_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 48px;
    height: auto;
    max-height: 48px;
}
.coupon_item .contents_wrap .info_title span {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    word-break: break-all;
}
.coupon_item .contents_wrap .info_text span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.coupon_item .contents_wrap .info_date {
    margin-top: 8px;
}
.coupon_item .contents_wrap .info_date span {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.coupon_item .contents_wrap .info_date span + em {
    margin-left: 8px;
}
.coupon_item .contents_wrap .info_date em {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.coupon_item .button_wrap {
    width: auto;
    margin-left: 28px;
    flex-shrink: 0;
}
.coupon_item .button_wrap > .btn_wrap {
    width: 48px;
}
.coupon_item .button_wrap > .btn_wrap > .btn {
    padding: 7px 0px;
    border-radius: 8px;
    word-break: keep-all;
}
.coupon_item.end .thumbnail_wrap, .coupon_item.full .thumbnail_wrap, .coupon_item._dis .thumbnail_wrap {
    opacity: 0.5;
}
.coupon_item.end .contents_wrap, .coupon_item.full .contents_wrap, .coupon_item._dis .contents_wrap {
    opacity: 0.3;
}
.coupon_item.end .button_wrap > .btn_wrap > .btn, .coupon_item.full .button_wrap > .btn_wrap > .btn, .coupon_item._dis .button_wrap > .btn_wrap > .btn {
    border: solid 1px #eaebea;
    background-color: #f7f7f7;
}
.coupon_item.end .button_wrap > .btn_wrap > .btn span, .coupon_item.full .button_wrap > .btn_wrap > .btn span, .coupon_item._dis .button_wrap > .btn_wrap > .btn span {
    opacity: 0.3;
    color: #171a19;
}
.coupon_item._medium {
    overflow: hidden;
    width: 100%;
    border: 1.4px solid transparent;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
.coupon_item._medium > .coupon_item {
    padding: 0px !important;
}
.coupon_item._medium .coupon_item_area {
    padding: 32px 20px;
}
.coupon_item._medium.end, .coupon_item._medium.full, .coupon_item._medium._dis {
    border: 1.4px solid #eaebea;
    background-color: #f7f7f7;
}
.coupon_item._medium.end > .coupon_item_area > a, .coupon_item._medium.full > .coupon_item_area > a, .coupon_item._medium._dis > .coupon_item_area > a {
    pointer-events: none;
}

/* 리스트 아이템 : 검색 AET-D01-003 젤리리스트 사용 */
.icon_list_item {
    overflow: hidden;
    padding: 20px;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.12);
}
.icon_list_item .icon_list_item_conts {
    display: flex;
    column-gap: 12px;
    justify-content: flex-start;
    align-items: center;
}
.icon_list_item .icon_list_item_conts .thumbnail_wrap {
    overflow: hidden;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #f7f7f7;
}
.icon_list_item .icon_list_item_conts .thumbnail_wrap .thumbnail {
    padding: 4px;
}
.icon_list_item .icon_list_item_conts .thumbnail_wrap .thumbnail > img {
    width: 100%;
}
.icon_list_item .icon_list_item_conts .contents_wrap .text_area .title span {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: auto;
}
.icon_list_item .icon_list_item_conts .contents_wrap .text_area .date span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}

/* 리스트 아이템 : 검색 AET-D01-003 모바일 사은정보 사용 */
.text_list_item {
    position: relative;
    padding: 20px;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.text_list_item_body > strong.title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: auto;
}
.text_list_item_body > strong.title + span.date {
    margin-top: 4px;
}
.text_list_item_body > span.date {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.text_list_item_body > em.d_day {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01a76b;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}

/*230706 1-1 수정된 패키지 모듈*/
.accordion_coupon_list_shop {
    overflow: hidden;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head {
    position: relative;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title {
    display: flex;
    padding: 20px;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .img_area {
    margin-right: 16px;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .img_area img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .text_area > .flag {
    position: unset;
    margin-bottom: 12px;
    background-color: transparent;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .text_area > .flag .flag_wrap span {
    padding: 0;
    margin: 0px;
    background: transparent;
    color: white;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .text_area > .title {
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.accordion_coupon_list_shop .ui_accordion > ul > li > .head .coupon_title .text_area > .text {
    margin-top: 8px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle {
    display: block;
    padding-top: 16px;
    border-top: 1px solid #EAEBEA;
    margin: 0 20px;
    padding: 16px 0 20px 0;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle > .button_area {
    display: flex;
    justify-content: space-between;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle > .button_area p {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle > .button_area p em {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #01a76b;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
    min-width: 10px;
    margin: 0 4px;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle > .button_area .icon_arrow {
    position: relative;
    top: 1px;
    transition: all 0.3s ease-in;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/images/mo/common/ico_dropdown_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle > .button_area .icon_arrow + span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle[aria-expanded=false] > .button_area .icon_arrow {
    transform: rotate(0deg);
}
.accordion_coupon_list_shop .ui_accordion > ul > li .ui_accord_toggle[aria-expanded=true] > .button_area .icon_arrow {
    transform: rotate(180deg);
}
.accordion_coupon_list_shop .coupon_list {
    padding: 4px 20px;
}
.accordion_coupon_list_shop .ui_accord_content .coupon_list > div:first-of-type.coupon_item .coupon_item_area {
    padding-top: 0px !important;
}

/* 쿠폰 - 행사안내상세용 */
.park_list_free_ticket {
    padding-top: 15px;
    padding-left: 16px;
    padding-right: 16px;
}
.park_list_free_ticket.coupon_item_bgheight_l li {
    box-sizing: content-box;
    width: 86%;
    height: 140px;
    background-image: url("/images/mo/service/coupon_bg_list.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position: 0 0;
    background-size: 100% 212px;
}
.park_list_free_ticket.coupon_item_bgheight_l li dl {
    position: relative;
    top: -5px;
    margin-left: 60px;
    max-width: 67%;
}
.park_list_free_ticket.coupon_item_bgheight_l li dl:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -60px;
    bottom: auto;
    right: auto;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background-image: url("/images/mo/shopping/shop_coupon_bg_list.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #daf5f0;
    border-radius: 50%;
}
.park_list_free_ticket li {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: -16px;
    padding: 36px calc(7% + 16px);
}
.park_list_free_ticket li dt {
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.park_list_free_ticket li dt .flag_wrap {
    margin-left: 5px;
    position: relative;
    top: -2px;
}
.park_list_free_ticket li dt span {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    margin-bottom: 2px;
}
.park_list_free_ticket li dd {
    font-size: 0.875rem;
    font-weight: 500;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.park_list_free_ticket li dd + dd {
    margin-top: 8px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.park_list_free_ticket li button {
    position: absolute;
    right: 8.5%;
    top: 48%;
    transform: translateY(-50%);
    font-size: 0.875rem;
    font-weight: 700;
    color: #01A76B;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.park_list_free_ticket li + li {
    margin-top: -35px;
}

/* 약관 전용 */
.terms_wrap.service {
    padding-top: 32px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.terms_wrap.service h2.top-tit {
    padding-bottom: 24px;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.service .top-tit {
    padding-bottom: 24px;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.service .part1 {
    padding-bottom: 24px;
}
.terms_wrap.service .part1 .part1-top .p1-tit {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.service .part1 .part1-top + .txt {
    margin-top: 8px;
}
.terms_wrap.service .part1 .txt {
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.service .part1 .txt li + li {
    margin-top: 8px;
}
.terms_wrap.service .part1 .table_wrap {
    margin-top: 24px;
}
.terms_wrap.identity {
    padding-top: 32px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.terms_wrap.identity h2.top-tit {
    padding-bottom: 24px;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.identity .top-tit {
    padding-bottom: 24px;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.identity .part1 {
    padding-bottom: 24px;
}
.terms_wrap.identity .part1 .part1-top .p1-tit {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.identity .part1 .part1-top + .txt {
    margin-top: 8px;
}
.terms_wrap.identity .part1 .txt {
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.terms_wrap.identity .part1 .txt li + li {
    margin-top: 8px;
}
.terms_wrap.identity .part1 .txt + .txt {
    margin-top: 8px;
}
.terms_wrap.identity .part1 .table_wrap {
    margin-top: 24px;
}

/* 캐러셀 타입별 모듈 정의 */
.slide_wrap {
    overflow: hidden;
}
.slide_wrap.module01, .slide_wrap.module02 {
    position: relative;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.12);
}
.slide_wrap.module01 .slide_content, .slide_wrap.module02 .slide_content {
    border-radius: 18px;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts, .slide_wrap.module02 .slide_content .slide_track .slide_conts {
    position: relative;
    display: flow-root;
    overflow: hidden;
    margin: 0 16px;
    font-size: 0;
    line-height: 0;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .slide_box,
.slide_wrap.module01 .slide_content .slide_track .slide_conts .ui_bnr_interaction, .slide_wrap.module02 .slide_content .slide_track .slide_conts .slide_box,
.slide_wrap.module02 .slide_content .slide_track .slide_conts .ui_bnr_interaction {
    display: inline-block;
    position: relative;
    min-width: 100%;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .slide_box::before,
.slide_wrap.module01 .slide_content .slide_track .slide_conts .ui_bnr_interaction::before, .slide_wrap.module02 .slide_content .slide_track .slide_conts .slide_box::before,
.slide_wrap.module02 .slide_content .slide_track .slide_conts .ui_bnr_interaction::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 0px;
    padding-bottom: 140.329%;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .slide_box > img,
.slide_wrap.module01 .slide_content .slide_track .slide_conts .ui_bnr_interaction > img, .slide_wrap.module02 .slide_content .slide_track .slide_conts .slide_box > img,
.slide_wrap.module02 .slide_content .slide_track .slide_conts .ui_bnr_interaction > img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend {
    position: absolute;
    margin: 0 0 56px 20px;
    left: 0;
    bottom: 0;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend em, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend em {
    margin: 0 0 8px 0;
    padding: 1px 8px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
    background-color: #FFFFFF;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color01, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color01 {
    border-color: #01A76B;
    color: #01A76B;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color02, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color02 {
    border-color: #ef9e00;
    color: #ef9e00;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color03, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color03 {
    border-color: #3a5fff;
    color: #3a5fff;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color04, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend em.color04 {
    border-color: #ff4bcd;
    color: #ff4bcd;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .txt_in_slide_trend span, .slide_wrap.module02 .slide_content .slide_track .slide_conts .txt_in_slide_trend span {
    display: block;
    padding-right: 20px;
    letter-spacing: -1px;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4166666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: auto;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .btn_wrap, .slide_wrap.module02 .slide_content .slide_track .slide_conts .btn_wrap {
    position: absolute;
    display: flex;
    padding-right: 20px;
    top: 0;
    right: -1px;
    align-items: center;
    justify-content: flex-end;
    width: 168px;
    height: 48px;
    background-image: url("/images/mo/shopping/btn_trend_heart_share.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-x: 100%;
    background-position-y: top;
    z-index: 5;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .btn_slide_seemoore, .slide_wrap.module02 .slide_content .slide_track .slide_conts .btn_slide_seemoore {
    display: none;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .btn_slide_seemoore:after, .slide_wrap.module02 .slide_content .slide_track .slide_conts .btn_slide_seemoore:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 63px;
    bottom: auto;
    right: auto;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url("/images/mo/shopping/btn_slide_seemoore.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts:last-of-type .btn_slide_seemoore, .slide_wrap.module02 .slide_content .slide_track .slide_conts:last-of-type .btn_slide_seemoore {
    display: block;
    z-index: 2;
    width: 93px;
    height: 56px;
    background: #01A76B;
    padding: 17px 0 0 20px;
    position: absolute;
    right: 0;
    top: 50%;
    border-radius: 29px 0 0 29px;
    transform: translateY(-50%);
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts:first-of-type .btn_slide_seemoore, .slide_wrap.module02 .slide_content .slide_track .slide_conts:first-of-type .btn_slide_seemoore {
    visibility: hidden;
}
.slide_wrap.module01 .slide_content .slide_track .slide_conts .btn_movie_slide_trend, .slide_wrap.module02 .slide_content .slide_track .slide_conts .btn_movie_slide_trend {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    background-image: url("/images/mo/shopping/ico_trend_32_movie.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.slide_wrap.module01 .slide_page_number, .slide_wrap.module02 .slide_page_number {
    right: 40px;
    bottom: 24px;
    padding: 1px 8px 2px 8px;
    border-radius: 25px;
    background-color: rgba(23, 26, 25, 0.5);
}
.slide_wrap.module01 .slide_page_number .current_num, .slide_wrap.module02 .slide_page_number .current_num {
    font-size: 0.75rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.slide_wrap.module01 .slide_page_number .total_num, .slide_wrap.module02 .slide_page_number .total_num {
    font-size: 0.75rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    opacity: 0.4;
}
.slide_wrap.module01 .slide_page_number .total::before, .slide_wrap.module02 .slide_page_number .total::before {
    height: 9px;
    opacity: 0.3;
}
.slide_wrap.module01 .slide_controls, .slide_wrap.module02 .slide_controls {
    margin: 0 16px;
    width: calc(100% - 32px);
}
.slide_wrap.module01 .slide_controls .ui_carousel_next:disabled, .slide_wrap.module02 .slide_controls .ui_carousel_next:disabled {
    display: none !important;
}
.slide_wrap.module_type {
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.12);
    border-radius: 18px;
    position: relative;
}
.slide_wrap.module_type .slide_conts .btn_wrap {
    position: absolute;
    display: flex;
    padding-right: 20px;
    top: 0;
    right: -1px;
    align-items: center;
    justify-content: flex-end;
    width: 168px;
    height: 48px;
    background-image: url("/images/mo/shopping/btn_trend_heart_share.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-x: 100%;
    background-position-y: top;
    z-index: 5;
}
.slide_wrap.module_type .slide_conts .btn_slide_seemoore {
    display: none;
}
.slide_wrap.module_type .slide_conts .btn_slide_seemoore:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 63px;
    bottom: auto;
    right: auto;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url("/images/mo/shopping/btn_slide_seemoore.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.slide_wrap.module_type .slide_conts:last-of-type .btn_slide_seemoore {
    display: block;
    z-index: 2;
    width: 93px;
    height: 56px;
    background: #01A76B;
    padding: 17px 0 0 20px;
    position: absolute;
    right: 0;
    top: 50%;
    border-radius: 29px 0 0 29px;
    transform: translateY(-50%);
    box-sizing: border-box;
    font-size: 0.937rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.47;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.slide_wrap.module_type .slide_content {
    border-radius: 18px;
}
.slide_wrap.module_type .slide_content img {
    position: relative;
    border-radius: 18px;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.12);
}
.slide_wrap.module_type .slide_content img.bg_img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.slide_wrap.module_type .slide_page_number {
    right: 20px;
    bottom: 24px;
    padding: 1px 8px 2px 8px;
    border-radius: 25px;
    background-color: rgba(23, 26, 25, 0.5);
}
.slide_wrap.module_type .slide_page_number .current_num {
    font-size: 0.75rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.slide_wrap.module_type .slide_page_number .total_num {
    font-size: 0.75rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    opacity: 0.4;
}
.slide_wrap.module_type .slide_page_number .total::before {
    height: 9px;
    opacity: 0.3;
}
.slide_wrap.module_type .slide_controls .disabled {
    opacity: 0.5;
}
.slide_wrap.module_type .slide_controls .next.disabled {
    display: none !important;
}
.slide_wrap.module_type .btn_movie_slide_trend {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    width: 48px;
    height: 48px;
    background-image: url("/images/mo/shopping/ico_trend_32_movie.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.slide_wrap.module_type + .slide_trend_lists, .slide_wrap.module_type + .module_type {
    margin-top: 56px;
}
.slide_wrap.module_type .txt_in_slide_trend {
    position: absolute;
    left: 20px;
    bottom: 55px;
}
.slide_wrap.module_type .txt_in_slide_trend em {
    display: inline-block;
    margin: 0 0 8px 0;
    padding: 1px 8px;
    border-radius: 4px;
    letter-spacing: -0.2px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    background-color: rgba(23, 26, 25, 0.8);
}
.slide_wrap.module_type .txt_in_slide_trend span {
    display: block;
    padding-right: 20px;
    letter-spacing: -1px;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.42;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    font-family: "Happiness-Sans" !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 68px;
}
.slide_wrap.module_type:last-of-type {
    margin-bottom: 20px;
}
.slide_wrap.module_type .slide_box {
    display: block;
    border-radius: 16px;
    width: 100%;
    background-image: url("/images/mo/shopping/bg_no_img.png");
    background-position: center;
    background-size: cover;
}
.slide_wrap.module_type .slide_box:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) -100%, rgba(0, 0, 0, 0) 100%);
}

/* 해시태그 */
.list_view_hash {
    display: flex;
    margin: 24px 24px 0;
    column-gap: 16px;
    row-gap: 13px;
    flex-wrap: wrap;
}
.list_view_hash li a {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.list_view_hash li a span {
    margin-right: 3px;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #00ce84;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}

.hashtag_area {
    margin: 24px 8px 0;
}
.hashtag_area .hashtag_list {
    display: flex;
    column-gap: 16px;
    row-gap: 12px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.hashtag_area .hashtag_item::before {
    content: "#";
    margin-right: 2px;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #00b775;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.hashtag_area .hashtag_item > span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}

/* 공유하기 팝업 */
.list_share_ect_gh {
    display: flex;
    margin: 0 -20px;
    padding: 10px 0 32px 0;
    width: calc(100% + 40px);
    justify-content: space-evenly;
}
.list_share_ect_gh li button {
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
.list_share_ect_gh li button img {
    display: block;
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}

.bottom_none_space {
    padding-bottom: 0px !important;
}

.ui_keysearch_wrap.all_list_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    box-sizing: border-box;
}
.ui_keysearch_wrap.all_list_box .top_area {
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 40px;
}
.ui_keysearch_wrap.all_list_box .top_area::-webkit-scrollbar {
    display: none;
}
.ui_keysearch_wrap.all_list_box .top_area > ul {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}
.ui_keysearch_wrap.all_list_box .top_area > ul > li:first-of-type {
    padding-left: 19px;
}
.ui_keysearch_wrap.all_list_box .top_area > ul > li:last-of-type {
    padding-right: 19px;
}
.ui_keysearch_wrap.all_list_box .top_area .string_btn {
    display: flex;
    width: 56px;
    height: 36px;
    border-radius: 18px;
    border: solid 1px #EAEBEA;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}
.ui_keysearch_wrap.all_list_box .top_area .string_btn span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.ui_keysearch_wrap.all_list_box .top_area .string_btn.on {
    border: 1px solid #01A76B;
    background-color: #01A76B;
}
.ui_keysearch_wrap.all_list_box .top_area .string_btn.on span {
    color: #FFFFFF;
}
.ui_keysearch_wrap.all_list_box .conts_area {
    height: calc(100% - 76px);
    padding: 0 24px;
    box-sizing: border-box;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: auto;
}
.ui_keysearch_wrap.all_list_box .conts_area::-webkit-scrollbar {
    display: none;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list {
    display: flex;
    column-gap: 16px;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list > .title {
    position: relative;
    width: auto;
    flex-shrink: 1;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list > .title > div {
    position: sticky;
    display: flex;
    width: 100%;
    height: 45px;
    margin-right: 16px;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list > .title > div span {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "Happiness-Sans";
}
.ui_keysearch_wrap.all_list_box .conts_area > .list > ul {
    width: 100%;
    box-sizing: border-box;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list > ul > li:first-of-type .all_list_item {
    border-color: #171A19;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list .all_list_item {
    display: flex;
    width: 100%;
    height: 45px;
    padding: 0 16px;
    border-top: 1px solid #EAEBEA;
    align-items: center;
    box-sizing: border-box;
}
.ui_keysearch_wrap.all_list_box .conts_area > .list .all_list_item span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}

/**
 * space
 */
._space_left_margin_1 {
    margin-left: 1px !important;
}
._space_left_margin_2 {
    margin-left: 2px !important;
}
._space_left_margin_3 {
    margin-left: 3px !important;
}
._space_left_margin_4 {
    margin-left: 4px !important;
}
._space_left_margin_5 {
    margin-left: 5px !important;
}
._space_left_margin_6 {
    margin-left: 6px !important;
}
._space_left_margin_7 {
    margin-left: 7px !important;
}
._space_left_margin_8 {
    margin-left: 8px !important;
}
._space_left_margin_9 {
    margin-left: 9px !important;
}
._space_left_margin_10 {
    margin-left: 10px !important;
}
._space_left_margin_11 {
    margin-left: 11px !important;
}
._space_left_margin_12 {
    margin-left: 12px !important;
}
._space_left_margin_13 {
    margin-left: 13px !important;
}
._space_left_margin_14 {
    margin-left: 14px !important;
}
._space_left_margin_15 {
    margin-left: 15px !important;
}
._space_left_margin_16 {
    margin-left: 16px !important;
}
._space_left_margin_17 {
    margin-left: 17px !important;
}
._space_left_margin_18 {
    margin-left: 18px !important;
}
._space_left_margin_19 {
    margin-left: 19px !important;
}
._space_left_margin_20 {
    margin-left: 20px !important;
}
._space_left_margin_21 {
    margin-left: 21px !important;
}
._space_left_margin_22 {
    margin-left: 22px !important;
}
._space_left_margin_23 {
    margin-left: 23px !important;
}
._space_top_margin_1 {
    margin-top: 1px !important;
}
._space_top_margin_2 {
    margin-top: 2px !important;
}
._space_top_margin_3 {
    margin-top: 3px !important;
}
._space_top_margin_4 {
    margin-top: 4px !important;
}
._space_top_margin_5 {
    margin-top: 5px !important;
}
._space_top_margin_6 {
    margin-top: 6px !important;
}
._space_top_margin_7 {
    margin-top: 7px !important;
}
._space_top_margin_8 {
    margin-top: 8px !important;
}
._space_top_margin_9 {
    margin-top: 9px !important;
}
._space_top_margin_10 {
    margin-top: 10px !important;
}
._space_top_margin_11 {
    margin-top: 11px !important;
}
._space_top_margin_12 {
    margin-top: 12px !important;
}
._space_top_margin_13 {
    margin-top: 13px !important;
}
._space_top_margin_14 {
    margin-top: 14px !important;
}
._space_top_margin_15 {
    margin-top: 15px !important;
}
._space_top_margin_16 {
    margin-top: 16px !important;
}
._space_top_margin_17 {
    margin-top: 17px !important;
}
._space_top_margin_18 {
    margin-top: 18px !important;
}
._space_top_margin_19 {
    margin-top: 19px !important;
}
._space_top_margin_20 {
    margin-top: 20px !important;
}
._space_top_margin_21 {
    margin-top: 21px !important;
}
._space_top_margin_22 {
    margin-top: 22px !important;
}
._space_top_margin_23 {
    margin-top: 23px !important;
}

/* select btmsheet : content button */
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type {
    width: 100%;
    padding: 16px 20px;
    border: solid 1px #EAEBEA;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text {
    display: flex;
    justify-content: left;
    align-items: center;
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text img {
    width: 64px;
    height: 40px;
    margin-right: 12px;
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text span .sub {
    display: block;
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text .flag_wrap > span {
    font-size: 0.75rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}
div.ui_HdApp_select_btmsheet .card_select .btn_sel_type .ui_select_text .flag_wrap.c01 > span {
    color: #01A76B;
}
div.ui_HdApp_select_btmsheet .card_select.shadow_type .btn_sel_type {
    border: solid 1px transparent;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}
div.ui_HdApp_select_btmsheet.single_item {
    pointer-events: none !important;
}
div.ui_HdApp_select_btmsheet.single_item .btn_sel_type:after {
    content: none !important;
}

/* select btmsheet : popup */
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_tit h1 {
    padding: 0px;
    margin: 0px;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.btm_select_wrap.card_select {
    padding: 0px;
    padding-bottom: 4px;
    width: 100%;
    margin: 0px;
    margin-top: 24px;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.btm_select_wrap.card_select .ui_option_wrap li .ui_btn_option {
    display: flex;
    justify-content: left;
    align-items: center;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.btm_select_wrap.card_select .ui_option_wrap li .ui_btn_option img {
    width: 64px;
    height: 40px;
    margin-right: 12px;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.btm_select_wrap.card_select .ui_option_wrap li .ui_btn_option span {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.btm_select_wrap.card_select .ui_option_wrap li .ui_btn_option span .sub {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.payment_day .ui_option_wrap li {
    position: relative;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.payment_day .ui_option_wrap li .ui_btn_option {
    width: 100%;
    padding: 16px 24px;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.payment_day .ui_option_wrap li .ui_btn_option > em {
    margin-right: 4px;
    font-size: 1rem;
    font-weight: 500;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.payment_day .ui_option_wrap li.on {
    background-color: #F7FAF9;
}
article.lay_pop.bottom_type.lay_pop .lay_wrap .lay_conts.payment_day .ui_option_wrap li.on:after {
    content: "";
    position: absolute;
    top: 50%;
    left: initial;
    bottom: 0;
    right: 24px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_s_check_20_green.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.card_image_shadow_box {
    position: relative;
    width: 100%;
}
.card_image_shadow_box > img {
    position: relative;
}
.card_image_shadow_box::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 10%;
    background-color: transparent;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.1);
    z-index: -1;
}

.barcode_area .barcode_img .barcode_wrap {
    display: flex;
    width: 100% !important;
    font-size: 0px;
    line-height: 0px;
    align-items: center;
    justify-content: center;
}
.barcode_area .barcode_num > p {
    display: block;
    font-size: 0.75rem;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.2px;
}
.barcode_area .barcode_num.is_btn_type {
    display: flex;
    padding-bottom: 8px !important;
    align-items: center;
    justify-content: center;
}
.barcode_area .barcode_num.is_btn_type .btn_wrap .barcode_copy {
    display: block;
    width: auto;
    padding: 2px 11px 3px;
    border-radius: 36px;
    border: solid 1px #d3d3d3;
    box-sizing: border-box;
}
.barcode_area .barcode_num.is_btn_type .btn_wrap .barcode_copy span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
.barcode_area .barcode_num.is_btn_type .btn_wrap + p {
    margin-left: 8px;
}

.accordion_list_box .head_box {
    display: flex;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    column-gap: 12px;
    align-items: center;
    justify-content: flex-start;
}
.accordion_list_box .head_box > p {
    display: flex;
    width: 100%;
    column-gap: 12px;
    align-items: center;
    justify-content: flex-start;
}
.accordion_list_box .head_box > p strong.text {
    width: 100%;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4375;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
.accordion_list_box .head_box > p strong.num {
    width: auto;
    flex-shrink: 0;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_list_box .head_box > i.ico_arrow {
    display: block;
    flex-shrink: 0;
    transition: all 0.25s ease-in;
}
.accordion_list_box .contents_box {
    padding-top: 20px;
    border-top: 1px solid #eaebea;
}
.accordion_list_box .contents_box .list > li + li {
    margin-top: 16px;
}
.accordion_list_box .contents_box .list .item_box {
    display: flex;
    column-gap: 16px;
    align-items: flex-start;
    justify-content: space-between;
}
.accordion_list_box .contents_box .list .item_box > dt {
    width: 100%;
    flex-shrink: 1;
}
.accordion_list_box .contents_box .list .item_box > dt span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_list_box .contents_box .list .item_box > dd {
    width: auto;
    flex-shrink: 0;
}
.accordion_list_box .contents_box .list .item_box > dd strong {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_list_box li.active .head_box .ico_arrow {
    transform: rotate(0deg);
}
.accordion_list_box li.on .head_box .ico_arrow {
    transform: rotate(180deg);
}

.accordion_card_box > ul > li {
    padding: 4px 20px 4px;
    border-radius: 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.accordion_card_box > ul > li + li {
    margin-top: 24px;
}
.accordion_card_box .head_box {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    column-gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
}
.accordion_card_box .head_box > div {
    word-break: keep-all;
}
.accordion_card_box .head_box > div > strong {
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.25px;
}
.accordion_card_box .head_box > .title {
    flex-basis: max-content;
    flex-grow: 1;
}
.accordion_card_box .head_box > .jelly_num {
    position: relative;
    padding-left: 32px;
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
}
.accordion_card_box .head_box > .jelly_num .ico_jelly_red {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.accordion_card_box .head_box > .jelly_num .ico_jelly_blue {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.accordion_card_box .head_box > .icon {
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
}
.accordion_card_box .head_box > .icon .ico_arrow {
    transition: all 0.25s ease-in;
}
.accordion_card_box .contents_box {
    border-top: 1px solid #eaebea;
    padding-top: 20px;
    padding-bottom: 16px;
}
.accordion_card_box .contents_box .con_box > .text > span {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_card_box .contents_box .con_box > .text p {
    display: inherit;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4666666667;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
    word-break: keep-all;
}
.accordion_card_box .contents_box .con_box > .text + .image {
    margin-top: 16px;
}
.accordion_card_box .contents_box .con_box > .check_list > dt span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.accordion_card_box .contents_box .con_box > .check_list > dt + dd {
    margin-top: 8px;
}
.accordion_card_box .contents_box .con_box + .con_box {
    margin-top: 24px;
}
.accordion_card_box li.active .head_box .ico_arrow {
    transform: rotate(0deg);
}
.accordion_card_box li.on .head_box .ico_arrow {
    transform: rotate(180deg);
}

.ico_rank_up {
    display: inline-flex;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/images/mobilehome2/mypage/new/ico_rankup_20.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -1px;
}
.ico_rank_down {
    display: inline-flex;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/images/mobilehome2/mypage/new/ico_rankdown_20.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.ico_arrow {
    display: inline-flex;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/images/mobilehome2/mypage/new/ico_arrow_down_20_black.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.ico_jelly_red {
    display: inline-flex;
    width: 32px;
    height: 32px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/images/mo/benefit/img_jelly_red.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.ico_jelly_blue {
    display: inline-flex;
    width: 32px;
    height: 32px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-image: url("/images/mo/benefit/img_jelly_blue.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

span.point_red {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: 700;
    color: #eb3912 !important;
}

span.point_black {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: 700;
    color: #171a19 !important;
}

.module_frame {
    position: relative;
}
.module_frame_header {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 7.317%;
    padding-left: 7.317%;
    padding-right: 9.756%;
    box-sizing: border-box;
    z-index: 2;
}
.module_frame_header .title {
    display: inline-block;
    font-size: 1.625rem;
    font-weight: 700;
    color: white;
    line-height: 1.4615384615;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -1px;
    font-family: "HappinessSansVF";
}
.module_frame_contents {
    position: relative;
    z-index: 1;
}
.module_frame_contents .img_wrap {
    padding-left: 3.658%;
    padding-right: 3.658%;
    padding-top: 19.207%;
    padding-bottom: 7.317%;
}
.module_frame_contents .img_wrap::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/mobilehome2/new/module_home_shoppingnews_01.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 2;
}
.module_frame_contents .img_wrap .img {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    bottom: -0.5px;
}
.module_frame_contents .img_wrap .img::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47.368%;
    width: 100%;
    background: linear-gradient(to bottom, rgb(0, 22, 10) 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.6;
    z-index: 1;
}
.module_frame_contents .img_wrap .img::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 47.368%;
    width: 100%;
    background: linear-gradient(to bottom, rgb(0, 22, 10) 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.6;
    z-index: 1;
    transform: rotateX(180deg);
}
.module_frame_contents .img_wrap .img > img {
    position: absolute;
    width: 100%;
}
.module_frame._case01 .img_wrap::before {
    background-image: url("/images/mobilehome2/new/module_home_shoppingnews_01.png");
}
.module_frame._case02 .img_wrap::before {
    background-image: url("/images/mobilehome2/new/module_home_shoppingnews_02.png");
}
.module_frame._case03 .img_wrap::before {
    background-image: url("/images/mobilehome2/new/module_home_shoppingnews_03.png");
}
.module_frame._case04 .img_wrap::before {
    background-image: url("/images/mobilehome2/new/module_home_shoppingnews_04.png");
}

.detail_box {
    padding-top: 32px;
    padding-right: 8px;
    padding-left: 8px;
}
.detail_box .detail_list > li + li {
    margin-top: 24px;
}
.detail_box .detail_item > dt span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.detail_box .detail_item > dt + dd {
    margin-top: 8px;
}
.detail_box .detail_item > dd span {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
    letter-spacing: -0.4px;
}
.detail_box .detail_item > dd + dd {
    margin-top: 4px;
}
.detail_box .detail_item.bullet > dd {
    position: relative;
    padding-left: 10px;
}
.detail_box .detail_item.bullet > dd:before {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #171A19;
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    bottom: 0;
    right: 0;
}

/* GNB 정의 */
header#head {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    /* 메인일때 */
    /* 서브 gnb 일때 */
    /* //서브 gnb 일때 */
}
header#head .header_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin: 0 auto;
}
header#head .header_wrap > div {
    display: inline-flex;
    align-items: center;
    /* 버튼 노출 옵션 */
}
header#head .header_wrap > div + div {
    padding-right: 16px;
}
header#head .header_wrap > div .logo {
    position: relative;
    margin-left: 20px;
}
header#head .header_wrap > div .logo h1 {
    display: inline-block;
    font-size: 1rem;
}
header#head .header_wrap > div .logo h1 a {
    display: block;
    width: 100%;
    height: 100%;
}
header#head .header_wrap > div .logo h1 a span {
    font-size: 1.25rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
header#head .header_wrap > div .logo > button {
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/btn_gnb_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .logo > button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .logo.on button {
    transform: rotate(-180deg);
}
header#head .header_wrap > div .logo.on .brand_gate {
    visibility: visible;
    opacity: 1;
    height: 105px;
    transition: all 0.3s ease-in-out;
}
header#head .header_wrap > div .tit_link {
    display: none;
}
header#head .header_wrap > div .brand_search {
    position: relative;
}
header#head .header_wrap > div .brand_search a {
    width: 32px;
    height: 32px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
header#head .header_wrap > div .brand_search a:after {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_search_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .brand_search a span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .brand_search .search_wrap {
    display: none;
}
header#head .header_wrap > div .notice {
    margin-left: 12px;
}
header#head .header_wrap > div .notice em {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .notice em span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .notice em.alam {
    display: none;
}
header#head .header_wrap > div .notice.on em {
    display: none;
}
header#head .header_wrap > div .notice.on em.alam {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_on_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .home {
    margin-left: 12px;
}
header#head .header_wrap > div .home a > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .home.on {
    display: block;
}
header#head .header_wrap > div .home.on::before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_home_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .new_all_menu {
    margin-left: 12px;
}
header#head .header_wrap > div .new_all_menu h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .new_all_menu > button {
    display: block;
    width: 32px;
    height: 32px;
}
header#head .header_wrap > div .new_all_menu > button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head .header_wrap > div .new_all_menu > button:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_menu_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div .new_all_menu .menu_wrap {
    display: none;
}
header#head .header_wrap > div .grade_state {
    display: none;
}
header#head .header_wrap > div .grade_state > span,
header#head .header_wrap > div .grade_state a {
    font-size: 0.875rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
header#head .header_wrap > div .grade_state.vip {
    display: block;
}
header#head .header_wrap > div .grade_state.vip::before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_vip_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head .header_wrap > div > div.none {
    display: none;
}
header#head .header_wrap > div .search_area {
    display: none;
}
header#head.main .logo {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head.sub .header_wrap > div {
    position: relative;
}
header#head.sub .header_wrap > div .logo {
    display: none;
}
header#head.sub .header_wrap > div .tit_link {
    display: block;
    height: 24px;
    margin-left: 16px;
}
header#head.sub .header_wrap > div .tit_link h2 {
    position: absolute;
    top: 12px;
    left: 48px;
    display: block;
    width: 235px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 24px;
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
header#head.sub .header_wrap > div .tit_link a {
    display: inline-block;
    margin-top: 0;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_arrow_left_24_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.sub .header_wrap > div .tit_link a span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

/* //GNB 정의 */
/* GNB theme BLACK */
header#head.theme_black {
    background-color: #171A19;
}
header#head.theme_black .header_wrap > div .logo h1 a span {
    color: #FFFFFF;
}
header#head.theme_black .header_wrap > div .tit_link {
    margin-top: 0;
}
header#head.theme_black .header_wrap > div .tit_link h2 {
    font-size: 1rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
header#head.theme_black .header_wrap > div .tit_link a {
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_arrow_left_24_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_black .header_wrap > div .brand_search a:after {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_search_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_black .header_wrap > div .notice em {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_black .header_wrap > div .notice.on em.alam {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_on_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_black .header_wrap > div .new_all_menu > button:before {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_menu_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/* GNB theme Transparent */
header#head.theme_trans {
    background-color: transparent;
}
header#head.theme_trans .header_wrap > div .logo h1 a span {
    color: #FFFFFF;
}
header#head.theme_trans .header_wrap > div .tit_link h2 {
    font-size: 1rem;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
header#head.theme_trans .header_wrap > div .tit_link a {
    margin-top: 0;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_arrow_left_24_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_trans .header_wrap > div .brand_search a:after {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_search_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_trans .header_wrap > div .notice em {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_trans .header_wrap > div .notice.on em.alam {
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_notice_on_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.theme_trans .header_wrap > div .new_all_menu {
    background-color: transparent;
}
header#head.theme_trans .header_wrap > div .new_all_menu > button:before {
    margin-top: 0;
    width: 32px;
    height: 32px;
    background-image: url("/images/mobilehome2/new/ico_menu_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/* GNB Search */
header#head.search .header_wrap {
    justify-content: flex-start;
}
header#head.search .header_wrap > div {
    position: relative;
}
header#head.search .header_wrap > div:last-child {
    width: 100%;
    padding-left: 8px;
}
header#head.search .header_wrap > div .logo {
    display: none;
}
header#head.search .header_wrap > div .tit_link {
    display: block;
    height: 24px;
    margin-left: 16px;
}
header#head.search .header_wrap > div .tit_link h2 {
    display: none;
}
header#head.search .header_wrap > div .tit_link a {
    display: inline-block;
    margin-top: 0;
    width: 24px;
    height: 24px;
    background-image: url("/images/mobilehome2/new/ico_arrow_left_24_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
header#head.search .header_wrap > div .tit_link a span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
header#head.search .header_wrap > div .search_area {
    position: fixed;
    display: block;
    top: 4px;
    left: 48px;
    right: 16px;
}
header#head.search .header_wrap > div .search_area .search_type > input {
    height: 40px;
    border: 1px solid #F7FAF9;
    background-color: #F7FAF9;
}
header#head.search .header_wrap > div .search_area .search_type .search {
    top: 8px;
}
header#head.search .header_wrap > div .search_area .search_type .del_input {
    top: 10px;
}
header#head.search .header_wrap > div .search_area .search_type .list_open_down {
    display: none;
}
header#head.search .header_wrap > div .search_area .search_type.on .list_open_down {
    position: relative;
    z-index: 50;
    display: block;
}
header#head.search .header_wrap > div .search_area .search_type.on:before {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    background-color: #fff;
}
header#head.search .header_wrap > div .search_area .search_type.on > input {
    border: 1px solid #F7FAF9;
    background-color: #FFFFFF;
}
header#head.search .header_wrap > div .search_area .search_type.on > input:checked,
header#head.search .header_wrap > div .search_area .search_type.on > input:focus {
    border: none;
}
header#head.search .header_wrap > div .search_area .search_type.auto + .auto_transform {
    display: block;
}
header#head.search .header_wrap > div .search_area .auto_transform {
    display: none;
}

/* AS-IS 충돌 해결 */
a[href*=modal] {
    width: auto !important;
    height: auto !important;
    border: none !important;
    text-align: left !important;
}

/* button */
.btn_wrap button.sbtn,
.btn_wrap a.sbtn {
    min-width: initial;
    height: auto;
}
.btn_wrap button.lbtn,
.btn_wrap a.lbtn {
    border: 2px solid #171A19 !important;
}

.btn_sel_type:after {
    content: "";
    position: absolute;
    top: 50%;
    left: initial;
    bottom: 0;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("/images/mobilehome2/new/ico_dropdown_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/* layer */
.lay_pop .lay_wrap .lay_tit h1 {
    padding-bottom: 0;
    line-height: 1.5;
    font-size: revert;
    font-size: 1.125rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}

/* gnb */
#head.sub .header_wrap > div .tit_link {
    margin-top: 0;
}
#head.sub .header_wrap > div .tit_link a {
    margin-top: 0;
}
#head.sub .header_wrap > div .new_all_menu > button:before {
    margin-top: 0;
}
#head em, #head strong {
    font-style: normal;
    font-size: inherit !important;
    font-weight: 700;
    color: inherit !important;
    line-height: 1.5;
    letter-spacing: -0.4px !important;
    font-family: "Happiness-Sans";
}

.hidden, legend, caption, hr, .blind, .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

fieldset {
    border: none;
}

caption {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    margin-top: 0 !important;
    position: static !important;
}

a {
    -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline-color: transparent;
}
a:hover, a:focus {
    text-decoration: none;
    color: transparent;
}

img {
    width: 100%;
    vertical-align: top;
}

/* 스킵네비게이션 */
nav.skip a {
    position: absolute;
    top: -100px;
    width: 100%;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.3;
    letter-spacing: -0.4px !important;
    font-family: "Pretendard" !important;
}
nav.skip a:focus, nav.skip a:active {
    top: 0;
}

/* //스킵네비게이션 */