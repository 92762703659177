@charset "UTF-8";


// 숨김 스타일(웹접근성)
@mixin hidden {
	position: absolute; //레이아웃에 영향을 주지 않게 해당 아이템 영역을 없앰
	width: 1px; //width, height값을 최소한의 크기로 조절.
	height: 1px;
	margin: -1px; //화면상 아예 안나오게.
	overflow: hidden; //overflow된 콘텐츠를 숨김
	clip-path: polygon(0 0, 0 0, 0 0); //클리핑 범위를 모두 0으로 지정해서 요소를 숨김
}



//폰트 스타일 지정 (크기,볼륨,색상,줄높이)
@mixin font($size, $weight, $color, $height) {
	font-size:$size;
	font-weight:$weight;
	color:$color;
	line-height:$height;
	letter-spacing: -0.4px !important;
	font-family: 'Happiness-Sans';
	@content;
}

@mixin tfont($size, $weight, $color, $height) {
	font-size:$size;
	font-weight:$weight;
	color:$color;
	line-height:$height;
	letter-spacing: -1px;
	font-family: 'Happiness-Sans-Title';
	@content;
}

@mixin font_pre($size, $weight, $color, $height) {
	font-size:$size;
	font-weight:$weight;
	color:$color;
	line-height:$height;
	letter-spacing: -0.4px !important;
	font-family: 'Pretendard' !important;
	@content;
}

// @include font_sum(크기, 굵기, 색상, 간격, 자간);
@mixin font_sum($s: 14, $w: 400, $c: $black, $h: 21, $l: -.4px, $box: inline-block ) {
	display: $box;
	@include font_pre(((calc(100 / 16) * $s) * 0.01) + rem, $w, $c, (calc($h / $s))) {
		letter-spacing: $l;
	};
	@content;
}


// 미디어 쿼리
@mixin media-query($break-point) {
	@if $break-point == pc {
		@media (min-width: 1024px) {
			@content;
		}
	}
	@if $break-point == mo {
		@media (max-width: 760px) {
			@content;
		}
	}
	@if $break-point == tablet {
		@media screen and (min-width: 761px) and (max-width: 1023px){
			@content;
		}
	}
}


// 말줄임
@mixin ellipsis($line, $height) {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	max-height:$height;
	@content;
}

//박스 중앙정렬
@mixin el_center {
	display: flex;
	justify-content: center;
	align-items: center;
	@content;
}


// 삼각형(말풍선)
@mixin arrow($pos, $size, $color) {
	width: 0;
	height: 0;
	@if $pos == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}
	@if $pos == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	}
	@if $pos == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
	@if $pos == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}
}


@mixin bg($width,$height,$url) {
	width:$width;
	height:$height;
	background-image: url($url);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	@content;
}


//가상선택자(레이어,블록)
@mixin sudo_pos($top:initial, $right:initial, $bottom:initial, $left:initial) {
	content:'';
	position:absolute;
	top:$top;
	left:$left;
	bottom:$bottom;
	right:$right;
	@content;
}

@mixin sudo_dis($mt,$mr,$mb,$ml) {
	content:'';
	display:inline-block;
	margin-top:$mt;
	margin-right:$mr;
	margin-bottom:$mb;
	margin-left:$ml;
	vertical-align: top;
	@content;
}


// X-스크롤바 비노출(동작O)
@mixin sc_hidden {
	overflow-x:scroll;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}


// 간격용
@mixin m_vertical($m: 8) {
	margin-left: $m * 1px;
	margin-right: $m * 1px;
}