@charset "UTF-8";

/* 레이아웃 */
#contents {
	width:100%;
	min-height:380px; //최소 높이
	box-sizing: border-box;
	&.no_footer {
		padding-bottom:80px;
	}
	.conts {
		margin-top:50px; //헤더만큼
		padding-left:16px;
		padding-right:16px;
		padding-bottom:80px; //230817_추가(단테 오류사항)
		.box_wrap {
			& + .box_wrap {
				margin-top:40px; //48px에서 변경. 공통인지 확인 필요 (0531)
			}
			&.fixed {
				position:fixed;
				// left:16px;
				// right:16px;
				left:0;
				right:0;
				bottom:0;
				margin-left:0;
				margin-right:0;
				// padding-left:16px;
				// padding-right:16px;
				background-color:$white;
				box-sizing:border-box;
				z-index:10;
				&.wbox {
					bottom:-8px;
					box-shadow: 0 -4px 27px 0 rgba(0, 0, 0, 0.12);
				}
				&.btm_btn {
					padding-top:20px; //230926_상단여백이 없어서 추가(ASV-B01-003), 다른페이지 문세 지 별고 지정 필요(service에)
					padding-left:16px;
					padding-right:16px;
					.btn_wrap {
						margin-left:0;
						margin-right:0;
					}
				}
			}
			&.wbox {
				padding-left:16px;
				padding-right:16px;
				background-color:$white;
				&.fixed {
					left:0;
					right:0;
					z-index: 5; // 20231030_[MCA1.1.3.11.4 tab에 덮어지는 현상으로 수정]
				}
			}
			&.btm_btn {
				padding-left:0;
				padding-right:0;
				.btn_wrap {
					margin-left:0;
					margin-right:0;
				}
			}
			// .btn_wrap {
			// 	margin-left:16px;
			// 	margin-right:16px;
			// }
		}

		.tab_wrap { //탭일때는 양사이드 간격이 달라 별도 하단버튼 간격 정의해줘야 함.
			.tab_conts {
				.box_wrap {
					&.btm_btn {
						padding-left:0;
						padding-right:0;
						.btn_wrap {
							margin-left:16px;
							margin-right:16px;
						}
					}
				}
			}
		}
	}
}
/* //레이아웃 */

/* 쉐도우박스(일반) */
.wbox {
	margin:8px 0; //그림자간격
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  	background-color:$white;
	& + .wbox {
		margin-top:16px;
	}
}
.btm_btn {
	margin-top:32px;
	padding-left:16px;
	padding-right:16px;
	padding-bottom:20px;
	&.wbox {
		box-shadow:none;
		.btn_wrap {
			margin-left:0 !important;
			margin-right:0 !important;
		}
		&.fixed {
			margin-top:48px !important;
			// margin-left:-16px;
			// margin-right:-16px;
			margin-left:0;
			margin-right:0;
			box-shadow: 0 -4px 27px 0 rgba(0, 0, 0, 0.12);
		}
		.desc {
			padding:16px 0;
			text-align:center;
		}
		.total_price {
			padding:16px 8px;
			dl {
				display:flex;
				justify-content:space-between;
				align-items:center;
				dt {
					@include font_pre(1rem,700,$black,1.5);
				}
				dd {
					@include font_pre(1rem,700,$green02,1.5);
				}
			}
		}
	}
}


/* 결과 없는 경우 */
// .no_result {
// 	padding:60px 0 40px;
// 	text-align:center;
// 	.ico {
// 		&:before {
// 			width:80px;
// 			height:80px;
// 			background-color:#ddd;
// 			@include bg(80px,80px,'/images/mobilehome2/new/bg_no_card_80_event.png');
// 			@include sudo_dis(0,auto,16px,auto) {
// 				display:block;
// 			};
// 		}
// 		.desc {
// 			display:block;
// 			margin-top:8px;
// 			color:$gray02;
// 		}
// 	}
// }
.no_result {
	&::before {
		@include bg(88px,88px,'/images/mobilehome2/new/ico_no_result_88.png');
		@include sudo_dis(0,auto,8px,auto) {
			display:block;
		};
	}
	.comment {
		text-align:center;
		@include font_pre(1rem,700,$black,1.4);
	}
	.add_comment {
		margin-top:4px;
		@include font_pre(0.875rem,400,$black,1.4);
		&.center {
			text-align:center;
		}
	}
	&.list_type { //리스트타입(소형)
		&::before {
			@include bg(48px,48px,'/images/mobilehome2/new/ico_no_result_88.png');
			@include sudo_dis(0,auto,12px,auto) {
				display:block;
			}
		}
		.comment {
			text-align:center;
			@include font_pre(0.937rem,700,$black,1.5);
		}
	}
	&.top_line {
		margin-top: 0px !important;
		padding-top: 80px;
		border-top: 8px solid #F7F7F7;
	}
	// 버튼이 있을 시
	.btn_wrap {
		margin-top: 24px;
		.btn.border.inline {
			width: auto;
			padding-right: 15px; // border
			padding-left: 15px;
			border-width:2px; //231005_통테수정 라인굵이 변경(AMY-E01-002T)
		}
	}
}


/* 하단 공통 공지(참고사항) */
div.btm_notice_line { //상단 구분라인 있는 경우
	margin-top:48px;
	// padding-bottom:80px; //AHC-B01-003 추가, 230817_conts에 동일 패딩 추가로 인해 삭제

	&:before {
		@include sudo_dis(0,-16px,24px,-16px){
			display:block;
			width:auto;
			height:8px;
			background-color: $gray05; // 20231018_[UIUX검수: 주차정산수정건반영]
		}
	}
	.bullet_list {
		&.dot {
			li,p {
				color:$gray02;
			}
		}
	}
	.ui_accordion {
		margin-top:-24px;
		> ul {
			> li {
				border-bottom:none;
				.head {
					position: relative;
					padding: 16px 0px !important;
					background-color: $white !important;
					> div {
						@include font_pre(((calc(100 / 16) * 15) * 0.01) + rem, 500, $black, (calc(22 / 15)));
					}
					.ui_accord_toggle {
						display: block;
						position: absolute;
						top: 16px;
						right: -4px; // 공통 적용
						padding:0;
						> span {
							@include hidden;
						}
						@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_black.png') {
							transform:rotate(-180deg);
						}
					}
				}
				&.active {
					.head {
						.ui_accord_toggle {
							top:18px;
							transform:rotate(0);
						}
					}
				}
				.ui_accord_content {
					padding:16px 0px !important;
					background-color:$white !important;
					border-top: 1px solid #EAEBEA;
					// border-bottom: 1px solid #EAEBEA; // 공통 내용 반영
					dl {
						dt {
							@include font_sum (
								$w: 700,
								$box: block
							);
							& + dd {
								margin-top:8px;
							}
							&.sub_title01 {
								@include font_sum (
									$w: 700,
								);
							}
						}
						dd {
							& + dt {
								margin-top: 12px;
							}
						}
						& + dl,
						& + .bullet_list {
							margin-top:24px;
						}
					}
					.bullet_list {
						& + dl,
						& + .bullet_list {
							margin-top:24px;
						}
					}
					pre {
						margin-top: 12px;
						white-space:pre-wrap;
						@include font_pre(0.875rem,400,$gray02,1.5);
					}
				}
			}
		}
	}
	// 20231017_[UX/UI검수 수정: 컨텐츠 padding 이 일경우 옆 간격이 24가 되게끔 아코디언 에 간격]
	&._space16_type {
		.ui_accordion {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}


/* 박스 상단 간격 전용 클래스(수정용) */
.cnt32 {
	margin-top:32px !important;
}
.cnt40 {
	margin-top:40px !important;
}
.cnt80 {
	margin-top:80px !important;
}
.mt12 {
	margin-top:12px !important;
}
.mt24 {
	margin-top:24px !important;
}

/* position 속성 전용 */
.pos_rel {
	position:relative;
}

/* 양쪽 간격 24px(기본 16px일때) 경우 */
.inner_pd {
	padding-left:8px;
	padding-right:8px;
}
.basic_inner_pd {
	padding-left:16px;
	padding-right:16px;
}