@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";



/* 플래그 정의 */
.flag_wrap {
	display:inline-block;
	padding:0 6px;
	border-radius: 4px;
	border:1px solid $black;
	background-color:$black;
	line-height:100%;
	span {
		// @include font_pre(0.75rem,500,$white,1.5);
		@include font_pre(12px,500,$white,1.5);
		font-style: normal;
	}

	&.border {
		background-color:$white;
		span {
			color:$black;
		}
	}

	&.opa08 {
		background-color: rgb(23 26 25 / 80%);
		border-color: transparent;
	}

	&.white {
		&.border {
			border-color: $white;
			background-color: transparent;
			span {
				color: $white;
				background-color: transparent;
			}
		}
	}
	/* 강조형(컬러타입) */
	&.c01 {
		color:$white;
		border-color:$green02;
		background-color:$green02; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:$green02;
			}
		}
	}
	&.c02 {
		color:$white;
		border-color:$blue;
		background-color:$blue; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:$blue;
			}
		}
	}
	&.c03 {
		color:$white;
		border-color:$rred;
		background-color:$rred; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:$rred;
			}
		}
	}
	&.c04 {
		color:$white;
		border-color:$yellow;
		background-color:$yellow; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:$yellow;
			}
		}
	}
	&.c05 {
		color:$white;
		border-color:#6889ff;
		background-color:#6889ff; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#6889ff;
			}
		}
	}
	&.c06 {
		color:$white;
		border-color:#ff60d2;
		background-color:#ff60d2; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#ff60d2;
			}
		}
	}
	&.c07 {
		color:$white;
		border-color:$pred;
		background-color:$pred; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:$pred;
			}
		}
	}
	&.c08 {
		color:$white;
		border-color:#7f7f7f;
		background-color:#7f7f7f; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#7f7f7f;
			}
		}
	}
	&.c09 {
		color:$white;
		border-color:#f07191;
		background-color:#f07191; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#f07191;
			}
		}
	}
	&.c10 {
		color:$white;
		border-color:#2ABBB6;
		background-color:#2ABBB6; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#2ABBB6;
			}
		}
	}
	&.c11 {
		color:$white;
		border-color:#2e2c8b;
		background-color:#2e2c8b; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#2e2c8b;
			}
		}
	}
	&.c12 {
		color:$white;
		border-color:#5B74BD;
		background-color:#5B74BD; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#5B74BD;
			}
		}
	}
	&.c13 {
		color:$white;
		border-color:#ffa800;
		background-color:#ffa800; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#ffa800;
			}
		}
	}
	&.c14 {
		color:$white;
		border-color:#727573;
		background-color:#727573; //변경 가능
		&.border {
			background-color:$white;
			span {
				color:#727573;
			}
		}
	}

	&.c15 {
		border-color:#EAEBEA;
		background-color:#EAEBEA; //변경 가능
		> span {
			color:$black;
		}
		&.border {
			background-color:$white;
			span {
				color:$black;
			}
		}
	}

	&.benefit { //혜택용 플래그
		display:inline-block;
		padding:0 8px;
		border-radius: 4px;
		border:1px solid $black;
		background-color:$black;
		line-height:100%;
		span {
			@include font_pre(0.75rem,400,$white,1.5);
		}
	}
	/* 등급 : 말꼬리 이미지 형태 */
	$_dir : '/images/mobilehome2/new/';
	&.bubblee {
		$_h : 26px;
		// 기본
		& {
			padding: 0px;
			border: 0px;
			box-sizing: border-box;
			background-color: transparent;
			span { @include hidden; }
		}
		// 회원등급
		&.yp { @include bg(61px, $_h, $_dir + '/flag_bubblee_rank_clubyp.png'); }
		&.jasmin { @include bg(102px, $_h, $_dir + '/flag_bubblee_rank_jasmin.png'); }
		&.sage { @include bg(54px, $_h, $_dir + '/flag_bubblee_rank_sage.png'); }
		&.green { @include bg(60px, $_h, $_dir + '/flag_bubblee_rank_green.png'); }
		&.bestuser { @include bg(57px, $_h, $_dir + '/flag_bubblee_rank_bestuser.png'); }
		&.season { @include bg(63px, $_h, $_dir + 'flag_bubblee_season.png'); }
		// green 지점
		&.green_210 { @include bg(118px, $_h, $_dir + '/flag_bubblee_rank_green_210.png'); }
		&.green_220 { @include bg(118px, $_h, $_dir + '/flag_bubblee_rank_green_220.png'); }
		&.green_240 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_240.png'); }
		&.green_250 { @include bg(118px, $_h, $_dir + '/flag_bubblee_rank_green_250.png'); }
		&.green_260 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_260.png'); }
		&.green_270 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_270.png'); }
		&.green_290 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_290.png'); }
		&.green_400 { @include bg(118px, $_h, $_dir + '/flag_bubblee_rank_green_400.png'); }
		&.green_410 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_410.png'); }
		&.green_420 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_420.png'); }
		&.green_430 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_430.png'); }
		&.green_450 { @include bg(107px, $_h, $_dir + '/flag_bubblee_rank_green_450.png'); }
		&.green_460 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_460.png'); }
		&.green_470 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_470.png'); }
		&.green_480 { @include bg(97px, $_h, $_dir + '/flag_bubblee_rank_green_480.png'); }
		&.green_490 { @include bg(118px, $_h, $_dir + '/flag_bubblee_rank_green_490.png'); }
	}
	/* 등급 : 기본 이미지 형태 */
	&.img {
		$_h : 20px;
		// 기본
		& {
			padding: 0px;
			border: 0px;
			box-sizing: border-box;
			background-color: transparent;
			span { @include hidden; }
		}
		// 회원등급
		&.yp { @include bg(61px, $_h, $_dir + '/flag_rank_clubyp.png'); }
		&.jasmin { @include bg(102px, $_h, $_dir + '/flag_rank_jasmin.png'); }
		&.sage { @include bg(54px, $_h, $_dir + '/flag_rank_sage.png'); }
		&.green { @include bg(60px, $_h, $_dir + '/flag_rank_green.png'); }
		&.bestuser { @include bg(57px, $_h, $_dir + '/flag_rank_bestuser.png'); }
		&.season { @include bg(63px, $_h, $_dir + '/flag_season.png'); }
		// green 지점
		&.green_210 { @include bg(118px, $_h, $_dir + '/flag_rank_green_210.png'); }
		&.green_220 { @include bg(118px, $_h, $_dir + '/flag_rank_green_220.png'); }
		&.green_240 { @include bg(97px, $_h, $_dir + '/flag_rank_green_240.png'); }
		&.green_250 { @include bg(118px, $_h, $_dir + '/flag_rank_green_250.png'); }
		&.green_260 { @include bg(97px, $_h, $_dir + '/flag_rank_green_260.png'); }
		&.green_270 { @include bg(97px, $_h, $_dir + '/flag_rank_green_270.png'); }
		&.green_290 { @include bg(97px, $_h, $_dir + '/flag_rank_green_290.png'); }
		&.green_400 { @include bg(118px, $_h, $_dir + '/flag_rank_green_400.png'); }
		&.green_410 { @include bg(97px, $_h, $_dir + '/flag_rank_green_410.png'); }
		&.green_420 { @include bg(97px, $_h, $_dir + '/flag_rank_green_420.png'); }
		&.green_430 { @include bg(97px, $_h, $_dir + '/flag_rank_green_430.png'); }
		&.green_450 { @include bg(107px, $_h, $_dir + '/flag_rank_green_450.png'); }
		&.green_460 { @include bg(97px, $_h, $_dir + '/flag_rank_green_460.png'); }
		&.green_470 { @include bg(97px, $_h, $_dir + '/flag_rank_green_470.png'); }
		&.green_480 { @include bg(97px, $_h, $_dir + '/flag_rank_green_480.png'); }
		&.green_490 { @include bg(118px, $_h, $_dir + '/flag_rank_green_490.png'); }
	}

}
/* //플래그 정의 */

/* 플래그 정의 - 말꼬리 타입 */
.flag_bubblee_wrap {
	$_dir : '/images/mobilehome2/new/';

	display: inline-block;
	padding-bottom: 7px; // 20231024_[하단길이 7]
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: auto 8.1px ;
	background-image: url( $_dir + 'flag_edge.png');
	.flag_bubblee {
		display: flex;
		height: 20px;
		padding: 0 8px;
		border-radius: 4px 4px 4px 0;
		align-items: center;
		justify-content: center;
		background-color: $black;
		span {
			@include font_sum(12, 500, white !important, 18, -.2px);
		}
	}
	&.c01 {
		background-image: url( $_dir + 'flag_edge_c01.png');
		.flag_bubblee {
			background-color: #01A76B;
		}
	}
	&.c02 {
		background-image: url( $_dir + 'flag_edge_c02.png');
		.flag_bubblee {
			background-color: #3A5FFF;
		}
	}
	&.c03 {
		background-image: url( $_dir + 'flag_edge_c03.png');
		.flag_bubblee {
			background-color: #F07191;
		}
	}
	&.c05 {
		background-image: url( $_dir + 'flag_edge_c05.png');
		.flag_bubblee {
			background-color: #7F7F7F;
		}
	}
	&.c06 {
		background-image: url( $_dir + 'flag_edge_c06.png');
		.flag_bubblee {
			background-color: #2ABBB6;
		}
	}
	&.c07 {
		background-image: url( $_dir + 'flag_edge_c07.png');
		.flag_bubblee {
			background-color: #302E92;
		}
	}
	&.c08 {
		background-image: url( $_dir + 'flag_edge_c08.png');
		.flag_bubblee {
			background-color: #5B74BD;
		}
	}
}
