@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* AS-IS 충돌 해결 */

a[href*="modal"] {
	width:auto !important;
	height:auto !important;
	border:none !important;
	text-align:left !important;
}

/* button */
.btn_wrap {
	button,
	a {
		&.sbtn {
			min-width:initial;
			height:auto;
		}
		&.lbtn {
			border:2px solid $black !important;
		}
	}

}
.btn_sel_type {
	&:after {
		@include sudo_pos(50%,20px,0,initial){
			transform:translateY(-50%);
		};
		@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_black.png');
	}
}

/* layer */
.lay_pop {
	.lay_wrap {
		.lay_tit {
			h1 {
				padding-bottom:0;
				line-height:1.5;
				font-size:revert;
				@include font_pre(1.125rem,700,$black,1.4);
			}
		}
	}
}

/* gnb */
#head {
	&.sub {
		.header_wrap {
			> div {
				.tit_link {
					margin-top:0;
					a {
						margin-top:0;
					}
				}
				.new_all_menu {
					> button {
						&:before {
							margin-top:0;
						}
					}
				}
			}
		}
	}
	em, strong {
		font-style:normal;
		@include font(inherit !important,700,inherit !important,1.5);
	}

}

