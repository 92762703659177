@charset "UTF-8";


* {
	padding:0;
	margin:0;
	list-style: none;
	font-family: 'Happiness-Sans', 'Noto Sans', Arial, dotum, 'sans-serif';
	-webkit-text-size-adjust: none;
	text-decoration:none;
}


@import "_font.scss"; // 폰트
@import "_var.scss"; // 변수
@import "_mixins.scss"; // 믹스인
@import "_tab.scss"; // 탭
@import "_carousel.scss"; // 캐러셀
@import "_layer.scss"; // 레이어팝업
@import "_layout.scss"; // 레이아웃
@import "_bullet.scss"; // 블렛
@import "_button.scss"; // 버튼
@import "_form.scss"; // 폼
@import "_flag.scss"; // 플래그
@import "_table.scss"; // 테이블
@import "_accodian.scss"; //아코디언
@import "_calendar.scss"; // 달력
@import "_module.scss"; // 공통모듈
@import "_gnb.scss"; // GNB (안쓰긴하지만 레이아웃 오류 방지를 위해)
@import "_base_new.scss"; //AS-IS공통 충돌방지
// @import "_floating.scss"; // 플로팅 컨텐츠
// @import "_paging.scss"; // 페이징
// @import "_menu.scss"; // 메뉴들(전체메뉴)


.hidden, legend, caption, hr, .blind, .hide {
	@include hidden;
}
fieldset {
	border:none;
}

caption {
	visibility:hidden;
	overflow: hidden;
	width:0;
	height:0;
	font-size:0;
	line-height:0;
	margin-top:0 !important;
	position:static !important;
}

a {
	&:hover,
	&:focus {
		text-decoration: none;
		color:transparent;
	}
	-webkit-tab-highlight-color:rgba(0,0,0,0);
	-webkit-tap-highlight-color:transparent;
	outline-color: transparent;
}

img {
	width:100%;
	vertical-align:top;
}


/* 스킵네비게이션 */
nav {
	&.skip {
		a {
			position:absolute;
			top:-100px;
			width:100%;
			// background-color:$gray01;
			padding:5px;
			text-align:center;
			@include font_pre(14px,700,$white,1.3);
			&:focus, &:active {
				top:0;
			}
		}
	}
}
/* //스킵네비게이션 */