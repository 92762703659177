@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* GNB 정의 */
header#head {
	position:fixed;
	z-index:50;
	top:0;
	left:0;
	right:0;
	background-color:$white;
	.header_wrap {
		display:flex;
		justify-content:space-between;
		width:100%;
		height:50px;
		margin:0 auto;
		> div {
			display:inline-flex;
			align-items: center;
			& + div {
				padding-right:16px; //우측여백
			}
			.logo {
				position:relative;
				margin-left:20px;
				h1 {
					display:inline-block;
					font-size:1rem;
					a {
						display:block;
						width:100%;
						height:100%;
						span {
							@include font_pre(1.25rem,700,$black,1.4);
						}
					}
				}
				> button {
					display:inline-block;
					vertical-align: top;
					margin-top:-2px;
					margin-left:8px;
					@include bg(20px,20px,'/images/mobilehome2/new/btn_gnb_20.png');
					span {
						@include hidden;
					}
				}
				&.on {
					button {
						transform: rotate(-180deg);
					}
					.brand_gate {
						visibility: visible;
						opacity: 1;
						height:105px;
						transition: all 0.3s ease-in-out;
					}
				}
			}
			.tit_link {
				display:none;
			}
			.brand_search {
				position:relative;
				a {
					width:32px;
					height:32px;
					text-decoration:none;
					@include el-center;
					&:after {
						@include sudo_dis(0,0,0,0);
						@include bg(32px,32px,'/images/mobilehome2/new/ico_search_32_black.png');
					}
					span {
						@include hidden;

					}
				}
				.search_wrap {
					display:none;
				}
			}
			.notice {
				margin-left:12px;
				em {
					display:block;
					span {
						@include hidden;
					}
					@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_32_black.png');
					&.alam {
						display:none;
					}
				}
				&.on {
					em {
						display:none;
						&.alam {
							display:block;
							@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_on_32_black.png');
						}
					}
				}
			}
			.home {
				margin-left:12px;
				a {
					& > span {
						@include hidden;
					}
				}
				&.on {
					display:block;
					&::before {
						@include sudo_dis(0,0,0,0);
						@include bg(32px,32px,'/images/mobilehome2/new/ico_home_32_black.png');
					}
				}
			}
			.new_all_menu {
				margin-left:12px;
				h2 {
					@include hidden;
				}
				> button {
					display:block;
					width:32px;
					height:32px;
					span {
						@include hidden;
					}
					&:before {
						@include sudo_dis(0,0,0,0);
						@include bg(32px,32px,'/images/mobilehome2/new/ico_menu_32_black.png');
					}
				}
				.menu_wrap {
					display:none;
				}
			}
			.grade_state {
				display:none;
				> span,
				a {
					@include font_pre(0.875rem,700,$black,1.5);
				}
				&.vip {
					display:block;
					&::before {
						@include sudo_dis(0,0,0,0);
						@include bg(20px,20px,'/images/mobilehome2/new/ico_vip_20_black.png');
					}
				}
			}

			/* 버튼 노출 옵션 */
			> div {
				&.none {
					display:none;
				}
			}
			.search_area {
				display:none;
			}
		}
	}

	/* 메인일때 */
	&.main {
		.logo {
			@include hidden;
		}
	}

	/* 서브 gnb 일때 */
	&.sub {
		.header_wrap {
			> div {
				position:relative;
				.logo {
					display:none;
				}
				.tit_link {
					display:block;
					height:24px;
					margin-left:16px; //좌측여백 16px
					h2 {
						position:absolute;
						top:12px;
						left:48px; // 32px + 16px(좌측여백)
						display:block;
						width:235px;
						@include ellipsis(1,24px);
						@include font_pre(1rem,700,$black,1.5);
					}
					a {
						display:inline-block;
						margin-top:0; //ASIS 충돌방지
						@include bg(24px,24px,'/images/mobilehome2/new/ico_arrow_left_24_black.png');
						span {
							@include hidden;
						}
					}
				}
			}
		}
	}
	/* //서브 gnb 일때 */
}
/* //GNB 정의 */

/* GNB theme BLACK */
header#head.theme_black {
	background-color:$black;
	.header_wrap {
		> div {
			.logo {
				h1 {
					a {
						span {
							color:$white;
						}
					}
				}
			}
			.tit_link {
				margin-top:0; //ASIS 충돌방지
				h2 {
					@include font_pre(1rem,700,$white,1.5);
				}
				a {
					@include bg(24px,24px,'/images/mobilehome2/new/ico_arrow_left_24_white.png');
				}
			}
			.brand_search {
				a {
					&:after {
						@include bg(32px,32px,'/images/mobilehome2/new/ico_search_32_white.png');
					}
				}
			}
			.notice {
				em {
					@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_32_white.png');
				}
				&.on {
					em {
						&.alam {
							@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_on_32_white.png');
						}
					}
				}
			}
			.new_all_menu {
				> button {
					&:before {
						@include bg(32px,32px,'/images/mobilehome2/new/ico_menu_32_white.png');
					}
				}
			}
		}
	}
}

/* GNB theme Transparent */
header#head.theme_trans {
	background-color:transparent;
	.header_wrap {
		> div {
			.logo {
				h1 {
					a {
						span {
							color:$white;
						}
					}
				}
			}
			.tit_link {
				h2 {
					@include font_pre(1rem,700,$white,1.5);
				}
				a {
					margin-top:0; //ASIS 충돌방지
					@include bg(24px,24px,'/images/mobilehome2/new/ico_arrow_left_24_white.png');
				}
			}
			.brand_search {
				a {
					&:after {
						@include bg(32px,32px,'/images/mobilehome2/new/ico_search_32_white.png');
					}
				}
			}
			.notice {
				em {
					@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_32_white.png');
				}
				&.on {
					em {
						&.alam {
							@include bg(32px,32px,'/images/mobilehome2/new/ico_notice_on_32_white.png');
						}
					}
				}
			}
			.new_all_menu {
				background-color:transparent;
				> button {
					&:before {
						margin-top:0; //ASIS 충돌방지
						@include bg(32px,32px,'/images/mobilehome2/new/ico_menu_32_white.png');
					}
				}
			}
		}
	}
}

/* GNB Search */
header#head.search {
	.header_wrap {
		justify-content:flex-start;
		> div {
			position:relative;
			&:last-child {
				width:100%;
				padding-left:8px;
			}
			.logo {
				display:none;
			}
			.tit_link {
				display:block;
				height: 24px;
				margin-left: 16px;
				h2 {
					display:none;
				}
				a {
					display:inline-block;
					margin-top:0; //ASIS 충돌방지
					@include bg(24px,24px,'/images/mobilehome2/new/ico_arrow_left_24_black.png');
					span {
						@include hidden;
					}
				}
			}
			.search_area {
				position:fixed;
				display:block;
				top:4px;
				left:48px;
				right:16px;
				.search_type {
					> input {
						height:40px;
						border: 1px solid $gray08;
						background-color:$gray08;
					}
					.search {
						top:8px;
					}
					.del_input {
						top:10px;
					}
					.list_open_down {
						display:none;
					}
					&.on { //검색어 레이어 확장 시
						.list_open_down {
							position:relative;
							z-index:50;
							display:block;
						}
						&:before {
							content:'';
							position:fixed;
							z-index:-1;
							left:0;
							right:0;
							top:50px;
							bottom:0;
							background-color:#fff;
						}
						> input {
							border: 1px solid $gray08;
							background-color: $white;
						}
						> input:checked,
						> input:focus {
							border:none ;
						}
					}
					&.auto { // 자동변환 활성화 시
						& + .auto_transform {
							display:block;
						}
					}
				}
				.auto_transform {
					display:none;
				}
			}
		}
	}
}