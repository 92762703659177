@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 레이어 팝업 정의 */
article.lay_pop {
	// 기본 케이스
	display: none;
	width: calc(100% - 40px);
	& + .input_wrap  {
		margin-top:8px;
	}
	&.view_image .lay_wrap {
		div.slide_wrap.ban_type {
			margin-bottom: 0px;
		}
	}
	.lay_wrap {
		position: relative;
		padding: 20px 24px 40px;
		background-color: $white;
		box-sizing: border-box;
		.lay_tit {
			min-height: 39px;
			padding-bottom: 12px;
			padding-right: 20px;
			border-bottom:none;
			h1 {
				line-height:1.8; //231005_타이틀과 닫기버튼 정렬 맞춤(공통)
				span{
					// @include font(1rem,700,$black,1.5);
					@include font(16px,700,$black,1.5);
				}
				> a { // 231011_뒤로가기버튼 추가
					display: inline-block;
					margin-top: 0;
					margin-right:8px;
					width: 24px;
					height: 24px;
					background-image: url('/images/mobilehome2/new/ico_arrow_left_24_black.png');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					vertical-align:middle;
					> span {
						@include hidden;
					}
				}
			}
		}
		.lay_conts {
			padding:16px 16px 0;
			height: calc(100% - 50px);
			overflow-y: auto;
			max-height: none;
			&.is-btn {
				max-height: calc(100% - 142px); //lay_bot_btns 있을때 스타일
			}
		}
		.lay_bot_btns {
			display: flex;
			justify-content: center;
			flex-shrink: 0;
			min-height: 56px;
			padding:0 16px 20px;
			text-align: center;
			.btn_wrap {
				width:100%;
				button, a {
					& + button, & + a {
						margin-left:8px;
					}
				}
			}
		}
		&.alert { //경고성
			width:auto; // old 오버라이드
			border-radius:16px;
			padding:20px 16px;
			.lay_conts{
				padding:20px 0 0;
				> div {
					padding: 0px 24px; // 가이드상 옆 공간 40px 에서 겉 padding 16px 뺀 값
					text-align:center;
					&.ico {
						&:before {
							@include sudo_dis(0,auto,16px,auto) {
								display:block;
							}
							@include bg(88px,88px,'/images/mobilehome2/new/ico_no_result_88.png')
						}
					}
					p {
						@include font_pre(1.125rem, 500,$black,1.4);
						word-break: keep-all;
						> b {
							word-break: keep-all;
							@include font_sum (
								$s: 16,
								$w: 500,
								$c: $black,
								$h: 24,
								$l: -.4px,
							);

						}
					}
					.sub_desc {
						margin-top:8px;
						@include font_pre(0.875rem,400,$black,1.5);
					}
				}
				.btn_wrap {
					padding-left: 0px;
					padding-right: 0px;
					padding-top:32px;
					.btn {
						padding-top: 17px;
						padding-bottom: 17px;
						> span {
							display: inline-block;
							font-size: 0.9375rem;
							line-height: 1.4666666667;
							font-family: 'Happiness-Sans' !important;
						}
						&.border {
							border:2px solid $black;
						}
					}
				}
			}
			.lay_tit{
				text-align: center;
				padding-right: 0;
			}
		}
		&.notice_type { //알림성
			padding: 20px 24px;
			border-radius:18px;
			.lay_tit {
				min-height:auto;
				padding-bottom:16px;
				padding-right:32px;
				border-bottom:1px solid $gray03;
				h1 {
					line-height:1;
					span{
						@include font_pre(1.125rem,700,$black,1.5);
					}
				}
			}
			.lay_conts {
				padding:20px 0 0 !important;
			}
			.lay_bot_btns {
				padding:0 !important;
			}
			.close {
				top:16px;
			}
		}
		.close {
			position: absolute;
			top: 8px;
			right: 16px;
			z-index:5;
			button{
				@include bg(32px,32px,'/images/mobilehome2/new/ico_close_32_black.png');
				vertical-align: top;
				span {
					@include hidden;
				}
			}
		}
	}

	/* 이미지전용 */
	&.view_image {
		.lay_wrap {
			padding:0;
			.img_wrap {
				width:100%;
			}
		}
		&.full { // 231015_이미지 풀팝업 추가 (ACS-A01-019P)
			.lay_wrap {
				.lay_conts {
					padding:7px 0 0;
					.img_wrap {
						position:relative;
						width:100%;
						&:after {
							content:'';
							position:absolute;
							top:0;
							left:0;
							right:0;
							bottom:0;
							opacity:0.5;
							background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
							transform: rotate(-180deg);
						}
					}
				}
				.ban_type {
						height:calc(100% - 60px);
						.slide_content {
							height:100%;
							.slide_track  {
								height:100%;
								.slide_conts {
									overflow-y:auto;
									.slide_box {
										position:absolute;
										top:50%;
										transform: translateY(-50%);
										width:100%;
										max-height:100%;
									}
								}
							}
						}
				}
			}
		}
	}

	/* size */
	&.small {
		width:335px;
	}

	// 풀 팝업 케이스
	&.full {
		width: 100%;
		height: 100%;
		.lay_wrap {
			height: 100%;
			padding:0;
			overflow: hidden;
			.lay_tit {
				min-height:50px;
				box-sizing: border-box;
				padding:8px 20px 0;
				h1 {
					padding:0;
				}
				&.al_cetner {
					text-align:center;
				}
			}
			.lay_conts {
				overflow-y: auto;
				&.is-btn {
					max-height: calc(100% - 164px); //lay_bot_btns 있을때 스타일
				}
			}
			.lay_bot_btns {
				padding-top:20px !important;
				padding-bottom:20px !important;
				.btn_wrap {
					margin-top:2px;
				}
			}
		}
	}

	 // 바텀 모달(셀렉트 값 받기)
	&.ui_option_popup {
		width:100%;
		.lay_conts {
			max-height:274px;
			overflow-y: auto;
		}
		.lay_bot_btns {
			margin-top:12px;
		}
	}

	 // 바텀 모달
	&.bottom_type {
		width: 100%;
		.lay_wrap {
			position:relative;
			height: 100%;
			padding:20px 0;
			// padding:20px 0 24px;
			border-top-right-radius: 16px;
			border-top-left-radius: 16px;
			.lay_tit {
				min-height:initial;
				padding-left: 24px;
				padding-bottom: 0;
				padding-right: 24px;
				h1 {
					span {
						// @include tfont(1.125rem,700,$black,1.44);
						@include font_sum (
							$s: 18,
							$w: 700,
							$c: $black,
							$h: 26,
							$l: -.4px,
							$box: inline-block
						);
					}
					.sub_desc {
						display:block;
						margin-top:8px;
						@include font_pre(0.937rem,400,$black,1.5)
					}
				}
			}
			.lay_conts {
				// max-height:500px;
				// max-height:460px; //230817_iPhone 하위 버전 기존에서 높이가 높은 문제로 하단 버튼이 밑으로 밀리는 현상으로 인해 수정
				// padding:24px 16px 0; // 공통 수정
				// max-height: calc(65vh - ((100 + 40 + 24) * 1px)); // (버튼 56 + 20(간격) + 20(간격)) + (40 (팝업 위아래) + 24 (컨텐츠 마진) ) // 20231011_[상단 겹침으로 인한 위 사이즈 간격 수정건]
				max-height: calc(95vh - ((100 + 40 + 24 + 50) * 1px)); // 20231026_[하단 내용이 너무 적게 보이는 이슈로 인해 크기확장요청_AMY-A01-017B]
				padding:0 16px; // 230925_팝업 스크롤 시 제목과의 간격을 위해 수정(공통)
				margin-top:24px;; // 230925_팝업 스크롤 시 제목과의 간격을 위해 수정(공통)
				overflow-y: auto;
				&.branch_select {
					padding-bottom: 20px;
				}
				// MCA1.1.1.1.1.23B
				&.popup_min_height {
					min-height: 63px;
				}
				.tab_wrap {
					// margin-left:-20px;
					// margin-right:-20px;
					margin-left:-16px; // 230911_AHC-B01-035B에서 tab_conts에 가로스크롤이 생겨서 수정
					margin-right:-16px;
				}
				&.check_alert {
					padding-top:8px;
				}
				&.full_wrap {
					margin-left:-20px;
					margin-right:-20px;
				}
				&.info_notice {
					.txt {
						margin-bottom: 6px; // 20231013_[공통영역에서 하단 버튼있을시에 하단 공간 20px 주게끔 수정처리]
						@include font_pre(1rem,500,$black,1.5)
					}
				}
				/*
				 * 20231013_[공통영역에서 하단 버튼있을시에 하단 공간 20px 주게끔 수정처리]
				 * 하단 버튼이 마진 20px 으로 인해 padding-bottom: 40px 으로 적용
				 */
				//
				&.is-btn {
					padding-bottom: 20px;
					box-sizing: border-box;
				}
				// 20231013_[추천인 검색일경우 하단 공간 X]
				&.rcmd_search {
					padding-bottom: 0;
				}
				// 20231016_[지점선태택 팝업]
				&.branch_select {
					.form_type01 {
						> dl {
							> dt {
								margin-left: 8px;
							}
						}
					}
				}
			}
			.lay_bot_btns {
				margin-top:20px; //AHC-B01-035B 가이드로 변경(다른 케이스는 확인 필요)
				// margin-top:40px;
				// padding:0;
				padding:0 16px !important; //AHC-B01-035B 양옆 간격이 없어서 추가, 231017_개발에서 공통호출 스타일에 의해서 AMY-C01-005B 캔슬되고 있어서 important 추가
			}
			.close {
				top: 20px;
				right: 24px;
			}
		}
	}
	// 하단고정 클래스
	&.bottom_fix {
		min-height: auto !important;
	}
}
/* //레이어 팝업 정의 */

/* 레이어 팝업 웹뷰로 띄웠을 경우 타입 */
article.new_page_full_popup {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
}


/* 딤 레이어 */
.dim_layer {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:rgba(0,0,0,0.5);
	z-index:99;
}
/* //딤 레이어 */


/* 툴팁 레이어 */
.tooltip_wrap {
	position:relative;
	z-index:5;
	display:inline-block;
	vertical-align:top;
	.btn_tooltip {
		display:block;
		@include bg(20px,20px,'/images/mobilehome2/new/ico_tooltip_20_black.png');
		> span {
			@include hidden;
		}
	}
	.bubble_wrap {
		display:none;
		position:absolute;
		top:29px;
		min-width:150px;
		padding:12px 40px 12px 16px;
		box-sizing:border-box;
		border-radius:10px;
		background-color:$black;
		&:before {
			content:' ';
			position:absolute;
			width:0;
			height:0;
		}
		&.tr { //right
			right:-12px;
			&:before {
				top:-27px;
				right:12px;
				border-bottom:14px solid $black;
				border-top:20px solid transparent;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
			}
		}
		&.tl { //left
			left:-12px;
			&:before {
				top:-27px;
				left:12px;
				border-bottom:14px solid $black;
				border-top:20px solid transparent;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
			}
		}
		&.tc { //center
			left:-118px;
			&:before {
				top:-27px;
				left:118px;
				border-bottom:14px solid $black;
				border-top:20px solid transparent;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
			}
		}
		&.br {
			bottom: 32px;
			top: auto;
			&:before {
				top: auto;
				bottom: -27px;
				border-top: 14px solid #171A19;
				border-bottom: 20px solid transparent;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}
		}
		.desc {
			display:block;
			@include font_pre(0.875rem, 400, $white, 1.5); // 20230717 가이드 반영
			.txt {
				@include font_pre(0.75rem,400,$white,1.5);
				& + .txt {
					margin-top:8px;
				}
			}
			word-break:keep-all; // 231106_단어단위로 줄바꿈
		}
		.btn_close {
			position:absolute;
			top:12px;
			right:16px;
			span {
				@include hidden;
			}
			@include bg(20px,20px,'/images/mobilehome2/new/ico_close_20_white.png');
		}
	}
	&.open {
		.bubble_wrap {
			display:block;
		}
	}
}
/* //툴팁 레이어 */

/* toast */
.toast{
	position: fixed;
	width: 100%;
	bottom: 20px;
	z-index: 99999;

	.toast_box{
		position: relative;
		// width: calc(96% - 85px);
		width: calc(100% - 85px);
		padding: 16px 33px 16px 20px;
		border-radius: 10px;
		text-align: center;
		margin: 0 auto;
		// background: rgba(0, 0, 0, .6);
		border-radius:10px;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
		background-color: rgba(0, 144, 92, 0.9);
		span{
			@include font_pre(0.875rem,400,$white,1.5);
			color: $white !important;
		}
		button {
			display: block;
			position: absolute;
			right: 16px;
			top: 16px;
			@include bg(20px,20px,'/images/mobilehome2/new/ico_close_20_white.png');
		}
		&.no_x_btn_toast {
			padding-top: 8px; /* 20230714_[디자인 공통 적용] */
			padding-right: 20px;
			padding-bottom: 8px; /* 20230714_[디자인 공통 적용] */
		}
	}
}
/* toast */