@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 폼 */
div.input_wrap {
	position:relative;
	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="button"],
	input[type="email"],
	input[type="date"],
	input[type="tel"] {
		width:100%;
		height:48px;
		// padding:0 16px !important;
		padding:0 42px 0 16px !important;
		// border-radius:8px;
		border-radius:10px; // 디자인 수정(0531)
		border:1px solid $gray03;
		box-sizing:border-box;
		@include font_pre(0.937rem,400,$black,1.5);
		&:active {
			border-color:$gray03;
		}
		&:read-only {
			padding-right:0;
			background-color: #f7f7f7;
			border-color: #eaebea;
			outline:none;
			//iOS일때
			opacity:1;
			&::placeholder {
				@include font_pre(0.937rem,400,$gray04 !important,1.5);
			}
			&:focus,&:active {
				border:1px solid $gray08;
				outline: transparent;
			}
		}
		&:disabled{
			background-color:$gray05;
			border-color:$gray03;
			color:$gray01;
			pointer-events:none;
			//iOS일때
			-webkit-text-fill-color:$gray01;
			opacity:1;
			&::placeholder {
				@include font_pre(0.937rem,400,$gray01,1.5);
			}
		}
		&::placeholder {
			@include font_pre(0.937rem,400,$gray04,1.5);
		}
		&:focus,&:active {
			border:1px solid $green;
			outline: transparent;
		}
		//입력형식에 안맞을경우
		&.incorrect_type {
			border: 1px solid $rred;
		}
	}

	&.no_bg {
		input {
			border:none !important;
			background:none !important;
			font-size:1rem;
			font-weight:500;
			padding-left:8px !important;
		}
	}

	//입력삭제버튼
	.del_input {
		position: absolute;
		top: 14px;
		right: 16px;
		width: 20px;
		height: 20px;
		@include bg(20px,20px,'/images/mobilehome2/new/ico_input_del_20.png');
	}
	&.no_del { //입력삭제버튼이 없을 때
		input[type="text"],
		input[type="password"],
		input[type="number"],
		input[type="button"],
		input[type="email"],
		input[type="date"],
		input[type="tel"] {
			padding:0 16px !important;
		}
		.del_input {
			display:none;
		}
	}
	.btn_wrap {
		margin-left:8px !important;
		margin-right:0 !important;
	}

	//검색어 입력일때(삭제버튼노출케이스)
	&.search_type {
		input[type="text"],
		input[type="password"],
		input[type="number"],
		input[type="button"] {
			padding-right:68px !important;
		}
		.input {
			&.on {
				input[type="text"],
				input[type="password"],
				input[type="number"],
				input[type="button"] {
					padding-right:56px;
				}
			}
		}
		.search {
			position:absolute;
			top:12px;
			right:12px;
			@include bg(24px,24px,'/images/mobilehome2/new/ico_search_24_black.png');
			> span {
				@include hidden;
			}
		}
		.del {
			// display:none;
			position:absolute;
			top:16px;
			right:76px;
			background-color:transparent;
			@include bg(20px,20px,'/images/mobilehome2/new/ico_input_del.png');
			> span {
				@include hidden;
			}
		}
		&.search_open_down {
			overflow: hidden;
			border-radius: 10px;
			// border: 1px solid $gray03;
			&.on {
				border: 1px solid $green;
				input {
					border:none !important;
					background-color:$white;
				}
			}
			.del_input {
				right: 52px;
			}
			.search {
				width: 24px;
				height: 24px;
				top: 13px;
			}
			input {
				border: 1px solid $gray03;
				border-radius: 10px;
				background-color: $white;
				&:active,
				&:focus {
					border: 1px solid $green;
					background-color:$white;
				}
			}
			.list_open_down {
				overflow-y: auto;
				max-height: 200px;
				background:$gray08;
				li {
					height: 40px;
					button {
						display: block;
						width: 100%;
						padding: 9px 0;
						text-indent: 16px;
						text-align: left;
						@include font_pre(0.937rem,400,$gray04,1.47);
						span {
							@include font_pre(0.937rem,400,$black,1.47);
						}
					}
				}
			}
		}
		.del_input {
			right:46px;
		}
		&.auto {
			& + .auto_transform {
				padding:8px 12px;
				background-color:$white;
				// position:absolute;
				// top:0;
				// left:0;
				> p {
					@include font_pre(0.75rem,400,$gray02,1.5);
					em {
						@include font_pre(0.75rem,400,$green02,1.5);
					}
				}
				> a {
					display:block;
					margin-top:5px;
					@include font_pre(0.75rem,500,$black,1.5){
						text-decoration:underline;
					};
				}
				&:after {
					content:'';
					position:fixed;
					top:50px;
					left:0;
					right:0;
					height:58px;
					background-color:$white;
					z-index:-1;
				}
			}
		}
	}
}

div.input_wrap { //간격,정렬
	> div {
		position:relative !important;
		top:initial !important;
		right:initial !important;
		transform:none !important;
		&:first-child {
			margin-left:0;
		}
	}
	&.type01 {
		display:flex;
	 	justify-content: space-between;
		 .del_input {
			right:129px;
		}
	}
	& + .input_wrap,
	& + .sel_wrap {
		margin-top:8px;
	}
	&.error { //입력에러
		input {
			border-color:$rred;
		}
		& + .caution_wrap {
			display:block;
		}
	}
}

.amount_wrap { //금액 + 한글
	margin-top:16px;
	padding:13px 16px;
	border-radius:8px;
	border: solid 1px $gray03;
	background-color:$white;
	sup {
		display:block;
		@include font_pre(0.75rem,500,$green,1.5);
	}
	input {
		width:calc(100% - 42px);
		border:none;
		@include font_pre(0.937rem,400,$black,1.5);
		outline:none;
	}
	.txt {
		margin-left:20px;
		@include font_pre(0.937rem,400,$black,1.5);
	}
	&.error { //입력에러
		border-color:$rred;
		& + .caution_wrap {
			display:block;
		}
	}
}

.caution_wrap {
	display:none;
	//width:100%;
	margin-top:8px;
	margin-left: 12px; // 20230720
	p {
		@include font_pre(0.75rem,400,$rred,1.5);
	}
	&.positive {
		p {
			color:$green02;
		}
	}
}

div.rdo_wrap {
	display:inline-block;
	position:relative;
	& + .rdo_wrap {
		margin-left:0;
	}
	input {
		@include hidden;
	}
	input {
		&:focus + label {
			// outline:1px solid $black;
			outline: 0;
		}
	}
	label {
		display:inline-block;
		padding-left:28px;
		> span {
			@include font_pre(0.937rem,400,$black,1.5);
		}
	}
	input + label {
		&:before {
			@include sudo_pos(0,0,0,0);
			@include bg(20px,20px,'/images/mobilehome2/new/ico_radio_20_gray.png');
		}
	}
	input:checked + label {
		&:before {
			@include bg(20px,20px,'/images/mobilehome2/new/ico_radio_20_green.png');
		}
	}
	input:disabled + label {
		span {
			color:$gray01;
		}
		&:before {
			@include bg(20px,20px,'/images/mobilehome2/new/ico_radio_20_dis.png');
		}
	}

	//라운드형
	&.round {
		& + .rdo_wrap {
			margin-top:8px;
		}
		label {
			width:100%;
			height:65px;
			padding-left:0;
			text-align:center;
			border:1px solid $gray03;
			border-radius:80px;
			box-sizing:border-box;
			background-color:$white;
			@include el_center{
				flex-wrap:wrap;
				flex-direction: column;
			};
			&::before {
				content:none;
			}
			span {
				display:block;
				width:100%;
				@include font_pre(0.875rem,400,$black,1.5);
				&.desc {
					margin-top:2px;
					@include font_pre(0.75rem,400,$black,1.5)
				}
			}
		}
		input:checked {
			& + label {
				border:1.4px solid $black;
				span {
					font-weight:700;
					&.desc {
						font-weight:400;
					}
				}
			}
		}
		input:disabled + label {
			pointer-events:none;
			background-color:$gray05;
			span {
				color:$gray01;
			}
		}
	}
	//라운드형(소형)
	&.s_round {
		& + .rdo_wrap {
			margin-top:8px;
			margin-left:0;
		}
		label {
			width:100%;
			// height:45px; MCA1.1.1.1.1.17 때문에 수정
			height:48px;
			padding-left:0;
			text-align:center;
			border:1px solid $gray03;
			border-radius:28px;
			box-sizing:border-box;
			background-color:$white;
			@include el_center{
				flex-wrap:wrap;
				flex-direction: column;
			};
			&::before {
				content:none;
			}
			span {
				display:block;
				width:100%;
				@include font_pre(0.937rem,400,$black,1.5);
				&.desc {
					margin-top:2px;
					@include font_pre(0.75rem,400,$black,1.5)
				}
			}
		}
		input:checked {
			& + label {
				background-color:$green02;
				border:1px solid $green02;
				span {
					@include font_pre(0.937rem,700,$white,1.5);
					&.desc {
						font-weight:400;
					}
				}
			}
		}
		input:disabled + label {
			pointer-events:none;
			border:1px solid $gray05;
			background-color:$gray05;
			span {
				color:$gray01;
			}
		}
		//보더형
		&.border {
			input:checked {
				& + label {
					padding:7px 10px 6px;
					background-color:$white;
					border:2px solid $black;
					span {
						@include font_pre(0.875rem,700,$black,1.5);
					}
				}
			}
		}
	}
	//카드 라운드형
	&.card_round {
		& + .rdo_wrap {
			// margin-top:8px;
			margin-top:16px; //디자인 수정(0531)
		}
		> label {
			width:100%;
			padding-left:0;
			text-align:left;
			box-sizing:border-box;
			// border:1px solid $gray03;
			// border-radius:16px;
			border-radius: 18px; //디자인 수정multi_form
			background-color:$white; //디자인 수정(0531)
			box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1); //디자인 수정(0531)
			&::before {
				content:none;
			}
		}
		> input:checked {
			& + label {
				border:1.4px solid $black;
				span {
					font-weight:700;
					&.desc {
						font-weight:400;
					}
				}
			}
		}
		> input:disabled + label {
			pointer-events:none;
			background-color:$gray05;
			span {
				color:$gray01;
			}
		}
	}

	//선택형
	&.sel_btn {
		& + .rdo_wrap {
			margin-top:8px;
		}
		label {
			min-width:96px;
			height:48px;
			padding:13px 20px;
			text-align:center;
			background-color:$white;
			border:1px solid $gray03;
			border-radius:24px;
			box-sizing:border-box;
			@include el_center{
				flex-wrap:wrap;
				flex-direction: column;
			};
			&::before {
				content:none;
			}
			span {
				display:block;
				width:100%;
				@include font_pre(0.937rem,400,$black,1.5);
			}
		}
		input:checked {
			& + label {
				padding:10px 19px;
				background-color:$green02;
				border:1px solid $green02;
				span {
					@include font_pre(0.937rem,700,$white,1.5);
				}
			}
		}
		input:disabled + label {
			pointer-events:none;
			background-color:$gray05;
			border:1px solid $gray03;
			span {
				color:$gray01;
			}
		}
		//보더형
		&.border {
			input:checked {
				& + label {
					padding:10px 18px;
					background-color:$white;
					border:2px solid $black;
					span {
						@include font_pre(0.937rem,700,$black,1.5);
					}
				}
			}
		}
		//보더형(그린)
		&.border_green {
			input:checked {
				& + label {
					padding:10px 18px;
					background-color:transparent;
					border:2px solid $green02;
					span {
						@include font_pre(0.937rem,700,$green02,1.5);
					}
				}
			}
		}
	}
	//선택형(소형)
	&.s_sel_btn {
		& + .rdo_wrap {
			margin-top:8px;
		}
		label {
			// min-width:84px;
			min-width:auto;
			height:36px;
			padding:6px 11px 7px;
			background-color:$white;
			border: 1.4px solid $gray03;
			border-radius:24px;
			box-sizing:border-box;
			text-align:center;
			@include el_center{
				flex-wrap:wrap;
				flex-direction: column;
			};
			&::before {
				content:none;
			}
			span {
				display:block;
				width:100%;
				@include font_pre(0.875rem,400,$black,1.5);
			}
		}
		input:checked {
			& + label {
				// padding:8px 11px 7px;
				background-color:$green02;
				border:1.4px solid $green02; //1.4 ?
				span {
					@include font_pre(0.875rem,700,$white,1.5);
				}
			}
			// &input:disabled + label {
			// 	pointer-events:none;
			// 	background-color:$gray05;
			// 	border:1px solid $gray03;
			// 	span {
			// 		color:$gray01;
			// 	}
			// }
		}
		input:disabled + label {
			pointer-events:none !important;
			background-color:$gray05 !important;
			border:1px solid $gray03 !important;
			span {
				color:$gray01 !important;
			}
		}
		//보더형
		&.border {
			input:checked {
				& + label {
					padding:7px 10px 6px;
					background-color:$white;
					border:2px solid $black;
					span {
						@include font_pre(0.875rem,700,$black,1.5);
					}
				}
			}
		}

		&.black {
			label {
				text-align:center;
				border-radius:8px;
				background-color:$white;
				border:1px solid $black;
				span {
					color:$black;
				}
			}
			input:checked {
				& + label {
					background-color:$black !important;
					span {
						color:$white;
					}
				}

			}
		}
	}
	//상세정보제공형(버튼)
	&.btn_type {
		width:100%;
		input {
			& + label {
				border: solid 1px rgba(155,158,156,0.1);
				background-color:rgba(155,158,156,0.1);
				border-radius:24px;
				.sel_btn {
					display:flex;
					justify-content:center;
					align-items:center;
					width:102px;
					height:50px;
					margin-left:-2px;
					margin-top:-2px;
					margin-bottom:-2px;
					border-radius: 24px;
					border: solid 1px $gray03;
					background-color:$white;
					box-sizing: border-box;
					@include font_pre(0.937rem,400,$black,1.5);
				}
				.desc {
					display:flex;
					justify-content:center;
					align-items:center;
					flex:1;
					@include font_pre(0.875rem,400,$black,1.5);
				}
			}
		}
		input:checked + label {
			border: solid 1px $gray10;
			background-color:$gray10;
			.sel_btn {
				border: solid 1px $green02;
				background-color:$green02;
				@include font_pre(0.937rem,500,$white,1.5);
			}
		}
		& + .btn_type {
			margin-top:20px;
		}
		//세로펼침형
		&.row {
			input {
				& + label {
					display:block;
					height:auto;
					border: solid 1px $gray05;
					background-color:$gray05;
					border-radius:28px;
					.sel_btn {
						display:flex;
						justify-content:center;
						align-items:center;
						width:100%;
						height:48px;
						margin-left:0;
						border-radius: 28px;
						border:1px solid $gray03;
						background-color:$white;
						box-sizing: border-box;
						@include font_pre(0.937rem,400,$black,1.5);
					}
					.desc {
						display:none;
						justify-content:space-between;
						align-items:center;
						padding:20px 16px;
						> span {
							@include font_pre(0.875rem,400,$gray02,1.5);
						}
						.btn_wrap {
							.sbtn {
								min-width:initial;
								height:auto;
								padding:3px 12px 4px;
								// background-color:transparent;
							}
						}
					}
				}
			}
			input:checked + label {
				border:solid 1px $gray05;
				background-color:$gray05;
				.sel_btn {
					// border: solid 2px $black;
					// @include font_pre(0.937rem,700,$black,1.5);
					// border:1px solid $gray03;
					// background-color:$white;
					border: solid 1px $green02; //230916_활성화시녹색으로변경(AHC-E01-005)
					background-color:$green02; //230916_활성화시녹색으로변경(AHC-E01-005)
					@include font_pre(0.937rem,500,$white,1.5); //230916_활성화시녹색으로변경(AHC-E01-005)
					&.em {
						border: solid 2px $green;
						background-color:$green;
						@include font_pre(0.937rem,700,$white,1.5);
					}
				}
				.desc {
					display:flex;
					&.line {
						display:block;
					}
				}
			}
			& + .rdo_wrap {
				margin-top:12px;
			}
		}
	}
}

div.chk_wrap {
	display:inline-block;
	position:relative;
	// &.error {
	// 	label {
	// 		display:inline-block;
	// 		padding-left:23px;
	// 		> span {
	// 			@include font_pre(0.937rem,400,$ared,1.4);
	// 		}
	// 	}
	// }
	input {
		@include hidden;
	}
	input {
		&:focus + label {
			outline: 0;
		}
	}
	label {
		display:inline-block;
		padding-left:28px; //아이콘사이즈+여백
		> span {
			@include font_pre(0.937rem,400,$black,1.5);
		}
	}
	input + label {
		&:before {
			content:'';
			position:absolute;
			top:1px;
			left:0;
			@include bg(20px,20px,'/images/mobilehome2/new/ico_check_20_gray.png');
		}
	}
	input:checked + label {
		span {
			color:$black;
		}
		&:before {
			@include bg(20px,20px,'/images/mobilehome2/new/ico_check_20_green.png');
		}
	}
	input:disabled + label {
		span {
			color:$gray01;
		}
		&:before {
			@include bg(20px,20px,'/images/mobilehome2/new/ico_check_20_dis.png');
		}
		//비어있는 체크박스
		&.empty {
			&:before {
				@include bg(20px,20px,'/images/mobilehome2/new/ico_check_20_empty.png');
			}
		}
	}

	&.sm { //2deth
		label {
			display:inline-block;
			padding-left:28px;
			> span {
				@include font_pre(0.875rem,400,$black,1.5);
			}
		}
		input + label {
			&:before {
				content:'';
				position:absolute;
				top:1px;
				left:0;
				@include bg(20px,20px,'/images/mobilehome2/new/ico_s_check_20_gray.png');
			}
		}
		input:checked + label {
			span {
				color:$black;
			}
			&:before {
				@include bg(20px,20px,'/images/mobilehome2/new/ico_s_check_20_green.png');
			}
		}
		input:disabled + label {
			span {
				color:$gray01;
			}
			&:before {
				@include bg(20px,20px,'/images/mobilehome2/new/ico_s_check_20_dis.png');
			}
		}
	}
	//토글타입
	&.toggle {
		height:24px;
		input {
			& + label {
				position:relative;
				width:48px;
				height:24px;
				padding:0;
				border-radius:50px;
				background-color:$gray01;
				&:before {
					width:20px;
					height:20px;
					border-radius:50px;
					background-color:$white;
					background-image:none;
					@include sudo_pos(2px,initial,0,2px);
				}
				> span {
					@include hidden;
				}
			}
		}
		input:checked {
			& + label {
				background-color:$green;
				&:before {
					@include sudo_pos(2px,2px,0,initial)
				}
			}
		}
	}
	//선택형(소형)
	&.s_sel_btn {
		& + .chk_wrap {
			margin-top:8px;
		}
		label {
			min-width:auto;
			height:36px;
			padding:8px 12px 7px;
			background-color:$white;
			border:1px solid $gray03;
			border-radius:24px;
			box-sizing:border-box;
			text-align:center;
			@include el_center{
				flex-wrap:wrap;
				flex-direction: column;
			};
			&::before {
				content:none;
			}
			span {
				display:block;
				width:100%;
				@include font_pre(0.875rem,400,$black,1.5);
			}
		}
		input:checked {
			& + label {
				padding:8px 11px 7px;
				background-color:$green02;
				border:1.4px solid $green02; //1.4 ?
				span {
					@include font_pre(0.875rem,700,$white,1.5);
				}
			}
		}
		input:disabled + label {
			pointer-events:none;
			background-color:$gray05;
			border:1px solid $gray03;
			span {
				color:$gray01;
			}
		}
		//보더형
		&.border {
			input:checked {
				& + label {
					padding:7px 10px 6px;
					background-color:$white;
					border:2px solid $black;
					span {
						@include font_pre(0.875rem,700,$black,1.5);
					}
				}
			}
		}

		&.black {
			label {
				text-align:center;
				border-radius:8px;
				background-color:$white;
				border:1px solid $black;
				span {
					color:$black;
				}
			}
			input:checked {
				& + label {
					background-color:$black !important;
					span {
						color:$white;
					}
				}

			}
		}
	}
}

div.sel_wrap {
	.ui-selectbox-wrap {
		position:relative;
		width:100% !important;
		// z-index: 10;
		.ui-select-button { //셀렉트박스 스타일
			display:block;
			padding:12px 20px;
			border-radius:8px;
			border:1px solid #eaebea;
			background-color:$white;
			box-sizing:border-box;
			&.placeholder {
				.ui-select-text {
					color:$gray04;
				}
			}
			.ui-select-text {
				margin-right:-1px;
				@include font_pre(0.937rem,400,$black,1.5);
				vertical-align:middle;
				white-space:nowrap;
			}
			.hide {
				@include hidden;
			}
			.ico {
				position:absolute;
				top:16px;
				right:20px;
				flex-shrink: 0;
				@include bg(20px,20px,'/images/mobilehome2/new/ico_arrow_up_20_gray.png');
				//ABF-B01-011T.html 2차수정중 변경
				transform:rotate(180deg);
			}
		}
		&.on {
			.ui-select-button {
				box-sizing: border-box;
				padding:12px 20px 13px 20px;
				border-left:1px solid $green;
				border-right:1px solid $green;
				border-top:1px solid $green;
				border-bottom:none;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				&::after {
					@include sudo_pos(initial,2px,0,2px);
					z-index:1;
					border-bottom:1px solid #eaebea;
					height:1px;
				}
				.ico {
					transform:rotate(0deg); //230927_추가(AMB-C01-018P)
				}
			}
			// .ico {
				// top:14px;
				//ABF-B01-011T.html 2차수정중 변경
				// transform:rotate(0deg);
			// }
		}
		.ui-dropdown,
		.ui-selectbox-list { //펼쳐졌을때 스타일
			position:absolute;
			z-index:2;
			max-height: 114px;
			overflow: auto;
			left:0;
			right:0;
			padding:0;
			background-color:$white;
			box-sizing:border-box;
			border-left:1px solid $green;
			border-right:1px solid $green;
			border-bottom:1px solid $green;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			.ui-dropdown-title,
			.btn_wrap {
				@include hidden;
			}
			.ui-dropdown-list,
			.ui-select-scrollarea-ul {
				> li {
					padding:12px 20px;
					> a {
						display:block;
						outline: none;
						@include font_pre(0.937rem,400,$gray02,1.5) {
							text-decoration:none;
						};
					}
				}
			}
		}
		&.disabled {
			.ui-select-button {
				background-color:$gray05;
			}
		}
	}

	&.dis { //비활성
		.ui-selectbox-wrap {
			.ui-select-button {
				pointer-events: none;
				background-color:$gray05;
				.ui-select-text {
					color:$gray01;
				}
				.ico {
					@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_dis.png');
					transform:rotate(0deg);
				}
			}
		}
	}

	& + .input_wrap,
	& + .sel_wrap {
		margin-top:8px;
	}

	&.inline_type { //리스트 내 정렬 셀렉트박스(인라인타입)
		> .ui-selectbox-wrap {
			> .ui-selectbox-view {
				> .ui-select-button {
					display: flex;
					padding: 0px;
					border: 0px;
					border-radius: 0;
					align-items: center;
					justify-content: space-between;
					outline:none;
					-webkit-tap-highlight-color: rgba(0,0,0,0); //웹킷기반에서 터치영역 하이라이트 삭제
					span.ui-select-text {
						@include font_sum(
							$w: 400,
							$c: $gray02
						);
						width: auto;
						margin-right: 4px;
						font-size:14px;
					}
					span.ico {
						display: inline-block;
						position: initial;
					}
				}
			}
			&.on {
				> .ui-selectbox-view {
					.ui-select-button {
						border: 0xp;
						&::after {
							display: none;
						}
					}
			}
		}
		}
		.ui-dropdown {
			// max-height: 114px;
			max-height: 208px; //231010_최대높이 증가
			overflow-x: hidden;
			overflow-y: auto;
			position: absolute;
			min-width: 160px;
			width: auto;
			padding: 0;
			margin-top: 8px;
			border-radius: 8px;
			border:none;
			left: auto;
			right: 0;
			z-index: 10;
			box-sizing: border-box;
			background-color: #FFFFFF;
			box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
			> .ui-dropdown-box {
				> .ui-dropdown-title {
					@include hidden;
				}
				> .ui-dropdown-list {
					> li {
						min-height: 32px;
						padding: 5px 8px 6px 12px;
						box-sizing: border-box;
						> a {
							padding: 0px;
							@include font_sum (
								$c: $gray04,
								$box: block
							);
						}
					}
					> li.on {
						background-color: #f7faf9;
						> a {
							padding-right: 28px;
							color: #171a19;
							background-image: url('/images/mobilehome2/new/ico_s_check_h2_20_green.png');
							background-size: 20px 20px;
							background-repeat: no-repeat;
							background-position: center right;
						}
					}
				}
			}
		}
	}
}

.multi_form { //멀티폼 스타일
	display: flex;
	justify-content: flex-start;
	> div {
		flex:1;
		& + div {
			margin-top:0 !important;
			margin-left:8px;
		}
	}
	&.custom {
		> div {
			flex:0;
		}
	}
	.dash {
		display:inline-flex;
		align-items:center;
	}

	&.card_input { //카드유효기간입력폼
		.input_wrap {
			flex:1;
		}
		.dash {
			 flex:0;
		}
		> div + div {
			margin-left:4px;
		}
	}

	&.id_card { //주민등록번호 입력 폼
		// .input_wrap:first-child {
		// 	flex:1;
		// }
		.input_wrap {
			flex:1;
		}
		div.one_digit {
			flex:0;
			input {
				width:32px;
				padding:0 10px !important;
			}
		}
		.password_wrap {
			display:flex;
			justify-content: space-between;
			margin-left:8px;
			margin-right:24px;
			align-items:center;
			.pw_dot {
				display:inline-block;
				width:8px;
				height:8px;
				background-color:$gray01;
				border-radius: 16px;
				& + .pw_dot {
					margin-left:8px;
				}
			}
		}
	}

	&.license_id {
		.input_wrap {
			// padding:0 10px;
			flex:1;
			&:nth-child(3),
			&:nth-child(7) {
				flex:0;
			}
			input {
				padding:10px !important;
			}
		}
	}
}

.textarea_wrap {
	position:relative;
	padding: 13px 20px 45px 16px;
    border:1px solid $gray03;
	border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
	textarea {
		width: 100%;
		box-sizing: border-box;
		border: none;
		@include font_pre(0.973rem, 400, $black, 1.47);
		vertical-align:top;
		text-align:left;
		resize:none;
		&::placeholder {
			@include font_pre(0.973rem, 400, #9b9e9c, 1.47);
		}
		&:focus,&:active {
			border-color:$green;
			outline: transparent;
		}
	}
	.word_num {
		position: absolute;
		bottom: 20px;
		right: 20px;
		@include font_sum ( $s: 15, $c: #727573, $h: 22, $box:inline-block );
		.cnt { @include font_sum ( $s: 15, $c: #727573, $h: 22, $box:inline-block ); }
		em {  @include font_sum ( $s: 15, $c: $red, $h: 22, $box:inline-block ); }
	}
	&.read_only {
		padding: 13px 16px;
		background: $gray05;
		textarea {
			background: $gray05;
		}
	}
}

/*
	20230525_선택시 굵게 변경되는 스타일 추가 strong 태그로
 */
.btm_select_wrap {
	.ui_option_wrap {
		li {
			position:relative;
			.ui_btn_option {
				width:100%;
				padding:16px 24px;
				text-align:left;
				@include font_pre(1rem,500,$black,1.5);
				> em {
					margin-right: 4px;
					@include font_pre(1rem,500,$black,1.5);
				}
				> strong {
					@include font_pre(1rem,500,$black,1.5);
				}
				&:focus {
					background-color:$gray10;
				}
			}
			&.on {
				&:after {
					@include sudo_pos(50%,24px,0,initial){
						transform:translateY(-50%);
					};
					@include bg(20px,20px,'/images/mobilehome2/new/ico_s_check_20_green.png');
				}
				.ui_btn_option {
					background-color:$gray08;
					font-weight: 700; //230801_추가
					> strong {
						font-weight: 700;
					}
				}
			}
		}
	}
}

dl.with_text_input { // 인풋박스 내에에 타이틀과 단위 텍스트가 함께 노출되는 형태
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:0 16px;
	border-radius: 10px;
	border:solid 1px $gray03;
	background-color:$white;
	dt {
		@include font_pre(0.937rem,400,$black,1.5);
	}
	dd {
		flex:1;
		@include font_pre(0.937rem,400,$black,1.5);
		div.input_wrap {
			display:inline-block;
			width: calc(100% - 18px);
			input[type=text],
			input[type=password],
			input[type=number],
			input[type=button],
			input[type=email],
			input[type=date],
			input[type=tel] {
				padding-right: 0px !important;
				border:none;
				text-align: right;
				&:focus,&:active {
					outline:none;
					border:none;
				}
			}
			& + .won {
				@include font_sum (
					$s: 15,
					$h: 22,
				);
			}
			&.del_input_static {
				display: inline-flex;
				align-items: center;
				justify-content: flex-end;
				flex-wrap: nowrap;
				input {
					width: 100%;
				}
				.del_input {
					position: static;
					margin-left: 6px;
					flex-shrink: 0;
				}
			}
		}
	}
	&.error { //입력에러
		border-color:$rred;
		& + .caution_wrap {
			display:block;
		}
	}
	&.readonly {
		background-color:$gray05;
	}
	&.won_box {
		> dt {
			width: auto;
			flex-shrink: 0;
		}
		> dd {
			display: flex;
			width: 100%;
			flex: auto;
			align-items: center;
			justify-content: flex-end;
			> span.won {
				display: none;
				margin-left: 4px;
				&.on {
					display: inline-block
				}
			}
		}
	}
}

// 나의 쿠폰(AMY-A01-005B) 인풋 + 버튼(버튼크기가 104px 이상일시) 사용
.input_btn_set {
	display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: flex-start;
	> .input_wrap {
		width: 100%;
	}
	> .btn_wrap {
		width: auto;
		flex-shrink: 0;
	}
}
/* //폼 */