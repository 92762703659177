@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

@font-face {
	font-family: 'Happiness-Sans';
	font-style: 'normal';
	font-weight: 400;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	src: url('/font/Happiness-Sans-Regular.woff2') format('woff2'), /*  Modern Browsers */
		 url('/font/Happiness-Sans-Regular.woff') format('woff'), /* Modern Browsers */
		 url('/font/Happiness-Sans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/font/Happiness-Sans-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Happiness-Sans';
	font-style: 'bold';
	font-weight: 700;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	src: url('/font/Happiness-Sans-Bold.woff2') format('woff2'), /*  Modern Browsers */
		 url('/font/Happiness-Sans-Bold.woff') format('woff'), /* Modern Browsers */
		 url('/font/Happiness-Sans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/font/Happiness-Sans-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Happiness-Sans-Title';
	font-style: 'bold';
	font-weight: 900;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	src: url('/font/Happiness-Sans-Title.woff2') format('woff2'), /*  Modern Browsers */
		 url('/font/Happiness-Sans-Title.woff') format('woff'), /* Modern Browsers */
		 url('/font/Happiness-Sans-Title.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('/font/Happiness-Sans-Title.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	font-style: normal;
	src: local('Pretendard Regular'),
		 url('/font/Pretendard-Regular.woff2') format('woff2'),
		 url('/font/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: local('Pretendard Medium'),
		 url('/font/Pretendard-Medium.woff2') format('woff2'),
		 url('/font/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-style: 'bold';
	font-display: swap;
	src: local('Pretendard Bold'),
		 url('/font/Pretendard-Bold.woff2') format('woff2'),
		 url('/font/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	font-style: 'bold';
	src: local('Pretendard ExtraBold'),
		 url('/font/Pretendard-ExtraBold.woff2') format('woff2'),
		 url('/font/Pretendard-ExtraBold.woff') format('woff');
}



/* 기본폰트정의 */
div, p, span, dt, dd, li, label, a, button, dfn, i {
	font-style:normal;
	@include font_pre(0.875rem,400,$black,1.5);
}
em, strong {
	font-style:normal;
	@include font_pre(0.875rem,700,$black,1.5);
}
/* //기본폰트정의 */

/* 타이틀폰트 스타일 */
.tit01 {
	@include tfont(2rem,400,$black,1.4){
		font-family: 'Happiness-Sans-Title' !important;
	};
}
.tit02 {
	@include font(1.5rem,700,$black,1.4) {
		font-family: 'Happiness-Sans' !important;
	};
	> em {
		@include font(1.5rem,700,$green02,1.4){
			font-family: 'Happiness-Sans' !important;
		};
	}
}
.tit03 {
	@include font(1.25rem,700,$black,1.4){
		font-family: 'Happiness-Sans' !important;
	};
	> em {
		@include font(1.25rem,700,$green02,1.4){
			font-family: 'Happiness-Sans' !important;
		};
	}
}
.tit04 {
	letter-spacing:-0.8px;
	@include font_pre(1.125rem,700,$black,1.4);
	> em {
		@include font_pre(1.125rem,700,$green02,1.4);
	}
}
/* //타이틀폰트 스타일 */

/* 단독 크기 정의 */
.f16 {
	font-size:1rem;
}