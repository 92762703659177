@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 블렛 정의 */
ul.bullet_list {
	> li {
		position:relative;
		word-break:keep-all;
		@include font_pre(0.875rem,400,$gray09,1.5);
		> a {
			color:$black;
		}
		& + li {
			margin-top:8px; //가이드확인필요
		}
		.rred {
			font-weight:400;
			color:$rred !important;
		}
	}
	&.square {
		> li {
			padding-left:13px;
			&:before {
				width:3px;
				height:3px;
				background-color:$gray09;
				transform:rotate(45deg);
				@include sudo_pos(8px,0,0,0);
			}
		}
		& + li {
			margin-top:8px;
		}
	}
	&.dot { //dot
		li {
			padding-left: 5px; // 20230428_[지점안내/카드신청 간격이 5임을 확인하고 수정]
			&:before {
				content:' ';
				position:absolute;
				top:7px;
				left:0;
				width:2px;
				height:2px;
				border-radius:10px;
				background-color:$gray02;
				@include sudo_pos(7px,0,0,0);
			}
		}
		&.strong {
			> li {
				padding-left: 10px;
				&:before {
					content:' ';
					width:4px;
					height:4px;
					border-radius:50%;
					background-color: $gray02;
					@include sudo_pos(7px,0,0,0);
				}
				@include font_sum(14, 400, $gray09, 21, -.4px){
					display:block;
				};
			}
		}
		&.gray {
			> li {
				padding-left: 10px;
				> p {
					color:$gray02;
				}
				em, strong {
					color:$gray02;
				}
				&:before {
					background-color: $gray02;
				}
				@include font_sum(14, 400, $gray02, 21, -.4px){
					display:block;
				};
			}
		}
		&.red {
			> li {
				padding-left: 8px;
				&:before {
					background-color:$rred;
				}
				@include font_sum(14, 400, $rred, 21, -.4px){
					display:block;
				};
			}
		}
	}
	&.bar { //bar
		> li {
			padding-left:10px;
			&:before {
				width:5px;
				height:1px;
				background-color:$black;
				@include sudo_pos(8px,0,0,0);
				transform:rotate(0deg);
			}
		}
	}
	&.star { //star
		> li {
			padding-left:10px;
			&:before {
				content:'*' !important;
				color:$black;
				@include sudo_pos(3px,0,0,-2px);
			}
		}
	}
	&.alert { //alert
		> li {
			color:$red;
			&:before {
				background-color:$red;
			}
		}
	}
}
/* //블렛 정의 */