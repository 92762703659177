@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 전체메뉴 */
.ui_accordion {
	.head {
		&.mix_type {
			display:flex;
			justify-content:space-between;
			a {
				white-space:nowrap;
			}
		}
	}
}
/* //바텀 네비게이션 */
