@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


div.slide_wrap {
	position:relative;
	visibility: hidden;
	.indi_wrap {
		position:absolute;
		left:0;
		right:0;
		bottom:0;
		z-index:10;
		.indi_conts {
			font-size:0;
			text-align:center;
			> li {
				display:inline-block;
				line-height:0;
				font-size:0;
				.btn_indi {
					> span {
						@include hidden;
					}
				}
				& + li {
					margin-left:8px;
				}
			}
		}
		.indi_control {
			position:absolute;
			bottom:0;
			right:0;
			width:20px;
			height:20px;
			> span {
				@include hidden;
			}
		}
	}
	.slide_page_number {
		position:absolute;
		bottom:24px;
		right:24px;
		z-index:10;
		span {
			@include font_pre(0.875rem,700,$white,1.5) {
				opacity:0.5;
			}
			&.current_num {
				font-size:1.25rem;
				opacity:1;
			}
		}
		.total {
			&::before {
				@include sudo_dis(-2px,6px,0,6px) {
					width:1px;
					height:10px;
					background-color:$white;
					opacity:0.5;
					vertical-align:middle;
				}
			}
		}
	}
	//프로스래스바 타입 추가
	.indi_progress {
		position:absolute;
		z-index:10;
		left:0;
		right:0;
		height:2px;
		.track {
			height:2px;
			width:100%;
			// background-color:$gray05;
			.current {
				display:block;
				height:2px;
				background-color:rgba(0,0,0,1);
				> i {
					@include hidden;
				}
			}
		}
	}
	.slide_content {
		.slide_track {
			content:' ';
			display:flex;
			clear:both;
			overflow:hidden;
		}
		.slide_conts {
			position:relative;
			font-size: 0;
			line-height: 0px;
			a {
				background-image:url('/images/mo/shopping/bg_no_img.png');
				background-position: center;
				background-size: cover;
			}
		}
	}
	.slide_controls {
		position:absolute;
		display:block;
		top:50%;
		left:initial;
		max-width:initial;
		z-index:10;
		width:100%;
		transform: translateY(-20px); //버튼사이즈에 절반
		&.pos_top_25 {
			top: 25vw;
		}
		button {
			position:absolute;
			width:40px;
			height:40px;
			opacity: 1;
			@include bg(32px,32px,'/images/mobilehome2/new/ico_arrow_right_32_white.png');
			&.prev {
				left:8px;
				right:initial;
				transform: rotate(180deg);
			}
			&.next {
				left:initial;
				right:8px;
			}
			> span {
				@include hidden;
			}
			&.disabled {
				opacity: 0.3;
			}
		}
	}

	&.ban_type { //배너타입
		overflow: unset;
		margin-bottom:24px;
		.indi_wrap {
			bottom:-20px;
			.indi_conts {
				> li {
					.btn_indi {
						width:6px;
						height:6px;
						background-color:$gray06;
						border-radius:16px;
					}
					&.on {
						.btn_indi {
							width:20px;
							background-color:$black;
						}
					}
				}
			}
		}
		.slide_controls {
			display:none;
			button.disabled {
				opacity: 0;
			}
		}
	}
	// 20231020_[list item 1]
	&.none_indi {
		margin-bottom: 0px !important;
	}
}

div.rolling_wrap {
	.list_wrap{
		position:relative;
		width:100%;
		height:100%;
		overflow:hidden;
		> ul {
			> li {
				> a {
					display:block;
				}
			}
		}
	}
}