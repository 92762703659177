@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 완료 화면 */
.complete_wrap {
	padding-top:16px;
	&::before {
		@include bg(88px,88px,'/images/mo/common/ico_complete_88.png');
		@include sudo_dis(0,auto,16px,auto) {
			display:block;
		};
	}
	.comment {
		text-align:center;
		@include font_pre(1.25rem,700,$black,1.4);
		font-family: 'Happiness-Sans' !important;
		& + .sub_comment {
			margin-top :8px;
			> strong {
				@include font_sum (
					$w: 700,
				);
			}
		}
		> em {
			@include font_pre( inherit, inherit, #01a76b, inherit);
		}
		// 새로고침 버튼이 있읖경우 타입
		&.is_btn {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			> span {
				width: 100%;
				@include font_pre(1.25rem,700,$black,1.4);
				&:last-of-type {
					width: auto;
				}
			}
			.refresh_btn {
				position: static !important;
				bottom: 0px !important;
				margin-left: 8px !important;
				font-size: 0;
				line-height: 0;
				i {
					display: block;
					overflow: hidden;
					border-radius: 50%;
					@include bg(24px, 24px, '/images/mobilehome2/new/ico_refresh_24_white.png');
					background-color: $green02;
				}
			}
		}
	}
	.sub_comment {
		text-align:center;
		@include font_sum ( $c: #727573, $box: block ) {
			text-align:center;
		};
	}
	// 상단 32px
	&._first {
		padding-top: 32px;
	}
}

/* AMY-N01-001T : 목록 이미지 아이템 */
.image_item {
	overflow: hidden;
	position: relative;
	border-radius: 18px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	// &_conts {}
	&_thumbnail {
		position: relative;
		.image {
			position: relative;
			img {
				width: 100%;
				line-height: 0px;
			}
		}
	}
	&_info {
		display: block;
		position: relative;
		height: auto;
		padding: 20px 85px 24px 20px;
		box-sizing: border-box;
		background: $white;
		> .badge {
			font-size: 0;
			line-height: 0;
			.flag_wrap {
				& + .flag_wrap {
					margin-left: 4px;
				}
			}
			& + .title {
				margin-top: 12px;
			}
		}
		> .title {
			max-height: 48px;
			span {
				@include font_sum (
					$s: 16,
					$w: 700,
					$h: 24,
					$l: -.25px
				);
				@include ellipsis(2, auto);
				font-size:16px;
			}
			&._line01 {
				padding-right: 47px;
				span {
					@include ellipsis(1, auto);
					font-size:14px;
				}
			}
			&  + .text {
				margin-top: 4px;
			}
		}
		> .text {
			&.inline {
				> span {
					display:inline-block;
				}
			}
			> span {
				@include font_sum(
					$c: $gray02,
					$box: block // 20230915 디자인변경건으로 인산 수정, // 20231004 데이터와 날짜 별도로 적용하기 위해 추가 요청에 의해 처리
				);
				font-size:14px;
				& + .d_day {
					margin-left: 8px;
				}
			}
			.d_day {
				// position: absolute;
				top: 54px;
				right: 20px;
				@include font_sum (
					$s: 12,
					$w: 700,
					$c: $green02,
					$h: 18,
					$l: -.2px,
					$box: inline-block
				);
				font-weight: 700;
				&.d14 {
					color: $green02;
				}
				&.d3 {
					color: $rred;
				}
			}
			> ul {
				> li {
					span {
						@include font_sum (
							$c: $gray02,
						);
						& + span {
							position: relative;
							margin-left: 8px;
							padding-left: 9px;
							&::before {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 0;
								width: 1px;
								height: 11px;
								opacity: 0.1;
								transform: translateY(-50%);
								background-color: #000;
							}
						}
					}
				}
			}
		}
	}
	&_button {
		display: flex;
		position: absolute;
		right: 20px;
		bottom: 24px;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	// 찜하기 버튼 없는 형태
	&.heart_btn_none {
		.image_item_info {
			padding: 20px 20px 24px;
		}
	}
}
.small_image_item {
	position: relative;
	overflow: hidden;
	padding: 56px 20px 26px;
	border-radius: 16px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	background-color: $white;
	&_conts {
		display: flex;
		column-gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
	}
	&_thumbnail {
		width: auto;
		flex-shrink: 0;
		.image {
			overflow: hidden;
			width: 120px;
			height: 120px;
			border-radius: 16px;
			img {
				width: 100%;
				object-fit: cover;
			}
		}
	}
	&_info {
		width: 100%;
		margin-bottom: 21px;
		padding-bottom: 9px;
		> .title {
			span {
				@include font_sum (
					$w: 700,
				);
				@include ellipsis(2, auto);
				word-break:keep-all; // 231106_단어단위로 줄바꿈
			}
			& + .date { margin-top: 4px }
		}
		> .date,
		> .place {
			font-size: 0;
			line-height: 0;
			span {
				@include font_sum (
					$s: 12,
					$c: $gray02,
					$h: 18,
					$l: -.2px,
					$box: inline-block
				);
			}
		}
		> .place + .money {
			margin-top: 8px;
		}
		> .money {
			.em {
				@include font_sum (
					$w: 700,
					$c: $green02,
				);
				& + .through {
					margin-left: 7px;
				}
			}
			.through {
				@include font_sum (
					$c: $gray04,
				);
				text-decoration: line-through;
				& + .em {
					margin-left: 7px;
				}
			}
		}
	}
	&_button {
		> .badge {
			position: absolute;
			top: 20px;
			font-size: 0;
			line-height: 0;
			> .flag_wrap + .flag_wrap {
				margin-left: 8px;
			}
		}
		> .btn_wrap.ico {
			position: absolute;
			right: 20px;
			bottom: 20px;
		}
	}
	&._btn_is_heart {
		padding: 20px;
		// .small_image_item_conts {
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: flex-start;
		// 	.small_image_item_info {
		// 		margin-bottom: 0px;
		// 		> .title {
		// 			& + .money {
		// 				margin-top: 12px;
		// 			}
		// 		}
		// 		> .money {
		// 			> .em {
		// 				@include font_sum (
		// 					$s: 15,
		// 					$w: 700,
		// 					$c: $green02,
		// 					$h: 22,
		// 				);
		// 			}
		// 		}
		// 	}
		// }
	}
	&._goods {
		padding: 20px;
		.small_image_item_conts {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.small_image_item_info {
				margin-bottom: 0px;
				> .title {
					& + .money {
						margin-top: 12px;
					}
				}
				> .money {
					> .em {
						@include font_sum (
							$s: 15,
							$w: 700,
							$c: $green02,
							$h: 22,
						);
					}
				}
			}
		}
	}
	&.heart_btn_none {
		.small_image_item_info {
			margin: 0px;
			padding: 0px;
		}
	}
}
.con_view_products {
	position: relative;
	.btn_heart_share {
		display: flex;
		position:absolute;
		right: 0;
		// top: 0;
		top: -1px; //231017_프리즘상세 상단영역에서 1픽셀 간격이 보여서 수정
		justify-content: flex-end;
		@include bg(135px, 34px, '/images/mo/shopping/btn_heart_share.png' );
		.ico_heart_20_green_gray {
			overflow:hidden;
			line-height:10;
			@include bg(20px, 20px, '/images/mo/shopping/ico_heart_20_green_gray.png' );
		}
		.ico_heart_20_green_gray {
			&.on {
				@include bg(20px, 20px, '/images/mo/shopping/ico_heart_20_green_gray_on.png' );
			}
		}
		.ico_share_20_green_gray {
			margin:0 24px 0 16px;
			overflow:hidden;
			line-height:10;
			@include bg(20px, 20px, '/images/mo/shopping/ico_share_20_green_gray.png' ){
				width: 20px !important;
				height: 20px !important;
			};
		}
	}
	.btn_dotbogi_shop_gh {
		position:absolute;
		right: 24px;
		bottom: 24px;
		background-color:#21b27e;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		i {
			position: absolute;
			left: 4px;
			top: 4px;
			@include bg(24px, 24px, '/images/mo/shopping/btn_dotbogi_shop_24_gh.png' );
			font-size: 0;
		}
	}
}
.item_banner {
	position:relative;
	display:block;
	border-radius:25px;
	overflow:hidden;
	.slide_tit_text {
		position:absolute;
		z-index:10;
		left:20px;
		bottom:40px;
		dt {
			position: relative;
			// @include font_pre(1.5rem,700,$white,1.42){
			@include font_pre(24px,700,$white,1.42){
				font-family: 'Happiness-Sans' !important;
			};
			letter-spacing:-1px;
			> .flag_wrap {
				position: absolute;
				top: -9px;
				left: 0px;
				transform: translateY(-100%);
				font-family: 'Pretendard' !important;
			}
		}
		dd {
			margin-top:8px;
			// @include font_pre(0.875rem,400,$white,1.5);
			@include font_pre(14px,400,$white,1.5);
		}
	}
	.slide_info_text_tr {
		/*
		position:absolute;
		z-index:10;
		right:0px;
		top:0px;
		padding:8px 26px 0 0;
		text-align:right;
		// @include font_pre(0.75rem,400,$white,1.5);
		@include font_pre(12px,400,$white,1.5);
		@include bg(129px,34px,'/images/mo/shopping/bg_slide_topright.png');
		box-sizing: border-box;
		background-position-y: 0;
		*/
		// display: flex;
		position: absolute;
		top: 0;
		right: 0;
		width: 129px;
		height: 34px;
		padding-left: 34px;
		@include font_sum (
			// $s: 12,
			$w: 700,
			$c: $white,
			$h: 18,
			$l: -.2px,
			$box: flex
		);
		font-size:12px;
		background-image: url('/images/mo/shopping/bg_slide_topright.png');
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: top right;
		box-sizing: border-box;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
	.slide_info_text_br {
		position:absolute;
		z-index:10;
		right:20px;
		bottom:20px;
		border-radius:4px;
		// @include font_pre(0.75rem,700,$white,1.5);
		@include font_pre(12px,700,$white,1.5);
		padding:1px 8px 1px 8px;
		background:$black;
	}
	&:after { // 231011_[통합테스트:섬네일그라데이션 딤 추가]
		display:block;
		content:'';
		position:absolute;
		z-index:1;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
		// transform: rotate(-180deg); //20231018_[uiux검수 딤 하단 택스트 노출이 수월하게 처리]
	}
}


/* AMY-A01-007T : 대상(가족)선택 바텀 셀렉트 형 */
.owner {
	.top_type {
		display: inline-block;
		position: relative;
		width:auto;
		margin-left:8px;
		padding:0;
		border:none;
		background-color:transparent;
		&:after {
			@include sudo_pos(50%,-24px,0,initial);
			@include bg(20px,20px,'/images/mobilehome2/new/ico_dropdown_20_white.png');
			transform: translateY(-50%);
		}
		.ui_select_text {
			@include font_pre(1.25rem,700,$white,1.4);
			> em {
				margin-right: 4px;
				opacity: 0.6;
				@include font_pre(1.25rem,700,$white,1.4);
			}
		}
	}
	&._black {
		.top_type {
			&:after {
				background-image: url("/images/mobilehome2/mypage/new/ico_arrow_down_20_black.png");
			}
			.ui_select_text {
				@include font_sum (
					$s: 20,
					$w: 700,
					$c: $black,
					$h: 28,
					$l: -1px
				);
				font-family: 'Happiness-Sans' !important;
				> em {
					@include font_sum (
						$s: 20,
						$w: 700,
						$c: $gray02,
						$h: 28,
						$l: -1px
					);
					font-family: 'Happiness-Sans' !important;
				}
			}
		}
		.button_wrap { //231016_지점안내 상단 지점선택 팝업 호출버튼 형식으로 추가변경함(ASV-C01-001)
			margin-left:8px;
			&:after {
				content:none;
			}
			.btn_wrap {
				&.store_select {
					.text {
						@include font_sum (
							$s: 20,
							$w: 700,
							$c: $black,
							$h: 28,
							$l: -1px
						);
						font-family: 'Happiness-Sans' !important;
						> em {
							@include font_sum (
								$s: 20,
								$w: 700,
								$c: $gray02,
								$h: 28,
								$l: -1px
							);
							font-family: 'Happiness-Sans' !important;
						}
					}
				}
			}
		}
	}
}

/* 카드 > 청구계정선택 */
.account_select {
	.btn_sel_type {
		width:auto;
		margin:0;
		padding: 13px 44px 13px 8px;
		border-color:$white;
		span {
			@include font(1.25rem,700,$black,1.4){
				font-family: 'Happiness-Sans' !important;
			};
			> em {
				@include font(1.25rem,700,$gray02,1.4){
					font-family: 'Happiness-Sans' !important;
				};
			}
		}
	}
}

/* 마이카드 > 즉시결제내역 */
.history_state {
	padding:20px;
	// border-radius:12px;
	border-radius:18px;
	background-color:$gray08;
	dl {
		display:flex;
		justify-content:space-between;
		align-items:center;
		dt {
			@include font_pre(0.875rem,400,$gray09,1.5)
		}
		dd {
			@include font_pre(0.875rem,500,$black,1.5);
			.no {
				color:$rred;
				font-weight: 500;
			}
		}
		& + dl {
			margin-top:12px;
		}
	}
}

/* 온라인페이 > 보안비밀번호 네자리 */
.password_check_gh {
	display: flex;
	span {
		em {
			position: relative;
			top: 2px;
			@include font_pre(1rem,800,#000,1.5);
		}
		width: 40px;
		height: 40px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		&.empty {
			border: solid 1px $gray03;
			background-color: $gray05;
			em {
				display: none;
			}
		}
		&.empty.on {
			background-color: $green;
			border-color: $green;
			em {
				display: inline;
				@include font_pre(1rem,800,$white,1.5);
			}
		}
		& + span {
			margin-left: 8px;
		}
	}
}

/* 온라인페이 > 보안비밀번호 여러자리 */
.input_wrap.input_wrap_hidden_privacy {
	input {
		color: white;
	}
	.hidden_privacy_gh {
		position:absolute;
		left: 16px;
		top: 17px;
		letter-spacing: -4px;
		span {
			@include font_pre(0.937rem,400,$gray04,1.47);
			&.on {
				@include font_pre(0.937rem,400,$black,1.47);
			}
		}
	}
}

/* 기간조회 */
.date_term_search {
	display:flex;
	justify-content:space-between;
	align-items:center;
	margin-bottom:24px;
	padding-left:8px;
	padding-right:8px;
	padding-bottom:12px;
	border-bottom:1px solid $gray03;
	.date {
		@include font_pre(0.875rem,400,$black,1.5);
	}
	.btn_ico {
		.search {
			&:before {
				@include sudo_dis(0,2px,0,0);
				@include bg(20px,20px,'/images/mobilehome2/new/ico_calendar_20.png');
			}
			> span {
				@include font_pre(0.875rem,700,$gray02,1.5);
			}
		}
	}
}

/* 이용내역 */
.common_usage_history {
	.top_sec {
		padding:32px 8px;
	}
	.usage_list {
		margin-top:32px;
		dl {
			position:relative;
			padding:20px;
			border-radius:18px;
			box-shadow:0 2px 14px 0 rgba(0, 0, 0, 0.1);
			background-color:$white;
			dt {
				padding-right: 145px;
				@include font_pre(0.937rem,400,$black,1.5);
			}
			dd {
				margin-top:4px;
				.btn_wrap {
					position:absolute;
					top:20px;
					right:20px;
					margin-right: 0px !important;
					margin-left: 0px !important;
					> span {
						@include font_pre(0.937rem,700,$black,1.5);
					}
					.minus {
						color:$rred;
					}
					&.static {
						position:static;
						justify-content: left;
						margin-top:12px;
					}
				}
				&.info {
					.date, .etc {
						display:block;
						span {
							@include font_pre(0.75rem,400,$gray04,1.5);
						}
					}
					.etc {
						margin-top:2px;
						> span + span {
							&:before {
								@include sudo_dis(5px,8px,0,8px);
								width:1px;
								height:11px;
								background-color:$black;
								opacity:0.1;
							}
						}
					}
				}
			}
			& + dl {
				margin-top:24px;
			}
		}
	}
}

.usage_list {
	margin-top:32px;
	> div.no_result {
		margin-top: 80px;
		margin-bottom: 80px;
	}
}


/* 약관 타입01 */
.terms_type01 {
	padding:16px 8px 80px;
	.tit01 {
		@include font_pre(1.125rem,700,$black,1.4);
	}
	.tit03 {
		@include font_sum (
			$s: 15,
			$w: 700,
			$h: 22,
			$box: inherit
		);

	}
	.tit02 {
		margin-top:24px;
		@include font_pre(0.875rem,700,$black,1.5);
		&:first-child {
			margin-top: 0;
		}
	}
	ul {
		margin-top:8px;
		> li  {
			@include font_pre(0.875rem,400,$black,1.5);
		}
	}
	.tb_conts {
		.stxt {
			margin-bottom:12px;
			@include font_pre(0.875rem,500,$gray02,1.5);
		}
	}
}

/* ABI-A01-013B 지점선택 팝업 / 공통 관심있는 지점 선택*/
.lay_conts {
	&._common {
		padding-right: 24px !important;
		padding-left: 24px !important;
		.top_sec {
			padding-top: 16px;
			.tit02 + .sub_desc {
				margin-top: 8px;
				word-break: keep-all;
			}
		}
		.office_select_wrap {
			padding-top: 40px;
			padding-bottom: 80px;
		}
	}
	&._select {
		// padding-top: 24px !important; // 231004_제목과 컨텐츠사이 간격 24px로 통일(ABI-A01-013B)
		padding-top:0;
		& + .lay_bot_btns {
				padding: 0 16px !important;
		}
	}
	.office_select {
		& + .office_select {
			margin-top: 32px;
		}
		&_title {
			padding-left: 8px;
			span { @include font_sum($s: 16, $w: 700, $h: 24, $l: -.25px); }
		}
		&_title + .office_select_conts { margin-top: 12px; }
		&_conts {
			> ul {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				> li {
					width: calc(50% - 4px);
					margin-top: 8px;
					&:nth-child(-n + 2) { margin-top: 0px; }
					.rdo_wrap {
						&.round_btn {
							width: 100%;
							label {
								display: flex;
								padding: 11px 12px 13px;
								border: 1px solid #eaebea;
								border-radius: 33px;
								align-items: center;
								justify-content: center;
								&::before { content:none; }
								span {
									@include font_sum($s: 15, $h: 22);
									em {
										margin-left: 5px;
										@include font_sum (
											$s: 15,
											$w: 500,
											$c: #01a76b,
											$h: 22,
										);
									}
								}
							}
							input:checked {
								& + label {
									background-color: #01a76b;
									border: 1px solid #01a76b;
									span {
										font-weight: 700;
										color: $white;
										em {
											font-weight: 700;
											color: $white;
										}
									}
								}
							}
							input:disabled + label {
								pointer-events: none;
								background-color:$gray05;
								span { color:$gray01; }
							}
						}
					}
				}
				&.all_select_box {
					> li {
						width: 100%;
					}
				}
			}
		}
	}
}

.address_from {
	> dt {
		padding-left: 8px;
		@include font_sum ( $s: 15, $w: 700, $h: 22, $box: block );
		& + dd { margin-top: 8px; }
	}
}

/* 쿠폰 : AMY-A01-001T : 패키지 쿠폰 */
.accordion_coupon_list {
	overflow: hidden;
	border-radius: 18px;
	margin-bottom: 12px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	.icon_wrap {
		i.icon {
			&_arrow {
				display: block;
				line-height: 0;
				@include bg(20px, 20px, '/images/mo/common/ico_dropdown_20_black.png');
				& + span {
					@include hidden();
				}
			}
		}
	}
	.ui_accordion {
		> ul {
			> li {
				background-color: $white;
				> .head {
					position: relative;
					font-size: 0;
					line-height: 0;
					.coupon_title {
						position: relative;
						.text_area {
							position: absolute;
							display: flex;
							width: 100%;
							height: 100%;
							padding: 20px;
							top: 0;
							left: 0;
							box-sizing: border-box;
							z-index: 2;
							flex-direction: column;
							justify-content: flex-end;
							align-items: flex-start;
							> .flag {
								position: static;
								padding-bottom: 0px;
								margin: 0px;
								margin-bottom: 8px;
								.flag_wrap {
									padding: 0px 7px;
									span {
										padding: 0;
										margin: 0px;
										@include font_sum (
											$s: 12,
											$w: 500,
											$c: $white,
											$h: 18,
											$l: -.2px
										);
										background: transparent;
									}
								}
							}
							> .title {
								strong {
									@include font_sum (
										$s: 16,
										$w: 700,
										$c: $white,
										$h: 24,
										$l: -.25px,
										$box: block
									);
								}
								& + .text { margin-top: 8px }
							}
							> .text {
								p {
									@include font_sum (
										$s: 12,
										$c:  rgba(255, 255, 255, 0.6),
										$h: 18,
										$l: -.2px,
										$box: block
									);
								}
							}
						}
						.img_area {
							position: relative;
							width: 100%;
							z-index: 1;
						}
					}
				}
				.ui_accord_toggle {
					display: flex;
					position: absolute;
					width: 162px;
					height: 34px;
					right: 0;
					bottom: 0;
					align-items: center;
					justify-content: flex-end;
					@include bg(162px, 34px, '/images/mobilehome2/mypage/new/' + 'img_button_bg_01.png');
					z-index: 3;
					> .button_area {
						display: flex;
						height: 100%;
						margin: 0px !important;
						padding-right: 12px;
						align-items: center;
						justify-content: flex-end;
						box-sizing: border-box;
						background: $white;
						p {
							@include font_sum;
							em {
								@include font_sum($c: $green02, $l: -.25px);
								min-width: 10px;
								margin: 0 4px;
							}
						}
						.icon_arrow {
							transition: all 0.3s ease-in;
							transform: rotate(0deg);
						}
					}
				}
			}
			> li.on {
				.ui_accord_toggle {
					> .button_area {
						.icon_arrow {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	.coupon_list {
		padding: 8px 20px 4px;
		.coupon_item + .coupon_item { border-top: 1px solid $gray03; }
	}
}

/* 쿠폰 : AMY-A01-001T ~ AMY-A01-019T : 이미지 그림자 떄문에 상단 12 하단 16 좌우 16 공간을 가지고 있습니다. */
.coupon_img_box {
	$mypage_url: '/images/mobilehome2/mypage/new/';

	position: relative;
	background-image: url($mypage_url + 'img_mycoupon_shape_01.png');
	background-position: center center;
	background-size: 100%;
	background-repeat: no-repeat;
	&::before {
		content: '';
		position: relative;
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 45.834%; // padding-bottom: 165px;
		z-index: -1;
	}
	.coupon_item_wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3.337% 4.445% 4.445%; // 12 16
		top: 0;
		left: 0;
		box-sizing: border-box;
	}
	.coupon_item {
		display: flex;
		height: 100%;
		width: 100%;
		padding-left: 6.1%; // 20
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		.flag_area {
			top: -8px;
		}
		.coupon_item_area {
			padding: 0px;
			height: 100%;
			box-sizing: border-box;
			.contents_wrap {
				.info_title {
					margin-right: 20px;
				}
			}
			> .button_wrap {
				display: flex;
				width: 21.43%; // 66
				margin: 0px;
				justify-content: center;
				align-items: center;
				.text_em {
					width: 100%;
					text-align: center;
					flex-direction: column;
					align-content: center
				}
				span {
					// width: auto;
					text-align: center;
					word-break: keep-all;
				}
			}
		}
	}
	&._dis,
	&.end,
	&.full {
		background-image: url( $mypage_url + 'img_mycoupon_shape_01_off.png');
		.coupon_item_area {
			opacity: 0.3;
			> .button_wrap .text_em {
				span { color: #171a19; }
				i { display: none; }
			}
		}
	}
	&.shape_01 {
		&::before { padding-bottom: 45.834%; /* height: 165px; */ }
		background-image: url( $mypage_url + 'img_mycoupon_shape_01.png');
		&._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_off.png');
		}
		&.no_btn {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_no_btn.png');
			.coupon_item .contents_wrap {
				padding-right: 21%;
			}
		}
		&.no_btn._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_off_no_btn.png');
		}
	}
	&.shape_02 {
		background-image: url( $mypage_url + 'img_mycoupon_shape_02.png');
		&::before { padding-bottom: 51.667%; /* height: 186px; */ }
		&._dis { background-image: url( $mypage_url + 'img_mycoupon_shape_02_off.png'); }
		&.no_btn {
			background-image: url( $mypage_url + 'img_mycoupon_shape_02_no_btn.png');
			.coupon_item .contents_wrap {
				padding-right: 22%;
			}
		}
		&.no_btn._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_02_off_no_btn.png');
		}
	}
	&.shape_03 {
		background-image: url( $mypage_url + 'img_mycoupon_shape_01.png');
		&::before { padding-bottom: 45.834%; /* height: 165px; */ }
		&._dis { background-image: url( $mypage_url + 'img_mycoupon_shape_01_off.png'); }
		&.no_btn {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_no_btn.png');
			.coupon_item .contents_wrap {
				padding-right: 22%;
			}
		}
		&.no_btn._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_off_no_btn.png');
		}
	}
	&.shape_04 {
		background-image: url( $mypage_url + 'img_mycoupon_shape_04.png');
		&::before { padding-bottom: 46.11%; /* height: 166px; */ }
		&._dis { background-image: url( $mypage_url + 'img_mycoupon_shape_01_off.png'); }
		&.no_btn {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_no_btn.png');
			.coupon_item .contents_wrap {
				padding-right: 22%;
			}
		}
		&.no_btn._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_01_off_no_btn.png');
		}
		.coupon_item_area .contents_wrap .info_title {
			@include ellipsis(3, auto);
			max-height: 72px;
		}
	}
	&.shape_05 {
		background-image: url( $mypage_url + 'img_mycoupon_shape_05.png');
		&::before { padding-bottom: 45.27%; /* height: 163px; */ }
		&._dis { background-image: url( $mypage_url + 'img_mycoupon_shape_05_off.png'); }
		&.no_btn {
			background-image: url( $mypage_url + 'img_mycoupon_shape_05_no_btn.png');
			.coupon_item .contents_wrap {
				padding-right: 22%;
			}
		}
		&.no_btn._dis {
			background-image: url( $mypage_url + 'img_mycoupon_shape_05_off_no_btn.png');
		}
	}
	&.module {
		overflow: hidden;
		border-radius: 18px;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
		background: none;
		&::before {
			display: none;
		}
		.coupon_item_wrap {
			position: initial;
			width: 100%;
			height: auto;
			padding: 32px 20px;
			.coupon_item {
				padding: 0px;
			}
		}
	}
	& + .coupon_img_box {
		margin-top: -4px;
	}
}

/* 쿠폰 : AMY-A01-001T ~ AMY-A01-019T : 겉 이미지 제외한 내부 아이템 박스 입니다. */

.coupon_item {
	$mypage_url: '/images/mobilehome2/mypage/new/';
	.icon_wrap {
		i.icon {
			&_new {
				display: flex;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				background-color: $pred;
				span {
					padding-top: 1px;
					@include font_sum (
						$s: 8,
						$w: 900,
						$c: $white,
						$h: 8,
						$l: -.2px,
						$box: inline-block
					);
				}
			}
		}
	}
	.btn_wrap {
		.text_em {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			span {
				@include font_sum (
					$w: 700,
					$c: #01a76b,
				);
			}
			i {
				display: none;
				margin-top: 2px;
			}
			.icon_barcode {
				display: inline-block;
				@include bg(24px, 24px, $mypage_url + 'ico_barcode_24_green.png');
			}
			.icon_download {
				display: inline-block;
				@include bg(24px, 24px, $mypage_url + 'ico_download_24_green.png');
			}
		}
	}
	position: relative;
	.flag_area {
		position: absolute;
		display: flex;
		top: 0px;
		left: 0;
		align-items: flex-start;
		justify-content: flex-start;
		> * + * {
			margin-left: 4px;
		}
	}
	.coupon_item_area {
		display: flex;
		position: relative;
		width: 100%;
		padding: 16px 0;
		align-items: center;
		justify-content: flex-start;
		box-sizing: border-box;
		> a {
			display: flex;
			width: 100%;
			min-height: 100%;
			align-items: center;
			justify-content: flex-start;
		}
	}
	.thumbnail {
		&_wrap {
			display: block;
			position: relative;
			width: auto;
			flex-shrink: 0;
			.icon_wrap {
				position: absolute;
				top: -2px;
				left: -2px;
				z-index: 1;
			}
		}
		&_area {
			position: relative;
			display: flex;
			overflow: hidden;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			&.sale {
				@include bg( 48px, 48px, $mypage_url + 'img_sale_thumbnail_thum.png');
				> img { display: none; }
				&._03 { @include bg( 48px, 48px, $mypage_url + 'img_sale_thumbnail_3_thum.png'); }
				&._05 { @include bg( 48px, 48px, $mypage_url + 'img_sale_thumbnail_5_thum.png'); }
				&._10 { @include bg( 48px, 48px, $mypage_url + 'img_sale_thumbnail_10_thum.png'); }
			}
			&.amountdiscount {
				@include bg( 48px, 48px, $mypage_url + 'img_amountdiscount_thum.png');
				> img { display: none; }
				&._5000 { @include bg( 48px, 48px, $mypage_url + 'img_amountdiscount_thum_5000.png'); }
				&._10000 { @include bg( 48px, 48px, $mypage_url + 'img_amountdiscount_thum_10000.png'); }
				&._100000 { @include bg( 48px, 48px, $mypage_url + 'img_amountdiscount_thum_100000.png'); }
			}
			&.interestfree {
				> img { display: none; }
				@include bg( 48px, 48px, $mypage_url + 'img_interestfree_thum.png');
			}
			&.parking {
				> img { display: none; }
				@include bg( 48px, 48px, $mypage_url + 'img_parking_thum.png');
			}
		}
	}
	.contents_wrap {
		width: 100%;
		margin-left: 16px;
		.info {
			&_area {
				display: block;
			}
			&_branch {
				span { @include font_sum; }
			}
			&_branch + .info_title { margin-top: 2px; }
			&_title {
				@include ellipsis(2, 48px);
				height: auto;
				max-height: 48px;
				span {
					@include font_sum (
						$s: 16,
						$w: 700,
						$c: $black,
						$h: 24,
						$l: -.25px,
						$box: block
					){
						word-break: break-all;
					};
				}
			}
			&_text {
				span {
					@include font_sum (
						$w: 500,
						$c: $gray04,
					);
				}
			}
			&_date {
				margin-top: 8px;
				span {
					@include font_sum (
						$s: 12,
						$c: $gray04,
						$h: 18,
						$l: -.2px,
					);
					& + em {
						margin-left: 8px;
					}
				}
				em {
					@include font_sum (
						$s: 12,
						$w: 700,
						$c: $green02,
						$h: 18,
						$l: -.2px,
					);
				}
			}
		}
	}
	.button_wrap {
		width: auto;
		margin-left: 28px;
		flex-shrink: 0;
		> .btn_wrap  {
			width: 48px;
			> .btn {
				padding: 7px 0px;
				border-radius: 8px;
				word-break: keep-all;
			}
		}
	}
	&.end,
	&.full,
	&._dis {
		.thumbnail_wrap {
			opacity: .5;
		}
		.contents_wrap {
			opacity: .3;
		}
		.button_wrap {
			> .btn_wrap  {
				> .btn {
					border: solid 1px #eaebea;
					background-color: #f7f7f7;
					span {
						opacity: 0.3;
						color: #171a19;
					}
				}
			}
		}
	}
	&._medium {
		overflow: hidden;
		width: 100%;
		border: 1.4px solid transparent;
		border-radius: 18px;
		background-color: $white;
		box-sizing: border-box;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
		> .coupon_item {
			padding: 0px !important;
		}
		.coupon_item_area {
			padding: 32px 20px;
		}
		&.end,
		&.full,
		&._dis {
			border: 1.4px solid #eaebea;
			background-color: #f7f7f7;
			> .coupon_item_area {
				> a {
					pointer-events: none;
				}
			}
		}
	}
}

/* 리스트 아이템 : 검색 AET-D01-003 젤리리스트 사용 */
.icon_list_item {
	overflow: hidden;
	padding: 20px;
	border-radius: 18px;;
	background-color: #fff;
	box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.12);
	.icon_list_item_conts {
		display: flex;
		column-gap: 12px;
		justify-content: flex-start;
		align-items: center;
		.thumbnail_wrap {
			overflow: hidden;
			width: 48px;
			height: 48px;
			flex-shrink: 0;
			border-radius: 50%;
			background-color: #f7f7f7;
			.thumbnail {
				padding: 4px;
				> img {
					width: 100%;
				}
			}
		}
		.contents_wrap {
			.text_area {
				.title {

					span {
						@include font_sum (
							$s: 16,
							$w: 700,
							$h: 24,
							$l: -.25px,
							$box: block
						);
						@include ellipsis(1, auto);
					}
				}
				.date {
					span {
						@include font_sum (
							$c: #727573,
						);
					}
				}
			}
		}
	}
}

/* 리스트 아이템 : 검색 AET-D01-003 모바일 사은정보 사용 */
.text_list_item {
	position: relative;
	padding: 20px;
	border-radius: 18px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	&_body {
		> strong.title {
			@include font_sum (
				$s: 16,
				$w: 700,
				$h: 24,
				$l: -.25px,
				$box: block
			);
			@include ellipsis(2, auto);
			& + span.date {
				margin-top: 4px;
			}
		}
		> span.date {
			@include font_sum (
				$c: #727573,
				$box: block
			);
		}
		> em.d_day {
			position: absolute;
			bottom: 20px;
			right: 20px;
			@include font_sum (
				$w: 700,
				$c: #01a76b,
				$box: inline-block
			);
		}
	}
}

/*230706 1-1 수정된 패키지 모듈*/
.accordion_coupon_list_shop {
	overflow: hidden;
	border-radius: 18px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
	.ui_accordion {
		> ul {
			> li {
				> .head {
					position: relative;
					.coupon_title {
						display: flex;
						padding: 20px;
						.img_area {
							margin-right: 16px;
							img {
								width: 120px;
								height: 120px;
								border-radius: 10px;
							}
						}
						.text_area {
							> .flag {
								position: unset;
								margin-bottom: 12px;
								background-color: transparent;
								.flag_wrap {
									span {
										padding: 0;
										margin: 0px;
										background: transparent;
										color: white;
									}
								}
							}
							> .title {
								@include font_pre(1rem,700,$black,1.5);
							}
							> .text {
								margin-top: 8px;
								@include font_pre(0.75rem,400,$gray04,1.5);
							}
						}
					}
				}
				.ui_accord_toggle {
					display: block;
					padding-top: 16px;
					border-top: 1px solid $gray03;
					margin: 0 20px;
					padding: 16px 0 20px 0;
					> .button_area {
						display: flex;
						justify-content: space-between;
						p {
							@include font_sum(14, 400, $black, 21, -.4px);
							em {
								@include font_sum(14, 700, #01a76b, 21, -.25px) {
									min-width: 10px;
									margin: 0 4px;
								};
							}
						}
						.icon_arrow {
							position:relative;
							top: 1px;
							transition: all 0.3s ease-in;
							display: block;
							// @include bg(20px, 20px, '/images/mo/store/ico_arrow_up_20_black.png');
							@include bg(20px, 20px, '/images/mo/common/ico_dropdown_20_black.png');
							& + span {
								@include hidden();
							}
						}
					}
					&[aria-expanded="false"] > .button_area .icon_arrow{
						transform: rotate(0deg);
					}
					&[aria-expanded="true"] > .button_area .icon_arrow{
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.coupon_list {
		padding: 4px 20px;
		// .coupon_item + .coupon_item { border-top: 1px solid #eaebea; }
	}
	.ui_accord_content .coupon_list > div:first-of-type.coupon_item .coupon_item_area {
		padding-top: 0px !important;
	}
}

/* 쿠폰 - 행사안내상세용 */
.park_list_free_ticket {
	padding-top: 15px;
	padding-left: 16px;
	padding-right: 16px;
	&.coupon_item_bgheight_l {
		li {
			box-sizing: content-box;
			dl {
				position:relative;
				top:-5px;
				margin-left: 60px;
				max-width: 67%;
				&:before {
					@include sudo_pos(50%,auto,auto,-60px){
						transform:translateY(-50%);
						@include bg(48px,48px,'/images/mo/shopping/shop_coupon_bg_list.png');
						background-color: #daf5f0;
						border-radius:50%;
					}
				}
			}
			@include bg(86%,140px,'/images/mo/service/coupon_bg_list.png'){
				background-position:0 0;
				background-size:100% 212px;
			};
		}
	}
	li {
		position: relative;
		display:flex;
		align-items: center;
		margin-left:-16px;
		padding:36px calc(7% + 16px);
		dt {
			@include font_pre(1rem,700,$black,1.5);
			.flag_wrap {
				margin-left:5px;
				position:relative;
				top:-2px;
			}
			span {
				display: block;
				@include font_pre(0.875rem,400,$black,1.5);
				margin-bottom: 2px;
			}
		}
		dd {
			@include font_pre(0.875rem,500,$gray04,1.5);
			& + dd {
				margin-top: 8px;
				@include font_pre(0.75rem,400,$gray04,1.5);

			}
		}
		button {
			position: absolute;
			right: 8.5%;
			top: 48%;
			transform:translateY(-50%);
			@include font_pre(0.875rem,700,$green02,1.5);
		}
	}
	li + li {
		margin-top:-35px;
	}
}

/* 약관 전용 */
.terms_wrap {
	&.service { //ASV-B01-012P (위치기반서비스)
		padding-top:32px !important;
		padding-left:24px !important;
		padding-right:24px !important;
		h2.top-tit {
			padding-bottom:24px;
			@include font_pre(1.25rem,700,$black,1.4);
		}
		.top-tit {
			padding-bottom:24px;
			@include font_pre(1rem,700,$black,1.4);
		}
		.part1 {
			padding-bottom:24px;
			.part1-top {
				.p1-tit {
					@include font_pre(0.875rem,700,$black,1.5);
				}
				& + .txt {
					margin-top:8px;
				}
			}
			.txt {
				@include font_pre(0.875rem,400,$black,1.5);
				li + li {
					margin-top:8px;
				}
			}
			.table_wrap {
				margin-top:24px;
			}
		}
	}
	&.identity { //회원,인증용
		padding-top:32px !important;
		padding-left:24px !important;
		padding-right:24px !important;
		h2.top-tit {
			padding-bottom:24px;
			@include font_pre(1.25rem,700,$black,1.4);
		}
		.top-tit {
			padding-bottom:24px;
			@include font_pre(1rem,700,$black,1.4);
		}
		.part1 {
			padding-bottom:24px;
			.part1-top {
				.p1-tit {
					@include font_pre(0.875rem,700,$black,1.5);
				}
				& + .txt {
					margin-top:8px;
				}
			}
			.txt {
				@include font_pre(0.875rem,400,$black,1.5);
				li + li {
					margin-top:8px;
				}
				& + .txt {
					margin-top:8px;
				}
			}
			.table_wrap {
				margin-top:24px;
			}
		}
	}
}

/* 캐러셀 타입별 모듈 정의 */
.slide_wrap {
	overflow:hidden;
	&.module01,
	&.module02 {
		position: relative;
		// width: 100vw;
		// left: 50%;
		// transform: translateX(-50%);
		// .slide_content { overflow: initial !important; } // 그림자 처리
		box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.12);
		.slide_content {
			border-radius: 18px;
			.slide_track {
				// overflow: initial !important; // 그림자 처리
				.slide_conts {
					position: relative;
					display: flow-root;
					// float: none !important;
					overflow: hidden;
					margin: 0 16px;
					// border-radius: 18px;
					font-size: 0;
					line-height: 0;
					// box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.12);
					// 컨텐츠 이미지 영역
					.slide_box,
					.ui_bnr_interaction {
						display: inline-block;
						position: relative;
						min-width: 100%;
						&::before {
							content: '';
							display: block;
							position: relative;
							width: 100%;
							height: 0px;
							padding-bottom: 140.329%;
						}
						> img {
							position: absolute;
							width: 100%;
							top: 0;
							left: 0;
						}
					}
					// 텍스트 영역
					.txt_in_slide_trend {
						position: absolute;
						margin: 0 0 56px 20px;
						left: 0;
						bottom: 0;
						// flag
						em {
							margin: 0 0 8px 0;
							padding: 1px 8px;
							border: 1px solid $white;
							border-radius: 4px;
							@include font_sum(12, 500, $white, 18, -0.2px) { background-color: $white; };
							@mixin _color($color) {
								border-color: $color;
								color: $color;
							}
							&.color01 { @include _color($green02); }
							&.color02 { @include _color(#ef9e00); }
							&.color03 { @include _color(#3a5fff); }
							&.color04 { @include _color(#ff4bcd); }
						}
						span {
							display: block;
							padding-right: 20px;
							letter-spacing: -1px;
							@include font_sum(24, 700, $white, 34, -1px);
							@include ellipsis (2, auto);
						}
					}

					// 찜하기 영역
					.btn_wrap {
						position: absolute;
						display: flex;
						padding-right: 20px;
						top: 0;
						right: -1px;
						align-items: center;
						justify-content: flex-end;
						@include bg(168px, 48px, '/images/mo/shopping/btn_trend_heart_share.png'){
							background-position-x: 100%;
							background-position-y: top;
						};
						z-index: 5;
					}
					// 더보기 버튼
					.btn_slide_seemoore {
						display: none;
						&:after {
							@include sudo_pos(20px,auto,auto,63px){
								display:block;
								@include bg(16px,16px,'/images/mo/shopping/btn_slide_seemoore.png');
							};
						}
					}
					&:last-of-type {
						.btn_slide_seemoore {
							display: block;
							z-index: 2;
							width: 93px;
							height: 56px;
							background:$green02;
							padding: 17px 0 0 20px;
							position: absolute;
							right: 0;
							top: 50%;
							border-radius: 29px 0 0 29px;
							transform:translateY(-50%);
							@include font_pre(0.937rem,700,$white,1.47);
						}
					}
					// 더보기 버튼 싱글일경우 안나오게 처리
					&:first-of-type .btn_slide_seemoore {
						visibility: hidden;
					}
					// 동영상 플레이 버튼
					.btn_movie_slide_trend {
						position: absolute;
						left: 50%;
						top: 50%;
						transform:translate(-50%,-50%);
						@include bg(48px,48px,'/images/mo/shopping/ico_trend_32_movie.png');
					}
				}
			}
		}

		// page_num
		.slide_page_number {
			right: 40px;
			bottom: 24px;
			padding: 1px 8px 2px 8px;
			border-radius: 25px;
			background-color: rgba(23,26,25,0.5);
			.current_num {
				@include font_pre(0.75rem,700,$white,1.42);
				letter-spacing: -0.4px;
			}
			.total_num {
				@include font_pre(0.75rem,700,$white,1.42);
				letter-spacing: -0.4px;
				opacity: 0.4;
			}
			.total::before {
				height: 9px;
				opacity: 0.3;
			}
		}
		// 화살표 컨트롤러
		.slide_controls {
			margin: 0 16px;
			width: calc(100% - 32px);
				// 더보기 버튼 처리를 위해 버튼 숨김처리
			.ui_carousel_next:disabled {
				display: none !important;
			}
		}
	}

	&.module_type {
		box-shadow: 0 4px 48px 0 rgba(0,0,0,0.12);
		border-radius: 18px;
		position: relative;
		.slide_conts {
			// 찜하기 영역
			.btn_wrap {
				position: absolute;
				display: flex;
				padding-right: 20px;
				top: 0;
				right: -1px;
				align-items: center;
				justify-content: flex-end;
				@include bg(168px, 48px, '/images/mo/shopping/btn_trend_heart_share.png'){
					background-position-x: 100%;
					background-position-y: top;
				};
				z-index: 5;
			}
			.btn_slide_seemoore {
				display: none;
				&:after {
					@include sudo_pos(20px,auto,auto,63px){
						display:block;
						@include bg(16px,16px,'/images/mo/shopping/btn_slide_seemoore.png');
					};
				}
			}
			&:last-of-type {
				.btn_slide_seemoore {
					display: block;
					z-index: 2;
					width: 93px;
					height: 56px;
					background:$green02;
					padding: 17px 0 0 20px;
					position: absolute;
					right: 0;
					top: 50%;
					border-radius: 29px 0 0 29px;
					transform:translateY(-50%);
					box-sizing: border-box;
					@include font_pre(0.937rem,700,$white,1.47);
				}
			}
		}
		.slide_content {
			border-radius: 18px;
		}
		.slide_content img {
			position:relative;
			border-radius: 18px;
			box-shadow: 0 4px 48px 0 rgba(0,0,0,0.12);
			&.bg_img {
				position:absolute;
				top:0;
				left:0;
				z-index: -1;
			}
		}
		.slide_page_number {
			right: 20px;
			bottom: 24px;
			padding: 1px 8px 2px 8px;
			border-radius: 25px;
			background-color: rgba(23,26,25,0.5);
			.current_num {
				@include font_pre(0.75rem,700,$white,1.42);
				letter-spacing: -0.4px;
			}
			.total_num {
				@include font_pre(0.75rem,700,$white,1.42);
				letter-spacing: -0.4px;
				opacity: 0.4;
			}
			.total::before {
				height: 9px;
				opacity: 0.3;
			}
		}
		.slide_controls {
			// button {
			// 	@include bg(40px,40px,'/images/mo/shopping/ico_slide_controls_next_40.png');
			// }
			.disabled {
				opacity: 0.5;
			}
			.next.disabled {
				display: none !important;
			}
		}
		.btn_movie_slide_trend {
			position: absolute;
			left: 50%;
			top: 50%;
			transform:translate(-50%,-50%);
			opacity: 0.8;
			@include bg(48px,48px,'/images/mo/shopping/ico_trend_32_movie.png');
		}
		& + .slide_trend_lists,
		& + .module_type {
			margin-top: 56px;
		}

		.txt_in_slide_trend {
			position: absolute;
			left: 20px;
			bottom: 55px;
			em {
				display: inline-block;
				margin:0 0 8px 0;
				padding: 1px 8px;
				border-radius: 4px;
				letter-spacing: -0.2px;
				@include font_pre(0.75rem,500,$white,1.5);
				background-color: rgba(23, 26, 25, 0.8);
			}
			span {
				display: block;
				padding-right: 20px;
				letter-spacing: -1px;
				@include font_pre(1.5rem,700,$white,1.42){
					font-family: 'Happiness-Sans' !important;
				};
				@include ellipsis (2,68px);
			}
		}

		&:last-of-type {
			margin-bottom: 20px;
		}

		.slide_box {
			display:block;
			border-radius: 16px;
			width:100%;
			// height: 125vw; // 20231016_[동영상 슬라이드일때에 화면 삐져나오는 부분 수정]
			background-image:url('/images/mo/shopping/bg_no_img.png');
			background-position: center;
			background-size: cover;
			&:after { // 230919_[통합테스트:섬네일그라데이션 딤 추가]
				display:block;
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				// background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
				// background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) 19%, rgba(0, 0, 0, 0) 100%);
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32) -100%, rgba(0, 0, 0, 0) 100%); // 20231024_[그라데이션 크기가 280 정도여서 수치 수정]
				// transform: rotate(-180deg); // 20231018_[딤효과 하단으로 가게끔 수정]
			}
		}
	}
}

/* 해시태그 */
.list_view_hash {
	display: flex;
	margin: 24px 24px 0;
	column-gap: 16px;
	row-gap: 13px;
	flex-wrap: wrap;
	li {
		a {
			@include font_sum (
				$s: 15,
				$w: 500,
				$h: 22,
			);
			span {
				margin-right: 3px;
				@include font_sum (
					$s: 15,
					$w: 500,
					$c: #00ce84,
					$h: 22,
				);
			}
		}
	}
}

.hashtag_area {
	margin:24px 8px 0;
	.hashtag_list {
		display: flex;
		column-gap: 16px;
		row-gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		> li {}
	}
	.hashtag_item {
		&::before {
			content: '#';
			margin-right: 2px;
			@include font_sum (
				$s: 15,
				$w: 500,
				$c: #00b775,
				$h: 22,
				$l: -.4px,
				$box: inline-block
			);
		}
		> span {
			@include font_sum (
				$s: 15,
				$w: 500,
				$c: $black,
				$h: 22,
				$l: -.4px,
				$box: inline-block
			);
		}
	}
}

/* 공유하기 팝업 */
.list_share_ect_gh {
	display: flex;
	margin: 0 -20px;
	padding: 10px 0 32px 0;
	width: calc(100% + 40px);
	justify-content: space-evenly;
	li {
		button {
			display: block;
			text-align: center;
			@include font_pre(1rem,500,$black,1.5);
			img {
				display: block;
				width: 64px;
				height: 64px;
				margin-bottom: 16px;
			}
		}
	}
}

.bottom_none_space {
	padding-bottom: 0px !important
}
.ui_keysearch_wrap {
	&.all_list_box {
		position: relative;
		width: 100%;
		height: 100%;
		padding-top: 16px;
		box-sizing: border-box;
		.top_area {
			@include sc_hidden;
			padding-bottom: 40px;
			> ul {
				display: flex;
				gap: 8px;
				align-items: center;
				justify-content: flex-start;
				> li {
					&:first-of-type {
						padding-left: 19px;
					}
					&:last-of-type {
						padding-right: 19px;
					}
				}
			}
			.string_btn {
				display: flex;
				width: 56px;
				height: 36px;
				border-radius: 18px;
				border: solid 1px $gray03;
				box-sizing: border-box;
				align-items: center;
				justify-content: center;
				background-color: $white;
				span {
					@include font_sum (
						$s: 15,
						$h: 22,
					);
				}
				&.on {
					border: 1px solid $green02;
					background-color: $green02;
					span {
						color: $white
					}
				}
			}
		}
		.conts_area {
			height: calc(100% - 76px);
			padding: 0 24px;
			box-sizing: border-box;
			@include sc_hidden();
			overflow-x: hidden;
			overflow-y: auto;
			> .list {
				display: flex;
				column-gap: 16px;
				> .title {
					position: relative;
					width: auto;
					flex-shrink: 1;
					> div {
						position: sticky;
						display: flex;
						width: 100%;
						height: 45px;
						margin-right: 16px;
						top: 0;
						left: 0;
						align-items: center;
						justify-content: flex-start;
						span {
							@include font_sum (
								$s: 20,
								$w: 700,
								$h: 28,
								$l: -1px,
								$box:inline-block
							);
							font-family: 'Happiness-Sans';
						}
					}
				}
				> ul {
					width: 100%;
					box-sizing: border-box;
					> li:first-of-type {
						.all_list_item {
							border-color: $black;
						}
					}
				}
				.all_list_item {
					display: flex;
					width: 100%;
					height: 45px;
					padding: 0 16px;
					border-top: 1px solid $gray03;
					align-items: center;
					box-sizing: border-box;
					span {
						@include font_sum (
							$c: $gray02,
						);
					}
				}
			}
		}
	}
}


/**
 * space
 */
._space {
	&_left {
		&_margin {
			@for $i from 1 to 24 {
				&_#{$i} {
					margin-left: ($i * 1px) !important;
				}
			}
		}
	}
	&_top {
		&_margin {
			@for $i from 1 to 24 {
				&_#{$i} {
					margin-top: ($i * 1px) !important;
				}
			}
		}
	}
}

/* select btmsheet : content button */
div.ui_HdApp_select_btmsheet {
	//카드선택모듈
	.card_select {
		.btn_sel_type {
			width: 100%;
			padding: 16px 20px;
			border: solid 1px $gray03;
			border-radius: 18px;
			background-color: $white;
			box-sizing: border-box;
			.ui_select_text {
				display:flex;
				justify-content: left;
				align-items:center;
				img {
					width: 64px;
					height: 40px;
					margin-right: 12px;
				}
				span {
					@include font_sum (
						$w: 700,
						$box: inline-block
					);
					.sub {
						display:block;
						@include font_sum (
							$s: 12,
							$c: $gray02,
							$h: 18,
							$box: block
						);
					}
				}
				.flag_wrap {
					> span {
						@include font(0.75rem,500,$black,1.5);
					}
					&.c01 {
						> span {
							color:$green02;
						}
					}
				}
			}
		}
		&.shadow_type {
			.btn_sel_type {
				border: solid 1px transparent;
				box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
				background-color: $white;
			}
		}
	}
	&.single_item { //아이템이 한개만있을때
		pointer-events:none !important;
		.btn_sel_type {
			&:after {
				content:none !important;
			}
		}
	}
}
/* select btmsheet : popup */
article.lay_pop.bottom_type.lay_pop {
	.lay_wrap {
		.lay_tit {
			h1 {
				padding: 0px;
				margin: 0px;
			}
		}
		.lay_conts {
			&.btm_select_wrap {
				&.card_select {
					padding: 0px;
					padding-bottom: 4px;
					width: 100%;
					margin: 0px;
					margin-top: 24px;
					.ui_option_wrap {
						li {
							.ui_btn_option {
								display:flex;
								justify-content: left;
								align-items:center;
								img {
									width:64px;
									height:40px;
									margin-right:12px;
								}
								span {
									@include font(0.875rem,700,$black,1.5);
									.sub {
										display:block;
										@include font(0.75rem,400,$gray02,1.5);
									}
								}
							}
						}
					}
				}
			}
			&.payment_day { //230918_나의등급(AMY-B01-001) 하단회원선택 셀렉트 스타일 복사,추가
				.ui_option_wrap {
					 li {
						position:relative;
						.ui_btn_option {
							width:100%;
							padding:16px 24px;
							text-align:left;
							@include font_pre(1rem,500,$black,1.5);
							> em {
								margin-right: 4px;
								@include font_pre(1rem,500,$black,1.5);
							}
						}
						&.on {
							background-color:$gray08;
							&:after {
								@include sudo_pos(50%,24px,0,initial){
									transform:translateY(-50%);
								};
								@include bg(20px,20px,'/images/mobilehome2/new/ico_s_check_20_green.png');
							}
						}
					 }
				}
			}
		}
		// 공통 _layer.scss 에서 컨트롤
		// .close {
		// 	top: 16px;
		// }
	}
}

// 카드 이미지 박스 - AHC-B01-004
.card_image_shadow_box {
	position: relative;
	width: 100%;
	> img {
		position: relative;
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		// width: calc(100% - 6px);
		// height: calc(100% - 6px);
		width: 100%;
		height: 100%;
		border-radius: 10%;
		background-color: transparent;
		transform: translate(-50%, -50%);
		box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.1);
		z-index: -1;
	}
}

// 바코드 중앙정렬
.barcode_area {
	.barcode_img {
		.barcode_wrap {
			display: flex;
			width: 100% !important;
			font-size: 0px;
			line-height: 0px;
			align-items: center;
			justify-content: center;
		}
	}
	.barcode_num {
		> p {
			@include font_sum (
				$s: 12,
				$w: 700,
				$c: #727573,
				$h: 18,
				$l: -.2px,
				$box: block
			);
		}
		&.is_btn_type {
			display: flex;
			padding-bottom: 8px !important;
			align-items: center;
			justify-content: center;
			.btn_wrap {
				.barcode_copy {
					display: block;
					width: auto;
					padding: 2px 11px 3px;
					border-radius: 36px;
					border: solid 1px #d3d3d3;
					box-sizing: border-box;
					span {
						@include font_sum (
							$w: 700,
							$l: -.25px,
						);
					}
				}
				& + p {
					margin-left: 8px;
				}
			}
		}
	}
}

.accordion_list_box {
	.head_box {
		display: flex;
		width: 100%;
		padding-top: 16px;
		padding-bottom: 16px;
		column-gap: 12px;
		align-items: center;
		justify-content: flex-start;
		> p {
			display: flex;
			width: 100%;
			column-gap: 12px;
			align-items: center;
			justify-content: flex-start;
			strong {
				&.text {
					width: 100%;
					@include font_sum (
						$s: 16,
						$w: 700,
						$h: 23,
						$l: -.25px,
						$box: inline-block
					);
				}
				&.num {
					width: auto;
					flex-shrink: 0;
					@include font_sum (
						$s: 15,
						$w: 700,
						$h: 22,
						$l: -.4px,
						$box: inline-block
					);
				}
			}
		}
		> i.ico_arrow {
			display: block;
			flex-shrink: 0;
			transition: all .25s ease-in;;
		}
	}
	.contents_box {
		padding-top: 20px;
		border-top: 1px solid #eaebea;
		.list {
			> li {
				& + li {
					margin-top: 16px;
				}
			}
			.item_box {
				display: flex;
				column-gap: 16px;
				align-items: flex-start;
				justify-content: space-between;
				> dt {
					width: 100%;
					flex-shrink: 1;
					span {
						@include font_sum (
							$s: 15,
							$h: 22,
						);
					}
				}
				> dd {
					width: auto;
					flex-shrink: 0;
					strong {
						@include font_sum (
							$s: 15,
							$w: 700,
							$h: 22,
						);
					}
				}
			}
		}
	}
	li {
		&.active {
			.head_box {
				.ico_arrow {
					transform: rotate(0deg);
				}
			}
		}
		&.on {
			.head_box {
				.ico_arrow {
					transform: rotate(180deg);
				}
			}
		}
	}
}

.accordion_card_box {
	> ul {
		> li {
			padding: 4px 20px 4px;
			border-radius: 18px;
			box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
			background-color: #fff;
			& + li {
				margin-top: 24px;
			}
		}
	}
	.head_box {
		display: flex;
		padding-top: 16px;// 클릭영역
		padding-bottom: 16px;// 클릭영역
		column-gap: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		> div {
			> strong {
				@include font_sum (
					$s: 16,
					$w: 700,
					$h: 24,
					$l: -.25px,
				);
			}
			word-break: keep-all;
		}
		> .title {
			flex-basis: max-content;
			flex-grow: 1;
		}
		> .jelly_num {
			position: relative;
			padding-left: 32px;
			flex-basis: auto;
			flex-shrink: 0;
			flex-grow: 0;
			.ico_jelly_red {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			.ico_jelly_blue {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		> .icon {
			flex-basis: auto;
			flex-shrink: 0;
			flex-grow: 0;
		}
		> .icon {
			.ico_arrow {
				transition: all .25s ease-in;;
			}
		}
	}
	.contents_box {
		border-top: 1px solid #eaebea;
		padding-top: 20px;
		padding-bottom: 16px;
		.con_box {
			> .text {
				> span {
					@include font_sum (
						$s: 15,
						$h: 22,
					);
				}
				p {
					@include font_sum (
						$s: 15,
						$w: 400,
						$h: 22,
						$box: inherit
					);
					word-break: keep-all;
				}
				& + .image {
					margin-top: 16px;
				}
			}
			// > .image {}
			> .check_list {
				> dt {
					span {
						@include font_sum (
							$w: 700,
						);
					}
					& + dd {
						margin-top: 8px;
					}
				}
				// > dd {}
			}
			> .btn_wrap {}
			& + .con_box {
				margin-top: 24px;
			}
		}
	}
	li {
		&.active {
			.head_box {
				.ico_arrow {
					transform: rotate(0deg);
				}
			}
		}
		&.on {
			.head_box {
				.ico_arrow {
					transform: rotate(180deg);
				}
			}
		}
	}
}


.ico {
	@mixin ico_default($width,$height,$url) {
		display: inline-flex;
		width:$width * 1px;
		height:$height * 1px;
		font-size: 0;
		line-height: 0;
		vertical-align: middle;
		background-image: url($url);
		background-position: center;
		background-size: 100%;
		background-repeat: no-repeat;
		@content;
	}

	&_rank {
		&_up {
			@include ico_default(20, 20, '/images/mobilehome2/mypage/new/ico_rankup_20.png');
			background-position-y: -1px;
		}
		&_down {
			@include ico_default(20, 20, '/images/mobilehome2/mypage/new/ico_rankdown_20.png');
		}
	}
	&_arrow {
		@include ico_default(20, 20, '/images/mobilehome2/mypage/new/ico_arrow_down_20_black.png');
	}
	&_jelly_red {
		@include ico_default(32, 32, '/images/mo/benefit/img_jelly_red.png');
	}
	&_jelly_blue {
		@include ico_default(32, 32, '/images/mo/benefit/img_jelly_blue.png');
	}
}

span.point_red {
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	font-weight: 700;
	color: #eb3912 !important;
}
span.point_black {
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	font-weight: 700;
	color: #171a19 !important;
}


.module_frame {
	$url : '/images/mobilehome2/new/';

	position: relative;
	&_header {
		position: absolute;
		width: 100%;
		height: 100%;
		padding-top: 7.317%;
		padding-left: 7.317%;
		padding-right: 9.756%;
		box-sizing: border-box;
		z-index: 2;
		.title {
			@include font_sum (
				$s: 26,
				$w: 700,
				$c: white,
				$h: 38,
				$l: -1px,
				$box: inline-block
			);
			font-family: 'HappinessSansVF';
		}
	}
	&_contents {
		position: relative;
		z-index: 1;
		.img_wrap {
			padding-left: 3.658%;
			padding-right: 3.658%;
			padding-top: 19.207%;
			padding-bottom: 7.317%;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url( $url + 'module_home_shoppingnews_01.png');
				background-position: center;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				z-index: 2;
			}
			.img {
				position: relative;
				width: 100%;
				padding-bottom: 100%;
				bottom: -0.5px;
				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0px;
					left: 0px;
					height: 47.368%;
					width: 100%;
					background: linear-gradient(to bottom, rgb(0 22 10) 0%, rgb(255 255 255 / 0%) 100%);
					opacity: 0.6;
					z-index: 1;
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0px;
					left: 0px;
					height: 47.368%;
					width: 100%;
					background: linear-gradient(to bottom, rgb(0, 22, 10) 0%, rgba(255, 255, 255, 0) 100%);
					opacity: 0.6;
					z-index: 1;
					transform: rotateX(180deg);
				}
				> img {
					position: absolute;
					width: 100%;
				}
			}
		}
	}
	&._case {
		&01 {
			.img_wrap::before {
				background-image: url( $url + 'module_home_shoppingnews_01.png');
			}
		}
		&02 {
			.img_wrap::before {
				background-image: url( $url + 'module_home_shoppingnews_02.png');
			}
		}
		&03 {
			.img_wrap::before {
				background-image: url( $url + 'module_home_shoppingnews_03.png');
			}
		}
		&04 {
			.img_wrap::before {
				background-image: url( $url + 'module_home_shoppingnews_04.png');
			}
		}
	}
}

.detail_box {
	padding-top: 32px;
	padding-right: 8px;
	padding-left: 8px;
	.detail_list {
		> li {
			& + li {
				margin-top: 24px;
			}
		}
	}
	.detail_item {
		> dt {
			span {
				@include font_sum (
					$w: 700
				);
			}
			& + dd {
				margin-top: 8px;
			}
		}
		> dd {
			span {
				@include font_sum;
			}
			& + dd {
				margin-top: 4px;
			}
		}
		&.bullet {
			> dd {
				position: relative;
				padding-left: 10px;
				&:before {
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background-color: $black;
					@include sudo_pos(7px, 0, 0, 0);
				}
			}
		}
	}
}